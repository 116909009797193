import { FieldArray, Form, getIn, useFormikContext } from "formik";
import { useCustomTranslation, useWarningMessageT2 } from "../../../app/hooks";
import { preventFormSubmit, t2DivVariants } from "./inputs/utils";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppSelector } from "../../../app/hooks";
import { isEqual } from "lodash";
import DefaultsErrorNote from "../../../components/messageNote/DefaultsErrorNote";
import LoadDefaultsButton from "./LoadDefaultsButton";

interface GenericOptionalTierTwoProps<T, E> {
  titleKey: string;
  listInputName: string;
  EntryComponent: React.ComponentType<EntryComponentProps<E>>;
}

export interface EntryComponentProps<E> {
  entry: E;
  index: number;
  onDefaultsError: (message: string) => void;
  key?: string;
}


const GenericOptionalTierTwo = <T, E>({ titleKey, listInputName, EntryComponent }: GenericOptionalTierTwoProps<T, E>) => {
  const translatedTitle = useCustomTranslation(titleKey);
  const { warningMessage, handleDefaultsError } = useWarningMessageT2();
  const { values } = useFormikContext<T>();
  const { tabsOpen } = useAppSelector(selectCurrentBuilder);

  const [list, setList] = useState<E[]>([]);

  useEffect(() => {
    const newList = getIn(values, listInputName)
    const isListUpdated = !isEqual(list, newList)
    if (isListUpdated) {
      setList(newList)
    }
  }, [values, listInputName]);

  if (tabsOpen) return null;

  return (
    <motion.div initial={false} variants={t2DivVariants}>
      <Form onKeyDown={preventFormSubmit} className="py-2">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-13 ff-normal-ext module-header w-fit">
            {translatedTitle} - Tier 2
          </h2>
          {warningMessage && <DefaultsErrorNote noteMessage={warningMessage} />}
        </div>

        <LoadDefaultsButton />

        <FieldArray name={listInputName}>
          {() => (
            <>
              {list.map((entry, index) => (
                <EntryComponent
                  key={`entry-${index}`}
                  entry={entry}
                  index={index}
                  onDefaultsError={handleDefaultsError}
                />
              ))}
            </>
          )}
        </FieldArray>
      </Form>
    </motion.div>
  );
}


export default GenericOptionalTierTwo;