import {
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ComposedChart,
	Line,
	CartesianGrid,
	ReferenceLine,
} from "recharts";
import { getGasColor } from "./graphsUtils";
import { useGetProjectResultsByGasQuery } from "../../app/features/project/projectApiSlice";
import { useMemo } from "react";
import { calculateYearlyEmissionsTimeSeries } from "../../pages/project/results/hooks/resultsUtils";
import { useSelector } from "react-redux";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import GraphContainer from "./GraphContainer";
import Skeleton from "react-loading-skeleton";
import TranslatableText from "../translations/TranslatableText";
import CustomLegend from "./CustomLegend";
import CustomTooltip from "./CustomTooltip";

const CarbonBalanceByYear = ({
	width,
	height,
	className,
	projectId,
	selectedActivities,
}: {
	width?: string | number;
	height: string | number;
	className?: string;
	projectId: number;
	selectedActivities?: number[];
}) => {
	const project = useSelector(selectCurrentProject)
	const { data: customRes, isFetching } = useGetProjectResultsByGasQuery(
		{ id: projectId, activities: selectedActivities },
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: (result) => ({
				...result,
				data: calculateYearlyEmissionsTimeSeries(result.data),
			}),
		},
	);

	const data = useMemo(() => {
		if (customRes) {
			return customRes.map((emission) => ({
				...emission,
				BALANCE: Object.values(emission).reduce(
					(sum, value) => sum + (value ?? 0),
					0,
				).toFixed(2),
			}));
		}
		return [];
	}, [customRes]);

	return (
		<>
			<div
				className="mt-4 w-fit"
				style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
			>
				<h2 className="ff-bold fs-13 text-black">
					<TranslatableText translationKey="main.carbon_balance_by_year_and_ghg"/>
				</h2>
			</div>
			<GraphContainer height={height}>
				{isFetching ? (
					<Skeleton height={height} width={width ?? "97%"} />
				) : (
					<ResponsiveContainer
						width={width ?? "97%"}
						height={height}
						className={className}
						style={{ paddingBottom: height }}
					>
						<ComposedChart
							data={data}
							margin={{
								top: 5,
								right: 10,
								left: 15,
								bottom: 5,
							}}
							barGap="1"
						>
							<CartesianGrid stroke="#f5f5f5" />
							<XAxis
								width={10}
								style={{ fontSize: "12px" }}
								tickFormatter={(value) => `${value + 1}`}
							/>
							<YAxis width={40} style={{ fontSize: "8px" }} name="Year" />
							<Tooltip content={<CustomTooltip customLabel={(label: number) => `Year ${(project.project?.start_year_of_activities ?? 1 )+ label }`} />} />
							<Legend content={<CustomLegend />} />
							<ReferenceLine y={0} stroke="#f5f5f5" />
							<Bar
								dataKey="CO2"
								stackId="a"
								fill={getGasColor({ name: "CO2" })}
							/>
							<Bar
								dataKey="CH4"
								stackId="b"
								fill={getGasColor({ name: "CH4" })}
							/>
							<Bar
								dataKey="N2O"
								stackId="c"
								fill={getGasColor({ name: "N2O" })}
							/>
							<Line type="monotone" dataKey="BALANCE" stroke="var(--tertiary-bright)" />
							{/* <Scatter dataKey="BALANCE" fill="red" /> */}
						</ComposedChart>
					</ResponsiveContainer>
				)}
			</GraphContainer>
		</>
	);
};

export default CarbonBalanceByYear;
