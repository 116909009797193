import { useState } from "react";
import { useGetProjectByIdQuery } from "../../../../app/features/project/projectApiSlice";
import TranslatableText from "../../../../components/translations/TranslatableText";
import useErrorMessage from "../../../../utils/useErrorMessage";
import DownloadReportButtons from "./DownloadReportButton";
import ActivitiesCheckboxes from "./ActivitiesCheckboxes";
import DescriptionBlock from "./DescriptionBlock";
import BalanceByStatus from "../../../../components/graphs/BalanceByStatus";
import CarbBalanceByYear from "../../../../components/graphs/CarbBalanceByYear";
import CO2ByActivityHorizontal from "../../../../components/graphs/CO2ByActivityHorizontal";
import BalanceCostRatio from "../../../../components/graphs/BalanceCostRatio";
import TotalCO2Generated from "./TotalCO2Generated";
import CarbonBalance from "./CarbonBalance";

interface ProjectResultsProps {
    projectId?: string;
    containerStyle?: React.CSSProperties;
}

export const ProjectResults = ({ projectId, containerStyle }: ProjectResultsProps) => {
    const [selectedActivities, setSelectedActivities] = useState<number[]>();
    const handleActivitiesRefresh = (activities: number[]) => {
        setSelectedActivities(activities);
    };

    const {
        data: project,
        isError,
        error,
        isLoading
    } = useGetProjectByIdQuery(projectId || "", {
        refetchOnMountOrArgChange: true,
    });
    const { errorMsg } = useErrorMessage({ error, isError });

    if (errorMsg) {
        return (
            <section>
                <div className="module-group">
                    <span className="error fs-13 pb-2">
                        {errorMsg || `An error occurred while fetching for project ${projectId}`}
                    </span>
                </div>
            </section>
        );
    }

    return (
        <section style={containerStyle}>
            <div className="module-group" style={{ display: "flex", marginLeft: 0 }}>
                <div className="pos-relative mb-2 pb-1">
                    <h2 className="fs-14 ff-medium-ext module-header w-fit">
                        <TranslatableText translationKey="project.project_results" />
                    </h2>
                    <div className="module-number header-number ff-light-ext">{project?.id}</div>
                </div>
            </div>

            <div className="d-flex f-column pb-2">
                <div className="d-flex pb-2 fs-13 lh-1 project-results-dc description-container">
                    <DescriptionBlock
                        country={project?.country?.name}
                        climate={project?.climate?.name}
                        moisture={project?.moisture?.name}
                        soilType={project?.soil_type?.name}
                        implDuration={project?.implementation_years}
                        capDuration={project?.capitalization_years}
                        isLoading={isLoading}
                        totalHectares={project?.total_hectares}
                        totalCatch={project?.total_catch?.w}
                        totalLivestock={project?.total_livestock?.w}
                    />
                </div>

                <div className="d-flex gap-8">
                    <DownloadReportButtons selectedActivities={selectedActivities} projectId={project?.id ?? 0} />
                    <ActivitiesCheckboxes onRefresh={handleActivitiesRefresh} projectId={project?.id ?? 0} />
                </div>

                <TotalCO2Generated
                    projectId={project?.id ?? 0}
                    selectedActivities={selectedActivities}
                />

                <CO2ByActivityHorizontal
                    projectId={project?.id ?? 0}
                    selectedActivities={selectedActivities}
                    height={100}
                    className="mb-3"
                />

                <BalanceByStatus
                    projectId={project?.id ?? 0}
                    selectedActivities={selectedActivities}
                    height={350}
                    className="my-3 pe-2"
                />

                <CarbBalanceByYear
                    projectId={project?.id ?? 0}
                    selectedActivities={selectedActivities}
                    height={350}
                    className="my-3 pe-2"
                />

                <CarbonBalance
                    projectId={project?.id ?? 0}
                    selectedActivities={selectedActivities}
                />

                <BalanceCostRatio
                    projectId={project?.id ?? 0}
                    selectedActivities={selectedActivities}
                />
            </div>
        </section>
    );
};

export default ProjectResults; 