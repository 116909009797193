import type { ReactNode } from "react";
import type {
	EntryShared,
	OptionalSubmodule,
	T2Schema,
	TranslationKey,
} from "../../../types/modulesInterfaces";
import { Form, useFormikContext, getIn, FieldArray } from "formik";
import { createContext, useContext, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import { useGetSubmoduleDefaultsQuery } from "../../../app/features/api/modules/genericOperationsApiSlice";
import { useCustomTranslation, usePermissions, useWarningMessageT2 } from "../../../app/hooks";
import { handleDisableSww } from "./moduleUtils";
import { preventFormSubmit } from "./inputs/utils";
import { TranslatedFormSubmodule } from "../../../components/formSubmodule/TranslatedFormSubmodule";
import FormSubmodule from "../../../components/formSubmodule/FormSubmodule";
import GenericInputGroup from "../../../components/input/inputGroup/GenericInputGroup";
import DefaultsErrorNote from "../../../components/messageNote/DefaultsErrorNote";
import LoadDefaultsButton from "./LoadDefaultsButton";
import useErrorMessage from "../../../utils/useErrorMessage";
import FadeAnimation from "../../../components/animations/FadeAnimation";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};
type GenericT2Context = {
	isReadOnly: boolean;
}
const GenericTwoContext = createContext<GenericT2Context>({
	isReadOnly: false
});

const GenericTierTwo = ({
	title,
	t2Schema,
	tabsOpen,
	warningMessage,
}: {
	title: TranslationKey;
	t2Schema: T2Schema;
	tabsOpen: boolean;
	warningMessage?: string | null
}) => {
	const { isReadOnly } = usePermissions();
	const { warningMessage: childwarningMessage, handleDefaultsError } = useWarningMessageT2();
	const translatedTitle = useCustomTranslation(title);
	if (tabsOpen) return null;
	return (
		<GenericTwoContext.Provider value={{ isReadOnly }}>
			<motion.div initial={false} variants={variants}>
				<Form onKeyDown={preventFormSubmit} className="py-2">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-13 ff-normal-ext module-header w-fit">
							{translatedTitle} - Tier 2
						</h2>
						{
							(childwarningMessage || warningMessage) && <DefaultsErrorNote noteMessage={childwarningMessage ?? warningMessage ?? ""} />
						}
					</div>

					<LoadDefaultsButton />

					{t2Schema.submodules.map((submodule) => (
						<FormSubmodule submoduleName={submodule.name} key={submodule.name}>
							<TierTwoBlock>
								{submodule.inputGroups.map((input, index) => {
									const curatedInput = handleDisableSww({ input, isReadOnly, index });
									return (
										<GenericInputGroup
											key={`submodule-${index}-${submodule.name}`}
											inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
										/>
									)
								})}
							</TierTwoBlock>
						</FormSubmodule>
					))}
					{t2Schema.optionalSubmodules?.map((submodule, index) => (
						<FormOptionalSubmodules
							submodule={submodule}
							key={`optsubmodule-${index}-${submodule.name}`}
							onDefaultsError={handleDefaultsError}
						/>
					))}
				</Form>
			</motion.div>
		</GenericTwoContext.Provider>
	);
};

const FormOptionalSubmodules = <T,>({ submodule, onDefaultsError }: { submodule: OptionalSubmodule<T>, onDefaultsError?: (message: string) => void }) => {
	const { values } = useFormikContext();
	const submoduleValues = getIn(values, submodule.inputName);
	const { isReadOnly } = useContext(GenericTwoContext);

	return Array.isArray(submoduleValues) ? (
		<>
			<FieldArray name={submodule.inputName}>
				{() => (
					<>
						{submoduleValues.map((el, index) => {
							return el.id ? (
								<FormOptionalSubmoduleELement
									entry={el}
									submodule={submodule}
									index={index}
									submoduleName={submodule.name}
									key={`${index + 1}. ${submodule.name}`}
									id={el.id}
									onDefaultsError={onDefaultsError}
								/>
							) : null;
						})}
					</>
				)}
			</FieldArray>
		</>
	) : (
		<FormSubmodule submoduleName={submodule.name}>
			<TierTwoBlock>
				{submodule.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({ input, isReadOnly, index: i });
					return (
						<GenericInputGroup
							key={`${submodule.name}-${i}`}
							inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
						/>
					)
				})}
			</TierTwoBlock>
		</FormSubmodule>
	);
};

const FormOptionalSubmoduleELement = <T,>({
	submodule,
	entry,
	index,
	submoduleName,
	id,
	onDefaultsError
}: {
	submodule: OptionalSubmodule<T>;
	entry: EntryShared;
	index: number;
	submoduleName: TranslationKey;
	id: number;
	onDefaultsError?: (message: string) => void
}) => {
	const { isReadOnly } = useContext(GenericTwoContext);
	const { setFieldValue } = useFormikContext();
	const { data: defaults, isError, error } = useGetSubmoduleDefaultsQuery({
		uniqueId: id,
		route: submodule.route ?? "",
		cacheTag: submodule.cacheTag ?? "FakeTag",
	},
		{ skip: !id || !submodule.route || !submodule.cacheTag },
	);

	//conditional section
	const shouldShow = useMemo(() => {
		if (!submodule.conditionalSection) return false
		return getIn(entry, submodule.conditionalSection.conditionName)
	}, [entry, submodule.conditionalSection])
	useEffect(() => {
		if (submodule?.conditionalSection?.initialValues && !shouldShow)  //if we hide the section, we reset its values
			setFieldValue(`${submodule.inputName}.${index}`, { ...entry, ...submodule.conditionalSection.initialValues })
	}, [shouldShow])


	//defaults handling
	useEffect(() => {
		if (!defaults || !setFieldValue || !submodule.route) return;
		const entryWithDefaults = defaults ? { ...entry, ...defaults } : null
		if (entryWithDefaults && index !== undefined)
			setFieldValue(`${submodule.inputName}.${index}`, entryWithDefaults);
	}, [defaults, setFieldValue, index]);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });
	useEffect(() => {
		if (defaultsErrorMsg && onDefaultsError) onDefaultsError(defaultsErrorMsg);
	}, [defaultsErrorMsg, onDefaultsError])


	return (
		<TranslatedFormSubmodule
			submoduleName={submoduleName}
			itemId={id}
			itemIndex={index + 1}
			className="pt-2"
		>
			<TierTwoBlock>
				{submodule.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({ input, isReadOnly, index });
					return (
						<GenericInputGroup
							key={`${submodule.name}-${i}`}
							inputGroup={{ ...curatedInput, index, disabled: isReadOnly }} //, autosubmit: true
						/>
					)
				})}

				<FadeAnimation isShown={shouldShow}>
					<>
						{submodule.conditionalSection?.inputGroups.map((input) => (
							<GenericInputGroup key={`${input.label}-${entry.id}`} inputGroup={{ ...input, disabled: isReadOnly, index }} />
						))}
					</>
				</FadeAnimation>
			</TierTwoBlock>
		</TranslatedFormSubmodule>
	);
};

export const TierTwoBlock = ({ children }: { children: ReactNode }) => {
	return <div className="tiertwo-block">{children}</div>;
};

export default GenericTierTwo;
