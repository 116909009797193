import { motion, AnimatePresence } from "framer-motion";
import { useMemo, useState } from "react";
import { BiDownload, BiFile, BiTrash, BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { ProjectAttachment } from "../../../../app/features/project/projectApiSlice";
import KebabMenu, { Line } from "../../../kebabMenu/KebabMenu";
import { readableFileSize } from "../../../../utils/helperFunctions";

interface AttachmentCardProps {
  attachment: ProjectAttachment;
  handleDelete: (attachment: ProjectAttachment) => void;
  handleDownload: (attachment: ProjectAttachment) => void;
  setSelected: (attachmentId: number) => void;
  setUnselected: (attachmentId: number) => void;
  isSelected: boolean;
}

const AttachmentCard = ({ 
  attachment, 
  handleDelete, 
  handleDownload, 
  setSelected, 
  setUnselected, 
  isSelected 
}: AttachmentCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const menuLines = useMemo<Line[]>(() => [
    {
      id: 0,
      label: "main.download" as const,
      icon: BiDownload,
      clickHandler: () => handleDownload(attachment)
    },
    {
      id: 1,
      label: "main.delete" as const,
      icon: BiTrash,
      clickHandler: () => handleDelete(attachment),
      activeColor: "var(--error)"
    }
  ], [attachment, handleDelete, handleDownload]);

  const fileSize = useMemo(() =>
    attachment.size ? readableFileSize(attachment.size) : null,
    [attachment.size]
  );

  const handleIconClick = () => {
    if (isSelected) {
      setUnselected(attachment.id);
    } else {
      setSelected(attachment.id);
    }
  };

  const renderIcon = () => {
    if (isSelected) {
      return <BiCheckboxChecked size={24} color="var(--primary-deep)" />;
    }
    if (isHovered) {
      return <BiCheckbox size={24} color="var(--primary-deep)" />;
    }
    return <BiFile size={24} color="var(--primary-deep)" />;
  };

  return (
    <motion.div
      className="file-card"
      whileHover={{ boxShadow: "5px 5px 10px rgba(73, 28, 105, 0.3)" }}
    >
      <div className="d-flex gap-1 align-items-center">
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleIconClick}
          style={{ position: 'relative', width: '24px', height: '24px', cursor: 'pointer' }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={isSelected ? 'checked' : isHovered ? 'hover' : 'file'}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.1 }}
              style={{ position: 'absolute' }}
            >
              {renderIcon()}
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="d-flex f-column ps-1 lh-90">
          <span className="ff-medium fs-14">{attachment.name}</span>
          {fileSize && <span className="text-gray fs-12">{fileSize}</span>}
        </div>
      </div>

      <KebabMenu 
        lines={menuLines} 
        activeColor="var(--primary-deep)" 
        position="left" 
      />
    </motion.div>
  );
};

export default AttachmentCard; 