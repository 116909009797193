import { BiSync, BiHelpCircle, BiDetail, BiCloudUpload } from "react-icons/bi";
import {
	MdEditNote,
	MdHelpOutline,
	MdOutlineChat,
	MdOutlineMap,
} from "react-icons/md";
import { ModulesCube, MyBolt } from "./customIcons";
import { useLocation } from "react-router-dom";
import { Actions } from "../types/interfaces";
import type { IconType } from "react-icons";
import type { ReactElement } from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
	selectCurrentBuilder,
	setActionList,
	setActiveAction,
} from "../app/features/builder/builderSlice";

import ModulesAction from "../components/actions/modules/ModulesAction";
import ChangesAction from "../components/actions/changes/ChangesAction";
import HelpAction from "../components/actions/HelpAction";
import LiveUpdatesAction from "../components/actions/liveUpdate/LiveUpdatesAction";
import NotesAction from "../components/actions/notes/NotesAction";
import CommentsAction from "../components/actions/comments/CommentsAction";
import MapAction from "../components/actions/map/MapAction";
import DocumentationAction from "../components/actions/DocumentationAction";
import DefinitionsAction from "../components/actions/definitions/DefinitionsAction";
import AttachmentsAction from "../components/actions/attachments/AttachmentsAction";
import { TranslationKey } from "../types/modulesInterfaces";

export type ActionsInfoType = {
	[key in Actions]: {
		label: TranslationKey;
		icon: IconType;
		color: string;
	};
};

export type ActionsComponentType = {
	[key in Actions]: ReactElement | null;
};

export const ActionsInfo: ActionsInfoType = {
	[Actions.LiveUpdates]: {
		label: "main.live_updates",
		icon: BiSync,
		color: "#750c0c",
	},
	[Actions.Notes]: {
		label: "main.notes",
		icon: MdEditNote,
		color: "#F9BD24",
	},
	[Actions.Comments]: {
		label: "main.comments",
		icon: MdOutlineChat,
		color: "var(--tertiary-deep)",
	},
	[Actions.Help]: {
		label: "main.tutorials",
		icon: MdHelpOutline,
		color: "#4E93C6",
	},
	[Actions.Changes]: {
		label: "main.activity",
		icon: MyBolt,
		color: "var(--quaternary-deep)",
	},
	[Actions.ProgressiveModules]: {
		label: "main.modules",
		icon: ModulesCube,
		color: "var(--common-dark)",
	},
	[Actions.Map]: {
		label: "main.map",
		icon: MdOutlineMap,
		color: "#750c0c",
	},
	[Actions.Documentation]: {
		label: "main.documentation",
		icon: BiHelpCircle,
		color: "#4E93C6",
	},
	[Actions.Definitions]: {
		label: "main.definitions",
		icon: BiDetail,
		color: "#50A2A7",
	},
	[Actions.Attachments]: {
		label: "main.uploads",
		icon: BiCloudUpload,
		color: "#F9BD24",
	},
};

export const ActionsComponent: ActionsComponentType = {
	[Actions.LiveUpdates]: <LiveUpdatesAction />,
	[Actions.Notes]: <NotesAction />,
	[Actions.Comments]: <CommentsAction />,
	[Actions.Help]: <HelpAction />,
	[Actions.Changes]: <ChangesAction />,
	[Actions.ProgressiveModules]: <ModulesAction />,
	[Actions.Map]: <MapAction />,
	[Actions.Documentation]: <DocumentationAction />,
	[Actions.Definitions]: <DefinitionsAction />,
	[Actions.Attachments]: <AttachmentsAction />,
};

const areEqual = (list1: Actions[], list2: Actions[]) => {
	if (list1.length !== list2.length) return false;
	for (let i = 0; i < list1.length; i++) {
		if (list1[i] !== list2[i]) return false;
	}
	return true;
};

const useTabsHook = () => {
	const dispatch = useAppDispatch();
	const { actionsList, activeAction } = useAppSelector(selectCurrentBuilder);
	const { pathname } = useLocation();

	useEffect(() => {
		const pathList = pathname.split("/");
		const root = pathList[1];
		const leaf = pathList[3];

		const projectActionsMap: { [leaf: string]: Actions[] } = {
			"activities": [Actions.Map, Actions.Documentation, Actions.Attachments],
			"description": [Actions.Map, Actions.Documentation],
			"results": [Actions.Map, Actions.Attachments],
			"new-activity": [
				Actions.ProgressiveModules,
				Actions.Map,
				Actions.Documentation,
				Actions.Definitions
			]
		};
		const builderActionsMap: (key: string) => Actions[] = (leaf) => {
			if (leaf === "complete") return [Actions.LiveUpdates, Actions.Help]
			return [Actions.LiveUpdates, Actions.Comments, Actions.Notes, Actions.Help, Actions.Changes, Actions.Definitions];
		}

		const actions = root === "project" ? projectActionsMap[leaf] ?? [] : root === "builder" ? builderActionsMap(leaf) : [];

		if (!areEqual(actionsList, actions) && actions.length > 0) {
			dispatch(setActionList(actions));
			if (!actions.includes(activeAction)) {
				dispatch(setActiveAction(actions[0]));
			}
		}
	}, [pathname, dispatch]);

	return { isHeaderSmall: pathname === "/" || pathname.startsWith("/auth") };
};

export default useTabsHook;
