import type {
	StartWithWithoutGeneric,
	DropdownInputGroup,
	StartWithWithoutTierTwo,
	DropdownInputGroupTierTwo,
	ModuleSchema,
	OptionalSubmodule,
	T2Schema,
	BooleanInputGroupTierTwo,
} from "../../../../types/modulesInterfaces";
import { FieldType, InputType } from "../../../../types/modulesInterfaces";
import * as Yup from "yup";
import { type ModuleFull, type ElectricityResponse, type FuelResponse, EnergyModules } from "./energyTypes";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { validatePositiveNumber } from "../moduleUtils";

export const MAXELECTRICITIES = 1;
export const MAXFUELS = 10;

export const validationSchema = Yup.object({
	electricities: Yup.array().of(
		Yup.object().shape({
			country_t2: validatePositiveNumber
				.when(["quantity_consumed_per_year_start", "quantity_consumed_per_year_w", "quantity_consumed_per_year_wo"], {
					is: (quantity_consumed_per_year_start: number, quantity_consumed_per_year_w: number, quantity_consumed_per_year_wo: number) =>
						!!quantity_consumed_per_year_start || !!quantity_consumed_per_year_w || !!quantity_consumed_per_year_wo,
					then: validatePositiveNumber
						.required('validations.power_country_required'),
				}),
			quantity_consumed_per_year_w: validatePositiveNumber.required('validations.energy_consumption_required'),
			quantity_consumed_per_year_wo: validatePositiveNumber.required('validations.energy_consumption_required'),
			quantity_consumed_per_year_start: validatePositiveNumber.required('validations.energy_consumption_required'),
			mwh_renewables_w: validatePositiveNumber.required('validations.fields_required'),
			mwh_renewables_wo: validatePositiveNumber.required('validations.fields_required'),
			mwh_renewables_start: validatePositiveNumber.required('validations.fields_required'),
		}),
	),
	fuels: Yup.array().of(
		Yup.object().shape({
			fuel_type_start: validatePositiveNumber.required("validations.fuel_type_required"),
			fuel_type_w: validatePositiveNumber.required("validations.fuel_type_required"),
			fuel_type_wo: validatePositiveNumber.required("validations.fuel_type_required"),
			quantity_consumed_per_year_start: validatePositiveNumber
				.required('validations.energy_consumption_required'),
			quantity_consumed_per_year_w: validatePositiveNumber
				.required('validations.energy_consumption_required'),
			quantity_consumed_per_year_wo: validatePositiveNumber
				.required('validations.energy_consumption_required'),
		}),
	),
});

const countries = () => getOptions("country");
const fuelTypes = () => getOptions("fuelTypes");
const emissionFactorSources = () => getOptions("emissionFactorSources");

const getOptionalSubmodules: () => OptionalSubmodule<ElectricityResponse | FuelResponse>[] = () => {
	const electricitySchema = getElectricitySchema();
	const fuelSchema = getFuelSchema();
	return [electricitySchema, fuelSchema];
}

const getElectricitySchema: () => OptionalSubmodule<ElectricityResponse> = () => {
	const powerCountryOfOrigin: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.power_country_of_origin",
		inputName: (index: number) => `electricities.${index}.country_t2`,
		dropdownOptions: countries,
	};
	const electricityGrid: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.electricity_grid",
		unit: "[MWh/yr]",
		inputName: (index: number) => `electricities.${index}.quantity_consumed_per_year`,
	};
	const electricityRenewable: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.electricity_renewable",
		unit: "[MWh/yr]",
		inputName: (index: number) => `electricities.${index}.mwh_renewables`,
	};

	return {
		name: "module.electricity_optional",
		inputGroups: [powerCountryOfOrigin, electricityGrid, electricityRenewable],
		inputName: 'electricities',
		repeatable: true,
		max: MAXELECTRICITIES,
		linkedTierTwoInputs: ['tiertwo.electricities'],
		route: 'electricities',
		cacheTag: 'ElectricitiesDefaults',
	};
};

const getFuelSchema: () => OptionalSubmodule<FuelResponse> = () => {
	const fuelType: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.type_of_fuel_required",
		inputName: (index: number) => `fuels.${index}.fuel_type`,
		startProps: { dropdownOptions: fuelTypes },
		withProps: { dropdownOptions: fuelTypes },
		withoutProps: { dropdownOptions: fuelTypes },
	}
	const fuelConsumption: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.fuel_consumption_required",
		inputName: (index: number) => `fuels.${index}.quantity_consumed_per_year`,
		startProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_start_unit` },
		withProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_w_unit` },
		withoutProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_wo_unit` },
	};

	return {
		name: EnergyModules.Fuel,
		inputGroups: [fuelType, fuelConsumption],
		inputName: 'fuels',
		repeatable: true,
		max: MAXFUELS,
		linkedTierTwoInputs: ['tiertwo.fuels'],
		route: 'fuels',
		cacheTag: 'FuelsDefaults',
	};
};

const getT2OptionalSubmodules: () => OptionalSubmodule<ElectricityResponse | FuelResponse>[] = () => {
	const electricityT2 = getT2ElectricitySchema();
	const fuelT2 = getT2FuelSchema();
	return [electricityT2, fuelT2];
}

const getT2ElectricitySchema: () => OptionalSubmodule<ElectricityResponse> = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `electricities.${index}.energy_ef_t2`,
			label: "module.ef_selected_country",
			unit: "[tCO2/MWh]",
			links: ["Power Country of Origin"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `electricities.${index}.transmission_loss_t2`,
			label: "module.transmission_loss",
			unit: "[%]",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
			inputType: InputType.PERCENTAGE
		},
	];

	const scopeOfEmissionFactor: DropdownInputGroupTierTwo = {
		type: FieldType.SELECT_T2,
		inputName: (index: number) => `electricities.${index}.ef_source`,
		label: "module.scope_of_emission_factor",
		links: [],
		unit: "",
		defaultValue: "value",
		dropdownOptions: emissionFactorSources,
	};

	return {
		name: EnergyModules.Electricity,
		inputName: 'electricities',
		inputGroups: [scopeOfEmissionFactor, ...rows],
		route: 'electricities',
		cacheTag: 'ElectricitiesDefaults',
	};
};

const getT2FuelSchema: () => OptionalSubmodule<FuelResponse> = () => {
	const accountsForCo2Emissions: BooleanInputGroupTierTwo = {
		type: FieldType.BOOLEAN_T2,
		inputName: (index: number) => `fuels.${index}.accounts_for_co2_emissions`,
		label: "module.accounts_for_co2_emissions",
		links: [],
	};
	const emissionsByGas: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `fuels.${index}.energy_ef_co2_t2`,
			label: "module.ef_of_fuel_used_co2",
			unit: "[tCO2-e/m3]",
			links: ["Fuel Type"],
			startProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_start_unit` },
			withoutProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_wo_unit` },
			withProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_w_unit` },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `fuels.${index}.energy_ef_ch4_t2`,
			label: "module.ef_of_fuel_used_ch4",
			unit: "[tCH4-e/m3]",
			links: ["Fuel Type"],
			startProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_start_unit` },
			withoutProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_wo_unit` },
			withProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_w_unit` },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `fuels.${index}.energy_ef_n2o_t2`,
			label: "module.ef_of_fuel_used_n2o",
			unit: "[tN2O-e/m3]",
			links: ["Fuel Type"],
			startProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_start_unit` },
			withoutProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_wo_unit` },
			withProps: { inputType: "number", labelSuffix: (index: number) => `fuels.${index}.fuel_type_w_unit` },
		},
	]

	return {
		name: EnergyModules.Fuel,
		inputName: 'fuels',
		inputGroups: [...emissionsByGas],
		route: 'fuels',
		cacheTag: 'FuelsDefaults',
		conditionalSection: {
			inputGroups: [accountsForCo2Emissions],
			conditionName: "is_solid_fuel_selected",
			initialValues: {
				accounts_for_co2_emissions: false
			}
		}
	};
}

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: [],
		mandatorySubmodules: [],
		optionalSubmodules: getOptionalSubmodules(),
	};

	const t2Schema: T2Schema = {
		submodules: [],
		optionalSubmodules: getT2OptionalSubmodules(),
	};


	return {
		moduleSchema,
		t2Schema,
	};
};

export const initialValues: ModuleFull = {
	electricities: [],
	fuels: [],
	parent: {
		id: null,
		note: null,
		activity: null,
		start_year: null,
		soc_t2_start: null,
		soc_t2_w: null,
		soc_t2_wo: null,
		status: null,
	},
};
