import ProjectSelectTable from "./ProjectSelectTable";
import Skeleton from "react-loading-skeleton";
import {
	ProjectDescription,
	StartNewProjectButton,
} from "./SelectProjectUtils";

import ConfirmationModal from "../../components/modal/ConfirmationModal";
import ErrorModal from "../../components/modal/ErrorModal";
import Pagination from "../../components/pagination/Pagination";
import TranslatableText from "../../components/translations/TranslatableText";
import DownloadExcels from "./DownloadExcels";
import { useAppContext } from "../../contexts/AppProvider";
import { useSelectProject } from "./hooks/useSelectProject";
import { baseUrl } from "../../app/features/api/apiSlice";

const ProjectBanner = ({ bucketUrl }: { bucketUrl: string }) => (
	<img
		src={`${bucketUrl}/project_select_banner.jpg`}
		alt="Kakuma, Kenya - Martha Kasafi, a refugee from Democratic Republic of Congo, works at her vegetable crops"
		className="w-100"
		style={{ aspectRatio: "700 / 383", objectFit: "cover", objectPosition: "top" }}
	/>
);

const ProjectHeader = ({ userName }: { userName: string }) => (
	<div className="d-flex justify-content-between">
		<ProjectDescription user={userName} />
		<a
			href={`${baseUrl}blog/ui/`}
			className="fs-13"
			target="_blank"
			rel="noopener noreferrer"
		>
			Release Notes
		</a>
	</div>
);

const SelectProject = () => {
	const {
		user,
		tableData,
		columns,
		isError,
		isLoading,
		isLoadingDelete,
		projectsCount,
		handleNewProject,
		handleNext,
		handlePrev,
		handlePageClick,
		isDeleteDialogOpen,
		handleDelete,
		handleCancel,
		isRemoveDialogOpen,
		handleRemove,
		handleCancelRemove,
		removeMeFromProjectErrorMsg,
		isRemoveMeFromProjectLoading,
		isRemoveMeFromProjectErrorMessageOpen,
		closeRemoveMeFromProjectErrorMessage,
		ITEMS_PER_PAGE,
	} = useSelectProject();

	const bucketUrl = useAppContext();

	const renderContent = () => {
		if (isLoadingDelete || isLoading) {
			return tableData?.map((_, index) => (
				<Skeleton key={index} width="100%" containerClassName="form-row-skeleton" />
			));
		}


		return (
			<>
				{isError ?
					<span className="error fs-13 pb-2">
						An error occurred while fetching projects data
					</span> :
					<ProjectSelectTable
						data={tableData}
						columns={columns}
					/>
				}
				<ConfirmationModal
					open={isDeleteDialogOpen}
					onCancel={handleCancel}
					onConfirm={handleDelete}
					message={<TranslatableText translationKey="project.delete_confirmation" />}
				/>
				<ConfirmationModal
					open={isRemoveDialogOpen}
					onCancel={handleCancelRemove}
					onConfirm={handleRemove}
					isLoading={isRemoveMeFromProjectLoading}
					message={<TranslatableText translationKey="project.remove_me_confirmation" />}
					headerText="project.remove_me_header"
				/>
				<ErrorModal
					isOpen={isRemoveMeFromProjectErrorMessageOpen}
					errorMessage={removeMeFromProjectErrorMsg}
					handleClose={closeRemoveMeFromProjectErrorMessage}
				/>
			</>
		);
	};

	const renderPagination = () => {

		if (projectsCount && projectsCount > ITEMS_PER_PAGE) {
			return (
				<Pagination
					totalItems={projectsCount}
					onNext={handleNext}
					onPrevious={handlePrev}
					onPageClick={handlePageClick}
					itemsPerPage={ITEMS_PER_PAGE}
					size="sm"
				/>
			);
		}

		if (projectsCount === 0 && !isLoading) {
			return (
				<div style={{ height: "5rem" }} className="w-100 d-flex align-items-center justify-content-center bg-white">
					<TranslatableText translationKey="project.no_projects_yet" className="ff-medium" />
				</div>
			);
		}

		return null;
	};

	return (
		<div className="login-container">
			<article className="bg-gray">
				<ProjectBanner bucketUrl={bucketUrl} />
				<div className="d-flex f-column pt-1 pb-2 px-2">
					<ProjectHeader userName={user?.first_name ?? ""} />
					{renderContent()}
					{renderPagination()}
					<div className="pt-2">
						<StartNewProjectButton handleClick={handleNewProject} />
					</div>
				</div>
				<DownloadExcels />
			</article>
		</div>
	);
};

export default SelectProject;
