import * as Yup from "yup";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { FieldType, ModuleSchema, StartWithWithoutTierTwo, T2Schema, type OptionalSubmodule, type StartWithWithoutGeneric } from "../../../../types/modulesInterfaces";
import { validatePositiveNumber } from "../moduleUtils";
import { ModuleFull, TransportEntryResponse } from "./transportTypes";

const fuelTypes = () => getOptions("fuelTypes", { search: ["mobile", "off-road"] })

export const validationSchema = () => {
  return Yup.object({
    transports: Yup.array().of(Yup.object({
      fuel_type_start: validatePositiveNumber.required("validations.fuel_type_required"),
      fuel_type_w: validatePositiveNumber.required("validations.fuel_type_required"),
      fuel_type_wo: validatePositiveNumber.required("validations.fuel_type_required"),
      quantity_consumed_per_year_start: validatePositiveNumber.required("validations.fuel_consumption_required"),
      quantity_consumed_per_year_w: validatePositiveNumber.required("validations.fuel_consumption_required"),
      quantity_consumed_per_year_wo: validatePositiveNumber.required("validations.fuel_consumption_required"),
    })),
  })
}

const getOptionalSubmodules: () => OptionalSubmodule<TransportEntryResponse>[] = () => {
  const fuelType: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    label: "module.type_of_fuel_required",
    inputName: (index: number) => `transports.${index}.fuel_type`,
    startProps: { dropdownOptions: fuelTypes },
    withProps: { dropdownOptions: fuelTypes },
    withoutProps: { dropdownOptions: fuelTypes },
  }
  const quantityConsumedPerYear: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: "module.fuel_consumption_required",
    inputName: (index: number) => `transports.${index}.quantity_consumed_per_year`,
    startProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_start_unit` },
    withProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_w_unit` },
    withoutProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_wo_unit` },
  }

  return [{
    name: "project.transport",
    inputName: "transports",
    inputGroups: [fuelType, quantityConsumedPerYear],
    repeatable: true,
    linkedTierTwoInputs: ["tiertwo.transports"],
    route: "transports",
    cacheTag: "TransportEntriesDefaults",
  }]
}

const getTransportEntryT2Submodule: () => OptionalSubmodule<TransportEntryResponse> = () => {
  const efRows: StartWithWithoutTierTwo[] = [
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `transports.${index}.energy_ef_co2_t2`,
      label: "module.ef_of_fuel_used_co2",
      unit: "[tCO2-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_start_unit` },
      withoutProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_wo_unit` },
      withProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_w_unit` },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `transports.${index}.energy_ef_ch4_t2`,
      label: "module.ef_of_fuel_used_ch4",
      unit: "[tCH4-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_start_unit` },
      withoutProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_wo_unit` },
      withProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_w_unit` },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `transports.${index}.energy_ef_n2o_t2`,
      label: "module.ef_of_fuel_used_n2o",
      unit: "[tN2O-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_start_unit` },
      withoutProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_wo_unit` },
      withProps: { inputType: "number", labelSuffix: (index: number) => `transports.${index}.fuel_type_w_unit` },
    },
  ];

  return {
    name: "project.transport",
    inputName: "transports",
    inputGroups: efRows,
    repeatable: true,
    linkedTierTwoInputs: ["tiertwo.transports"],
    route: "transports",
    cacheTag: "TransportEntriesDefaults",
  }
}

export const getSchemas = () => {
  const moduleSchema: ModuleSchema = {
    initInputGroups: [],
    mandatorySubmodules: [],
    optionalSubmodules: getOptionalSubmodules(),
  };

  const t2EntrySchema = getTransportEntryT2Submodule();
  const t2Schema: T2Schema = {
    submodules: [],
    optionalSubmodules: [t2EntrySchema],
  };

  return { moduleSchema, t2Schema, t2EntrySchema };
}

export const initialValues: ModuleFull = {
  transports: [],
  parent: {
    id: null,
    note: null,
    activity: null,
    start_year: null,
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    status: null,
  }
} 