import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { ModuleProps } from "../../../../types/modulesInterfaces";
import { T2Schema } from "../../../../types/modulesInterfaces";
import { ModuleFull, PackagingEntryResponse } from "./packagingTypes";
import { useCreatePackagingEntryMutation, useDeletePackagingEntryMutation, useGetPackagingEntriesQuery } from "../../../../app/features/api/modules/packagingApiSlice";
import { FEModules } from "../../../../utils/moduleList";
import { SetStateAction, useMemo, useEffect } from "react";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { useFormikContext } from "formik";
import { mergeLocalAndServerChanges } from "../inputs/utils";
import { isEqual } from 'lodash';
import { useVCEnergyHandler } from "../../../../utils/useVCEnergyHandler";
import GenericModule from "../GenericModule";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";

interface PackagingTierOneProps {
  initValues: ModuleFull;
  isEntriesLoading: boolean;
  setInitValues: (value: SetStateAction<ModuleFull>) => void
}

const PackagingTierOne = ({
  initValues,
  isEntriesLoading,
  setInitValues,
  ...moduleProps
}: ModuleProps & PackagingTierOneProps) => {
  const { values } = useFormikContext<ModuleFull>()
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { isReadOnly } = usePermissions()
  const { moduleList } = useAppSelector(selectCurrentBuilder);
  useVCEnergyHandler<ModuleFull, PackagingEntryResponse>({ listInputName: "packagings", energyTypeInputName: "fuel_type" })

  const parentId = useMemo(() => moduleList.find((m) => m.id === FEModules.Packaging)?.uniqueId, [moduleList]);
  const [deletePackagingEntry, { isLoading: isLoadingDeletePackagingEntry }] = useDeletePackagingEntryMutation()
  const [createPackagingEntry, { isLoading: isLoadingCreatePackagingEntry }] = useCreatePackagingEntryMutation()

  const { data: entriesData } = useGetPackagingEntriesQuery({ activityId: activeActivityId ?? 0 });
  const list = useMemo(() => {
    const isEntriesUpdated = !isEqual(values.packagings, entriesData)
    return isEntriesUpdated ? mergeLocalAndServerChanges({
      currentChangesList: values.packagings,
      incomingList: entriesData ?? [],
    }) : values.packagings
  }, [entriesData])

  useEffect(() => {
    if (list)
      setInitValues((cur) => ({ ...cur, packagings: list }))
  }, [list])

  const handleDeletePackaging = (
    remove: <T>(index: number) => T | undefined,
    index: number,
    id?: number | null,) => {
    remove(index)
    if (id) deletePackagingEntry({ id, activityId: activeActivityId ?? 0, parent: parentId })
  }
  const handleCreatePackaging = () => {
    createPackagingEntry({ activityId: activeActivityId ?? 0, parent: parentId })
  }

  return (
    <GenericModule {...moduleProps} disableOptionals>
      {
        () => (
          <>
            {moduleProps.moduleSchema.optionalSubmodules?.map((subModule, index) => {
              return (
                <GenericOptionalSubModule<PackagingEntryResponse>
                  key={`${subModule.name}-${index}`}
                  optionalSchema={subModule}
                  values={values?.packagings}
                  addLabel="module.add_new_packaging"
                  createHandler={handleCreatePackaging}
                  removeHandler={handleDeletePackaging}
                  fieldArrayName={subModule.name}
                  isOptionalLoading={isEntriesLoading || isLoadingCreatePackagingEntry || isLoadingDeletePackagingEntry}
                  isCreateLoading={isLoadingCreatePackagingEntry}
                  isDeleteLoading={isLoadingDeletePackagingEntry}
                  isReadOnly={isReadOnly}
                />
              )
            })}
          </>
        )
      }
    </GenericModule>
  );
}

export default PackagingTierOne;