import React, { useCallback, useMemo } from 'react'
import type {
    StartWithWithoutGeneric,
    HectaresInputGroup,
    InputGroup,
    DropdownInputGroup,
    T2Schema,
    ModuleSchema,
    Submodule,
    StartWithWithoutTierTwo,
    DropdownInputGroupTierTwo,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BEModules } from "../../../../utils/beModules";
import { useFetchLucTypes } from '../../../../app/hooks';


const useCoastalWetlandsSchema = () => {
    const { landUseTypes } = useFetchLucTypes({ beModuleId: BEModules.CoastalWetlands });
    const soilTypeOptions = () => getOptions("soil", { filterId: BEModules.CoastalWetlands })
    const salinityOptions = () => getOptions("salinity", { filterId: BEModules.CoastalWetlands })

    const getInitInputGroups: () => InputGroup[] = () => {
        const hectaresInputGroup: HectaresInputGroup = {
            type: FieldType.HECTARES,
            label: "module.hectares", unit: "[ha]", inputName: "module.area", disabled: true,
        };
        const typeOfVegetation: DropdownInputGroup = {
            type: FieldType.SELECT,
            inputName: "module.land_use_type",
            label: "module.type_of_vegetation",
            dropdownOptions: landUseTypes
        }
        const areaUnderDrainage: StartWithWithoutGeneric = {
            type: FieldType.SWW,
            inputName: "module.area_under_drainage",
            label: "module.area_under_drainage",
            unit: "[ha]"
        }
        const drainedAreaExcavated: StartWithWithoutGeneric = {
            type: FieldType.SWW,
            inputName: "module.drained_area_excavated",
            label: "module.drained_area_excavated",
            unit: "[ha]"
        }
        const areaNotDrainedOrRewetted: StartWithWithoutGeneric = {
            type: FieldType.SWW,
            inputName: "module.area_not_drained_or_rewetted",
            label: "module.area_not_drained",
            unit: "[ha]"
        }
        const areaWithRestoredVegetation: StartWithWithoutGeneric = {
            type: FieldType.SWW,
            inputName: "module.area_w_restored_vegetation",
            label: "module.area_with_restored_vegetation",
            unit: "[ha]"
        }

        return [hectaresInputGroup, typeOfVegetation, areaUnderDrainage, drainedAreaExcavated, areaNotDrainedOrRewetted, areaWithRestoredVegetation];
    };
    const getT2Submodules: () => Submodule[] = () => {
        const rows: StartWithWithoutTierTwo[] = [
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.soc_t2",
                label: "module.soil_carbon",
                unit: "[tC/ha]",
                links: [],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.pc_c_lost_after_excavation_t2",
                label: "module.percentage_c_lost_after_excavation",
                unit: "[%]",
                links: [],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.agb_t2",
                label: "module.above_ground_biomass",
                unit: "[tC/ha]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.bgb_t2",
                label: "module.below_ground_biomass",
                unit: "[tC/ha]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.litter_t2",
                label: "module.litter",
                unit: "[tC/ha]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.deadwood_t2",
                label: "module.dead_wood",
                unit: "[tC/ha]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.drainage_ef_t2",
                label: "module.ef_for_drainage",
                unit: "[tC/ha/yr]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.co2_rewetting_t2",
                label: "module.co2_from_rewetting",
                unit: "[tC/ha/yr]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.ch4_rewetting_t2",
                label: "module.ch4_from_rewetting",
                unit: "[kgCH4/ha/yr]",
                links: ["Type of Vegetation"],
                startProps: { inputType: "number", defaultValue: 0 },
                withoutProps: { inputType: "number", defaultValue: 0 },
                withProps: { inputType: "number", defaultValue: 0 },
            },

        ];

        const typeOfSoil: DropdownInputGroupTierTwo = {
            type: FieldType.SELECT_T2,
            inputName: "tiertwo.soil_type_t2",
            label: "activity.soil_type",
            unit: "[unit]",
            links: [],
            defaultValue: "value",
            dropdownOptions: soilTypeOptions
        }
        const salinity: DropdownInputGroupTierTwo = {
            type: FieldType.SELECT_T2,
            inputName: "tiertwo.avg_salinity_t2",
            label: "module.av_salinity",
            unit: "[unit]",
            links: [],
            defaultValue: "value",
            dropdownOptions: salinityOptions
        }

        return [
            {
                name: "",
                inputGroups: [typeOfSoil, ...rows, salinity],
            },
        ];
    };
    const getSchemas = () => {
        const moduleSchema: ModuleSchema = {
            initInputGroups: getInitInputGroups(),
            mandatorySubmodules: [],
            optionalSubmodules: [],
        };

        const t2Schema: T2Schema = {
            submodules: getT2Submodules(),
        };

        return { moduleSchema, t2Schema };
    };

    const { moduleSchema, t2Schema } = useMemo(() => {
        return getSchemas();
    }, []);

    return { moduleSchema, t2Schema };

}

export default useCoastalWetlandsSchema