import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FieldType, TranslationKey } from "../types/modulesInterfaces";
import i18n from "../i18n";

export const capitalize = (str: string | null) => {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : undefined;
};

export const capitalizeEach = (str: string) =>
	str ? str
		?.split(" ")
		.map((word) => capitalize(word))
		.join(" ") : undefined;

export function isFetchBaseQueryError(
	error: unknown,
): error is FetchBaseQueryError {
	return (
		typeof error === "object" &&
		error != null &&
		"status" in error &&
		"data" in error
	);
}

export const isSWW = (inputType: FieldType) =>
	[FieldType.SWW, FieldType.SWW_BOOL, FieldType.SWW_SELECT, FieldType.SWW_T2].includes(inputType);


export const translate = (key: TranslationKey) => {
	return i18n.t(key);
}

export const formatNumber = (num: number | string, decimalPlaces = 2): string => {
	let fixed
	if (typeof num === "string") {
		fixed = parseInt(parseInt(num).toFixed(decimalPlaces))
	} else {
		fixed = parseInt(num.toFixed(decimalPlaces));
	}
	const options = { maximumFractionDigits: 2 }
	const formattedNumber = Intl.NumberFormat("en-US", options).format(fixed);
	return formattedNumber
}

export const readableFileSize = (attachmentSize: number) => {
	const DEFAULT_SIZE = 0;
	const fileSize = attachmentSize ?? DEFAULT_SIZE;

	if (!fileSize) {
		return `${DEFAULT_SIZE} kb`;
	}

	const sizeInKb = fileSize / 1024;

	if (sizeInKb > 1024) {
		return `${(sizeInKb / 1024).toFixed(2)} mb`;
	} else if (sizeInKb > 1) {
		return `${sizeInKb.toFixed(2)} kb`;
	} else {
		return `${fileSize} bytes`;
	}
}
