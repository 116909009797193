import { useState } from 'react';
import { ProjectAttachment, useDeleteProjectAttachmentMutation, useGetProjectAttachmentsQuery, useLazyDownloadProjectAttachmentQuery } from "../../../../app/features/project/projectApiSlice";
import useErrorMessage from "../../../../utils/useErrorMessage";
import JSZip from 'jszip';

interface UseAttachmentManagerProps {
  projectId: number;
  projectName?: string;
}

interface UseAttachmentManagerReturn {
  attachments: ProjectAttachment[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  errorMsg: string;
  selectedAttachments: number[];
  isDeleteDialogOpen: boolean;
  isDeleteMultipleDialogOpen: boolean;
  isMultipleDownloadLoading: boolean;
  isMultipleDeleteLoading: boolean;
  isDeleteLoading: boolean;
  handleDeleteConfirm: () => Promise<void>;
  handleDeleteCancel: () => void;
  handleDelete: (attachment: ProjectAttachment) => void;
  handleDownload: (attachment: ProjectAttachment) => Promise<void>;
  handleSelect: (attachmentId: number) => void;
  handleUnselect: (attachmentId: number) => void;
  handleSelectAll: () => void;
  handleMultipleDownloadClick: () => Promise<void>;
  handleDeleteMultipleConfirm: () => Promise<void>;
  handleDeleteMultipleCancel: () => void;
  handleDeleteMultipleClick: () => void;
}

export const useAttachmentManager = ({ projectId, projectName }: UseAttachmentManagerProps): UseAttachmentManagerReturn => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState<ProjectAttachment | null>(null);
  const [selectedAttachments, setSelectedAttachments] = useState<number[]>([]);
  const [isDeleteMultipleDialogOpen, setIsDeleteMultipleDialogOpen] = useState(false);
  const [isMultipleDownloadLoading, setIsMultipleDownloadLoading] = useState(false);
  const [isMultipleDeleteLoading, setIsMultipleDeleteLoading] = useState(false);

  const { data: attachments, isLoading, isFetching, isError, error } = useGetProjectAttachmentsQuery(projectId, {
    skip: !projectId
  });
  const [deleteAttachment, { isLoading: isDeleteLoading }] = useDeleteProjectAttachmentMutation();
  const [triggerDownload] = useLazyDownloadProjectAttachmentQuery();

  const { errorMsg } = useErrorMessage({ isError, error });

  const handleDeleteConfirm = async () => {
    if (attachmentToDelete) {
      await deleteAttachment(attachmentToDelete.id);
      setIsDeleteDialogOpen(false);
      setAttachmentToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
    setAttachmentToDelete(null);
  };

  const handleDelete = (attachment: ProjectAttachment) => {
    setAttachmentToDelete(attachment);
    setIsDeleteDialogOpen(true);
  };

  const handleDownload = async (attachment: ProjectAttachment) => {
    try {
      const { data: blob } = await triggerDownload(attachment.id);
      if (!blob) return;

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.name);
      link.setAttribute('type', blob.type);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading attachment:", error);
    }
  };

  const handleSelect = (attachmentId: number) => {
    setSelectedAttachments((prev) => [...prev, attachmentId]);
  };

  const handleUnselect = (attachmentId: number) => {
    setSelectedAttachments((prev) => prev.filter((a) => a !== attachmentId));
  };

  const handleSelectAll = () => {
    if (attachments) {
      setSelectedAttachments(
        selectedAttachments.length === attachments.length
          ? []
          : attachments.map(a => a.id)
      );
    }
  };

  const handleMultipleDownloadClick = async () => {
    try {
      setIsMultipleDownloadLoading(true);
      const selectedFiles = attachments?.filter(a => selectedAttachments.includes(a.id)) ?? [];
      const zip = new JSZip();

      // Download all files
      const downloadPromises = selectedFiles.map(async (attachment) => {
        const { data: blob } = await triggerDownload(attachment.id);
        if (blob) {
          zip.file(attachment.name, blob);
        }
      });

      await Promise.all(downloadPromises);

      // Generate and download zip
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const url = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `attachments-${projectName}.zip`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading attachments:", error);
    } finally {
      setIsMultipleDownloadLoading(false);
    }
  };

  const handleDeleteMultipleConfirm = async () => {
    try {
      setIsMultipleDeleteLoading(true);
      const attachmentsToDelete = attachments?.filter(a => selectedAttachments.includes(a.id)) ?? [];
      setSelectedAttachments([]);

      await Promise.all(attachmentsToDelete.map(attachment => deleteAttachment(attachment.id)));
      setIsDeleteMultipleDialogOpen(false);
    } finally {
      setIsMultipleDeleteLoading(false);
    }
  };

  const handleDeleteMultipleCancel = () => {
    setIsDeleteMultipleDialogOpen(false);
  };

  const handleDeleteMultipleClick = () => {
    setIsDeleteMultipleDialogOpen(true);
  };

  return {
    attachments,
    isLoading,
    isFetching,
    errorMsg,
    selectedAttachments,
    isDeleteDialogOpen,
    isDeleteMultipleDialogOpen,
    isMultipleDownloadLoading,
    isMultipleDeleteLoading,
    isDeleteLoading,
    handleDeleteConfirm,
    handleDeleteCancel,
    handleDelete,
    handleDownload,
    handleSelect,
    handleUnselect,
    handleSelectAll,
    handleMultipleDownloadClick,
    handleDeleteMultipleConfirm,
    handleDeleteMultipleCancel,
    handleDeleteMultipleClick
  };
}; 