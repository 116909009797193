//this hook is used in Set Aside, Other Land and Settlement
//if we have a landUseChange we need to display its values, if not we display the module values. All of these are disabled

import useLandUseTypeInput from "../modules/useLandUseTypeInput";
import { useMemo } from "react";
import { useGetLandUseChangesQuery } from "../../../app/features/api/modules/landUseChangeApiSlice";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import { useAppSelector } from "../../../app/hooks";
import { FieldType, StartWithWithoutGeneric } from "../../../types/modulesInterfaces";
import { landUseOptions, mainActivityLandUseOptions } from "../modules/landUseChanges/landUseChange";
import { BEModules } from "../../../utils/beModules";

const useSpecialLandTypeInput = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);
  const { data: lucData, isLoading: isLoadingLuc } = useGetLandUseChangesQuery(activeActivityId ?? 0, {
    refetchOnMountOrArgChange: true,
  });

  const setAsideInput = useLandUseTypeInput({
    inputName: "module.land_use_type"
  });
  const lucInput = useLucInput("luc.module_type");
  const input = useMemo(() => lucData ? lucInput : setAsideInput, [lucData, setAsideInput]);

  return { input, lucData, isLoadingLuc }
}

const useLucInput = (inputName: string) => {
  const landUse: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    inputName: inputName,
    label: "module.type_of_land_use",
    startProps: { dropdownOptions: landUseOptions, disabled: true },
    withProps: { dropdownOptions: [...mainActivityLandUseOptions, { id: BEModules.OtherLand, name: "Other land" }], disabled: true },
    withoutProps: { dropdownOptions: landUseOptions, disabled: true },
  };

  return landUse
}

export default useSpecialLandTypeInput;