import { useEffect, useState } from "react";
import type { ErrorType } from "../types/interfaces";

const useErrorMessage = ({
	isError,
	error,
}: { error: ErrorType | null; isError: boolean }) => {
	const [errorMsg, setErrorMsg] = useState<string>("");

	useEffect(() => {
		setErrorMsg(extractErrorMessage(error));
	}, [error, isError]);

	return { errorMsg };
};

export const extractErrorMessage = (error: ErrorType | null) => {
	if (error && typeof error === "object") {
		if ("status" in error) {
			// you can access all properties of `FetchBaseQueryError` here
			const errMsg =
				"error" in error
					? error.error
					: JSON.parse(JSON.stringify(error.data));
			// console.log("fetchbasequeryerror", errMsg);


			if (typeof errMsg === "object" && 'details' in errMsg && typeof errMsg.details === "string")
				return errMsg.details;
			else if (typeof errMsg === "object" && 'details' in errMsg && typeof Array.isArray(errMsg.details))
				return errMsg.details[0];
			else if (typeof errMsg === "object" && 'error' in errMsg && typeof errMsg.error === "string")
				return errMsg.error;
			else if (typeof errMsg === "object" && 'non_field_errors' in errMsg && Array.isArray(errMsg.non_field_errors))
				return errMsg.non_field_errors[0]; // Set the first error message from the array
			else
				return "Something went wrong"
		} else {
			// you can access all properties of `SerializedError` here
			const errMsg = error.message;
			console.log("serialized error", errMsg);
			if (errMsg) return errMsg;
		}
	}
	return "";
}

export default useErrorMessage;

