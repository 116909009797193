import { createMachine, assign, raise } from "xstate";
import {
	initStep,
	isOnOrganicSoil,
	mainActivityFocus,
	hectares,
	previousLandUse,
	areaWithoutProject,
	livestockFeedOrigin,
	fishFeedOrigin,
	includesInputs,
	usesEnergy,
	fisheriesSize,
	usesValueChain,
} from "./setupAssistantUtils";
import { FormEvents, FormStates } from "./setupAssistant.types"
import type {
	FormMachineEvents,
	Context,
	SubmodulePlus,
	SetupAssistantForm,
	ContextModules,
} from "./setupAssistant.types";
import * as Yup from "yup";
import { FEModules } from "../../../../utils/moduleList";
import { useMachine } from "@xstate/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { setProgressiveModules } from "../../../../app/features/setupAssistant/setupAssistantSlice";
import { useGetChangeRatesQuery } from "../../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import { validatePositiveNumber } from "../../../activityBuilder/modules/moduleUtils";
import { useHandleProjectState } from "../../useHandleProjectState";
import useErrorMessage from "../../../../utils/useErrorMessage";

/* STATE MACHINE */
const formMachine = createMachine<Context, FormMachineEvents>(
	{
		id: "setup-assistant",
		context: {
			schema: [],
			values: {},
			modules: [],
		},
		initial: FormStates.initInfo,
		states: {
			[FormStates.initInfo]: {
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.mainActivityFocus],
						actions: ["addMainActivity", "pushValues"],
					},
					[FormEvents.SET_INIT_VALUES]: { actions: ["addInitalInfo"] },
					[FormEvents.SKIP]: {
						target: [FormStates.finish],
						actions: ["pushValues"],
					},
				},
			},
			[FormStates.mainActivityFocus]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.mainActivityFocus,
						),
				}),
				on: {
					[FormEvents.NEXT]: [
						{
							target: [FormStates.hectares],
							actions: [
								"addHectares",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.mainActivityFocus,
								})),
							],
							cond: (_, { next }) => next === FormStates.hectares,
						},
						{
							target: [FormStates.isOrganicSoil],
							actions: [
								"addOrganicSoil",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.mainActivityFocus,
								})),
							],
							cond: (_, { next }) => next === FormStates.isOrganicSoil,
						},
						{
							target: [FormStates.livestockFeedOrigin],
							actions: [
								"addLivestock",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.isOrganicSoil,
								})),
							],
							cond: (_, { next }) => next === FormStates.livestockFeedOrigin,
						},
						{
							target: [FormStates.fisheriesSize],
							actions: [
								"addFisheriesSize",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.isOrganicSoil,
								})),
							],
							cond: (_, { next }) => next === FormStates.fisheriesSize,
						},
						{
							target: [FormStates.fishFeedOrigin],
							actions: [
								"addFishFeed",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.isOrganicSoil,
								})),
							],
							cond: (_, { next }) => next === FormStates.fishFeedOrigin,
						},
						{
							target: [FormStates.includesInputs],
							actions: [
								"addIncludeInputs",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.isOrganicSoil,
								})),
							],
							cond: (_, { next }) => next === FormStates.includesInputs,
						},
						{
							target: [FormStates.usesValueChain],
							actions: [
								"addUsesValueChain",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.mainActivityFocus,
								})),
							],
							cond: (_, { next }) => next === FormStates.usesValueChain,
						},
					],
					[FormEvents.BACK]: {
						target: [FormStates.initInfo],
						actions: ["removeLast", "pushValues"],
					},
				},
			},
			[FormStates.isOrganicSoil]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.isOrganicSoil,
						),
				}),
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.hectares],
						actions: [
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.isOrganicSoil,
							})),
							"addHectares",
							"pushValues",
						],
					},
					[FormEvents.BACK]: {
						target: [FormStates.mainActivityFocus],
						actions: ["removeLast", "pushValues"],
					},
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.hectares]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter((el) => el.generatedBy !== FormStates.hectares),
				}),
				on: {
					[FormEvents.NEXT]: [
						{
							target: [FormStates.previousLandUse],
							actions: ["addPreviousLandUse", "pushValues"],
							cond: ({ values }) => values.mainActivityFocus !== FEModules.Waterbodies,
						}, //means: if mainActivityFocus is Waterbodies
						{
							target: [FormStates.usesEnergy],
							actions: ["addEnergyUse", "pushValues"],
							cond: ({ values }) => values.mainActivityFocus === FEModules.Waterbodies,
						},
					],
					[FormEvents.BACK]: [
						{
							target: [FormStates.isOrganicSoil],
							actions: ["removeLast", "pushValues"],
							cond: (_, { prev }) => prev === FormStates.isOrganicSoil,
						},
						{
							target: [FormStates.mainActivityFocus],
							actions: ["removeLast", "pushValues"],
							cond: (_, { prev }) => prev === FormStates.mainActivityFocus,
						}
					],
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.previousLandUse]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.previousLandUse,
						),
				}),
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.areaWithoutProject],
						actions: [
							"addAreaWithoutProject",
							"pushValues",
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.previousLandUse,
							})),
						],
					},
					[FormEvents.BACK]: {
						target: [FormStates.hectares],
						actions: ["removeLast", "pushValues"],
					},
				},
			},
			[FormStates.areaWithoutProject]: {
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.includesInputs],
						actions: [
							"addIncludeInputs",
							"pushValues",
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.areaWithoutProject,
							})),
						],
					},
					[FormEvents.BACK]: {
						target: [FormStates.previousLandUse],
						actions: ["removeLast", "pushValues"],
					},
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.livestockFeedOrigin]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.livestockFeedOrigin,
						),
				}),
				on: {
					[FormEvents.NEXT]: [
						{
							target: [FormStates.includesInputs],
							actions: [
								"addIncludeInputs",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.livestockFeedOrigin,
								})),
							],
						},
					],
					[FormEvents.BACK]: {
						target: [FormStates.mainActivityFocus],
						actions: ["removeLast", "pushValues"],
					},
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.fishFeedOrigin]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.fishFeedOrigin,
						),
				}),
				on: {
					[FormEvents.NEXT]: [
						{
							target: [FormStates.includesInputs],
							actions: [
								"addIncludeInputs",
								"pushValues",
								raise((_, { modules }) => ({
									type: FormEvents.PUSH_MODULES,
									modules,
									generatedBy: FormStates.fishFeedOrigin,
								})),
							],
						},
					],
					[FormEvents.BACK]: {
						target: [FormStates.mainActivityFocus],
						actions: ["removeLast", "pushValues"],
					},
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.fisheriesSize]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.fisheriesSize,
						),
				}),
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.usesEnergy],
						actions: [
							"addEnergyUse",
							"pushValues",
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.fisheriesSize,
							})),
						],
					},
					[FormEvents.BACK]: {
						target: [FormStates.mainActivityFocus],
						actions: ["removeLast", "pushValues"],
					},
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.includesInputs]: {
				entry: assign({
					modules: (ctx) =>
						ctx.modules.filter(
							(el) => el.generatedBy !== FormStates.includesInputs,
						),
				}),
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.usesValueChain], //FormStates.usesVC and from VC we'll go to finish
						actions: [
							"addUsesValueChain",
							"pushValues",
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.includesInputs,
							})),
						],
					},
					[FormEvents.BACK]: [
						{
							target: [FormStates.mainActivityFocus],
							cond: (_, { prev }) => prev === FormStates.mainActivityFocus,
							actions: ["removeLast", "pushValues"],
						},
						{
							target: [FormStates.livestockFeedOrigin],
							cond: (_, { prev }) => prev === FormStates.livestockFeedOrigin,
							actions: ["removeLast", "pushValues"],
						},
						{
							target: [FormStates.fishFeedOrigin],
							cond: (_, { prev }) => prev === FormStates.fishFeedOrigin,
							actions: ["removeLast", "pushValues"],
						},
						{
							target: [FormStates.areaWithoutProject],
							cond: (_, { prev }) => prev === FormStates.areaWithoutProject,
							actions: ["removeLast", "pushValues"],
						},
					],
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.usesEnergy]: {
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.finish],
						actions: [
							"pushValues",
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.usesEnergy,
							})),
						],
					},
					[FormEvents.BACK]: [
						{
							target: [FormStates.fisheriesSize],
							cond: (_, { prev }) => prev === FormStates.fisheriesSize,
							actions: ["removeLast", "pushValues"],
						},
						{
							target: [FormStates.hectares],
							cond: (_, { prev }) => prev === FormStates.hectares,
							actions: ["removeLast", "pushValues"],
						},
					],
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.usesValueChain]: {
				on: {
					[FormEvents.NEXT]: {
						target: [FormStates.finish],
						actions: [
							"pushValues",
							raise((_, { modules }) => ({
								type: FormEvents.PUSH_MODULES,
								modules,
								generatedBy: FormStates.usesValueChain,
							})),
						],
					},
					[FormEvents.BACK]: [
						{
							target: [FormStates.includesInputs],
							cond: (_, { prev }) => prev === FormStates.includesInputs,
							actions: ["removeLast", "pushValues"],
						},
						{
							target: [FormStates.mainActivityFocus],
							cond: (_, { prev }) => prev === FormStates.mainActivityFocus,
							actions: ["removeLast", "pushValues"],
						},
					],
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
				},
			},
			[FormStates.finish]: {
				on: {
					[FormEvents.PUSH_MODULES]: { actions: "pushModules" },
					[FormEvents.BACK]:
						[
							{
								target: [FormStates.initInfo],
								actions: "returnToInitial",
							},
						]

				},
			},
		},
		predictableActionArguments: true,
	},
	{
		actions: {
			returnToInitial: assign({
				schema: (context) => {
					return [context.schema[0]];
				},
			}),
			pushModules: assign({
				modules: (context, { generatedBy, modules }) => {
					let tempMods = [...context.modules];
					modules?.forEach((mod) => {
						const index = tempMods.findIndex((el) => el.module === mod);
						if (index === -1)
							tempMods = [
								...tempMods,
								{
									module: mod,
									generatedBy: generatedBy ?? FormStates.initInfo,
								},
							];
					});
					return tempMods;
				},
			}),
			pushValues: assign({
				values: (context, { values }) => ({ ...context.values, ...values }),
			}),
			addInitalInfo: assign({
				schema: (context, { schemaStep }) =>
					schemaStep ? [...context.schema, schemaStep] : context.schema,
			}),
			addOrganicSoil: assign({
				schema: (context) => [...context.schema, isOnOrganicSoil],
			}),
			addMainActivity: assign({
				schema: (context) => [...context.schema, mainActivityFocus],
			}),
			addHectares: assign({
				schema: (context) => [...context.schema, hectares],
			}),
			addPreviousLandUse: assign({
				schema: (context) => [...context.schema, previousLandUse],
			}),
			addLivestock: assign({
				schema: (context) => [...context.schema, livestockFeedOrigin],
			}),
			addIncludeInputs: assign({
				schema: (context) => [...context.schema, includesInputs],
			}),
			addFishFeed: assign({
				schema: (context) => [...context.schema, fishFeedOrigin],
			}),
			addFisheriesSize: assign({
				schema: (context) => [...context.schema, fisheriesSize],
			}),
			addAreaWithoutProject: assign({
				schema: (context) => [...context.schema, areaWithoutProject],
			}),
			addEnergyUse: assign({
				schema: (context) => [...context.schema, usesEnergy],
			}),
			addUsesValueChain: assign({
				schema: (context) => [...context.schema, usesValueChain],
			}),
			removeLast: assign({
				schema: (context) => {
					context.schema.pop();
					return [...context.schema];
				},
			}),
		},
		guards: {},
	},
);

export interface MachineProps {
	goNext: ({
		next,
		values,
		modules,
	}: {
		next?: FormStates;
		values?: SetupAssistantForm;
		modules?: FEModules[];
	}) => void;
	goBack: ({ values }: { values?: SetupAssistantForm }) => void;
	schema: SubmodulePlus[];
	values: SetupAssistantForm;
	modules: ContextModules[];
	currentState: string;
	skip: (values: SetupAssistantForm) => void;
	canSkip: boolean;
	canBack: boolean;
	isFinal: boolean | undefined;
	errorMsg: string;
	isError: boolean
}

export function useFormMachine(): MachineProps {
	const dispatch = useAppDispatch();
	const [state, send] = useMachine(formMachine);
	const { data: changeRates, isError: isChangeRatesError, error: changeRatesError } = useGetChangeRatesQuery();
	const { project } = useHandleProjectState();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (project && state.value === FormStates.initInfo && changeRates) {
			const { climate, soil_type, implementation_years, moisture, start_year_of_activities, last_year_of_accounting } = project; //date
			const defaultChangeRate = changeRates?.find(rate => rate.name === "linear") ?? undefined;
			const initialValues = {
				activityTitle: undefined,
				duration: implementation_years,
				climate: climate?.id,
				moisture: moisture?.id,
				typeOfSoil: soil_type?.id,
				startYear: start_year_of_activities,
				last_year_of_accounting: last_year_of_accounting,
				activityCost: 0,
				changeRate: defaultChangeRate?.id
			};

			const validationSchema = Yup.object({
				activityTitle: Yup.string().required(),
				duration: validatePositiveNumber.required().test(
					"start-year-duration-check",
					"Activity Start Year + Duration cannot exceed Project Start Year + Implementation Phase",
					function (value) {
						const { startYear, duration } = this.parent;
						const projectStartYear = project?.start_year_of_activities; // Adjust this line based on your project structure
						const implementationYears = project?.implementation_years;
						return startYear + duration <= projectStartYear + implementationYears;
					}
				),
				climate: validatePositiveNumber.required(),
				moisture: validatePositiveNumber.required(),
				typeOfSoil: validatePositiveNumber.required(),
				startYear: validatePositiveNumber.required().min(1900).test('start-year-check', 'Start Year should be greater or equalto Project Start Year', function (value) {
					const { startYear } = this.parent;
					return startYear >= project?.start_year_of_activities;
				}),
				last_year_of_accounting: validatePositiveNumber.required().test('ly-check', 'Last Year of Accounting should be greater or equal to Activity Start Year + Duration', function (value) {
					const { last_year_of_accounting, duration, startYear } = this.parent;
					return last_year_of_accounting >= startYear + duration;
				}).test('ly-check', 'Last Year of Accounting should be less than or equal to Project last year of accounting', function (value) {
					const { last_year_of_accounting } = this.parent;
					return last_year_of_accounting <= project?.last_year_of_accounting;
				}),
				changeRate: validatePositiveNumber.required(),
				activityCost: validatePositiveNumber.required()
			});

			send({
				type: FormEvents.SET_INIT_VALUES,
				schemaStep: { ...initStep, initialValues, validationSchema },
			});
		}
	}, [project, send, changeRates]);

	useEffect(() => {
		if (state.context.modules.length > 0) {
			const modules = state.context.modules.map((mod) => mod.module);
			dispatch(setProgressiveModules(modules));
		}
	}, [state.context.modules, dispatch]);

	const currentState = useMemo(() => state.value.toString(), [state]);
	const isFinal = useMemo(() => state.value === "finish", [state]);
	const history = useMemo(() => state.history, [state]);
	const canSkip = useMemo(() => state.can({ type: FormEvents.SKIP }), [state]);
	const canBack = useMemo(
		() => state.can({ type: FormEvents.BACK, prev: history?.value }),
		[state, history],
	);

	const goBack = useCallback(
		({ values }: { values?: SetupAssistantForm }) => {
			send({ type: FormEvents.BACK, prev: history?.value, values });
		},
		[send, history],
	);
	const goNext = useCallback(
		({
			next,
			values,
			modules,
		}: {
			next?: FormStates;
			values?: SetupAssistantForm;
			modules?: FEModules[];
		}) => {
			send({ type: FormEvents.NEXT, next, values, modules });
		},
		[send],
	); //todo change to accept id or values
	const skip = useCallback(
		(values: SetupAssistantForm) => send({ type: FormEvents.SKIP, values }),
		[send],
	);
	const { errorMsg } = useErrorMessage({ error: changeRatesError, isError: isChangeRatesError });

	return {
		goNext,
		goBack,
		skip,
		schema: state.context.schema,
		values: state.context.values,
		modules: [...state.context.modules],
		currentState,
		canBack,
		canSkip,
		isFinal,
		isError: isChangeRatesError,
		errorMsg
	};
}
