import * as Yup from "yup";
import type { ActivityUpdatePayload } from "../../app/features/activities/activityTypes";
import { useUpdateActivityMutation } from "../../app/features/activities/activityApiSlice";
import { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { validatePositiveNumber } from "../activityBuilder/modules/moduleUtils";
import { useId } from "./EditActivityContainer";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import { useAppSelector } from "../../app/hooks";
import { getCapitalizationYears } from "../../utils/projectUtils";
import { initValues } from "./utils";
import { BiSave } from "react-icons/bi";
import { useFirebaseHook, EventTypes } from "../../utils/firebaseUtils";
import { useHandleActivityState } from "../activityBuilder/hooks/useHandleActivityState";
import TranslatableText from "../../components/translations/TranslatableText";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import EditActivityContent from "./EditActivityContent";
import ModuleButton from "../../components/button/ModuleButton";
import useErrorMessage from "../../utils/useErrorMessage";


const EditActivity = () => {
  const { id } = useId()
  const { project } = useAppSelector(selectCurrentProject);
  const { activity, isFetching, isError, error } = useHandleActivityState();
  const { fireEvent } = useFirebaseHook();
  const [initialvalues, setInitialvalues] =
    useState<ActivityUpdatePayload>(initValues);
  const navigate = useNavigate();


  const [
    updateActivity,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateActivityMutation();

  const { errorMsg: blockingError } = useErrorMessage({ isError, error });
  const { errorMsg: updateErrorMessage } = useErrorMessage({
    isError: isUpdateError,
    error: updateError,
  });

  const validationSchema = useMemo(() =>
    Yup.object().shape({
      id: validatePositiveNumber.required(),
      activity: Yup.object().shape({
        name: Yup.string().required("validations.activity_name_required"),
        duration_t2: validatePositiveNumber.required("validations.duration_required").min(1, "validations.duration_min"),
        climate_t2: Yup.string().nullable().required("validations.climate_required"),
        moisture_t2: Yup.string().nullable().required("validations.moisture_required"),
        soil_type_t2: Yup.string().nullable().required("validations.soil_type_required"),
        start_year_t2: validatePositiveNumber.nullable().required().test("sy-greater-than-project", "Activity Start Year must be greater or equal to Project Start Year", function (value) {
          const { start_year_t2 } = this.parent;
          return start_year_t2 >= (project?.start_year_of_activities ?? 0);
        }),
        last_year_of_accounting_t2: validatePositiveNumber.nullable().required("validations.last_year_of_accounting_required").test('ly-lower-than-project', 'Activity Last year of accounting must be lower or equal to Project Last Year of Accounting', function (value) {
          const { last_year_of_accounting_t2 } = this.parent;
          return last_year_of_accounting_t2 <= (project?.last_year_of_accounting ?? 0);
        }).test('ly-greater-than-ip', 'Activity Last year of accounting must be greater or equal to Duration + Start Year', function (value) {
          const { last_year_of_accounting_t2, duration_t2, start_year_t2 } = this.parent;
          return last_year_of_accounting_t2 >= duration_t2 + start_year_t2;
        }),
        change_rate: validatePositiveNumber.nullable().required("validations.change_rate_required"),
        cost: validatePositiveNumber.nullable().required().test(
          "cost-grater-than-project",
          "Activity cost must be less than Project Cost",
          function (value) {
            if (project?.cost == null) return true;
            const { cost } = this.parent;
            return cost <= project.cost;
          }
        ),
        capitalization_years: validatePositiveNumber.nullable().min(0, "validations.cap_phase_min"),
      }),
    }), [project])

  useEffect(() => {
    const duration = activity?.duration_t2 ?? project?.implementation_years
    const startYear = activity?.start_year_t2 ?? project?.start_year_of_activities
    const moisture = activity?.moisture_t2?.id ?? project?.moisture?.id
    const soilType = activity?.soil_type_t2?.id ?? project?.soil_type?.id
    const climate = activity?.climate_t2?.id ?? project?.climate?.id
    const lastYearOfAccounting = activity?.last_year_of_accounting_t2 ?? project?.last_year_of_accounting

    const initValues: ActivityUpdatePayload = {
      id: id ?? 0,
      activity: {
        name: activity?.name ?? "",
        duration_t2: duration,
        start_year_t2: startYear,
        last_year_of_accounting_t2: lastYearOfAccounting,
        climate_t2: climate,
        moisture_t2: moisture,
        soil_type_t2: soilType,
        cost: activity?.cost,
        change_rate: activity?.change_rate?.id,
        capitalization_years: getCapitalizationYears(startYear, duration, lastYearOfAccounting),
      },
    };
    setInitialvalues(initValues);
  }, [activity, id, project]);


  const handleSubmit = (values: ActivityUpdatePayload) => {
    try {
      const payload = {
        ...values,
        activity: {
          ...values.activity,
          duration_t2: values.activity.duration_t2 === project?.implementation_years ? null : values.activity.duration_t2,
          start_year_t2: values.activity.start_year_t2 === project?.start_year_of_activities ? null : values.activity.start_year_t2,
          climate_t2: values.activity.climate_t2 === project?.climate?.id ? null : values.activity.climate_t2,
          moisture_t2: values.activity.moisture_t2 === project?.moisture?.id ? null : values.activity.moisture_t2,
          soil_type_t2: values.activity.soil_type_t2 === project?.soil_type?.id ? null : values.activity.soil_type_t2,
          last_year_of_accounting_t2: values.activity.last_year_of_accounting_t2 === project?.last_year_of_accounting ? null : values.activity.last_year_of_accounting_t2,
        }
      }
      updateActivity(payload).unwrap().then(() => fireEvent(EventTypes.edit_activity));
    } catch (error) {
      console.error(error);
    }
  };

  return blockingError ? (
    <section>
      <div className="module-group">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-14 ff-medium-ext module-header w-fit">
            <TranslatableText translationKey="main.edit_activity" />
          </h2>
          <div className="module-number header-number ff-light-ext">{id}</div>
        </div>

        <span className="error fs-13 pb-2">{blockingError}</span>
      </div>
    </section>
  ) : (
    <section>
      <div className="module-group">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-14 ff-medium-ext module-header w-fit">
            <TranslatableText translationKey="main.edit_activity" />
          </h2>
          {/* <div className="module-number header-number ff-light-ext"></div> */}
        </div>

        <div className="d-flex f-column" style={{ maxWidth: "550px" }}>
          <Formik
            initialValues={initialvalues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            <Form>
              <EditActivityContent isLoading={isFetching || isUpdateLoading} />
              <p
                className={`text-error pt-2 fs-13 ${updateErrorMessage && updateErrorMessage.length > 0
                  ? "pb-2"
                  : null
                  }`}
              >
                {updateErrorMessage}
              </p>
              <div className="pb-2 d-flex align-items-center gap-8">
                <ModuleButton
                  disabled={isUpdateLoading}
                  isLoading={isUpdateLoading}
                  buttonType="button"
                  handleClick={() => navigate("../edit-modules", { relative: "path" })}
                  labelKey="main.change_modules"
                />
                <ModuleButton
                  disabled={isUpdateLoading}
                  isLoading={isUpdateLoading}
                  icon={BiSave}
                  labelKey="main.save_changes"
                />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {
        isUpdateSuccess && <EditActivitySuccessModal />
      }
    </section>
  )
};

export default EditActivity;

const EditActivitySuccessModal = () => {
  const { project } = useAppSelector(selectCurrentProject);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleConfirmation = () => {
    setIsModalOpen(false);
    navigate(`/project/${project?.id ?? "new"}/activities`);
  };

  return (
    <ConfirmationModal
      onConfirm={handleConfirmation}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      message={<TranslatableText translationKey="main.edit_activity_success" />}
      confirmationMessage="main.back_to_activities"
      cancelButtonMessage="main.keep_editing"
    />
  )
}
