import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, usePermissions } from "../../../app/hooks";
import { selectCurrentUser } from "../../../app/features/auth/authSlice";
import { clearProject } from "../../../app/features/project/projectSlice";
import { useCreateProjectCopyMutation, useDeleteMembershipMutation, useGetProjectsQuery, useLazyGetMyMembershipQuery } from "../../../app/features/project/projectApiSlice";
import { createColumnHelper, type ColumnDef } from "@tanstack/react-table";
import { FastProject, Role, User } from "../../../types/interfaces";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import { MdContentCopy, MdPersonRemove } from "react-icons/md";
import { HeaderSpan } from "../SelectProjectUtils";
import useDeleteProject from "../useDeleteProject";
import useRemoveMeFromProject from "./useRemoveMeFromProject";
import { usePaginate } from "../../project/activities/usePaginate";
import type { Line } from "../../../components/kebabMenu/KebabMenu";
import { WrappedKebabMenu } from "../components/WrappedKebabMenu";
import { format } from 'date-fns';
import { useFirebaseHook } from "../../../utils/firebaseUtils";
import { EventTypes } from "../../../utils/firebaseUtils";

const ITEMS_PER_PAGE = 15;

interface SelectProjectHookReturn {
    user: User | null;
    tableData: FastProject[];
    columns: ColumnDef<FastProject, any>[];
    isError: boolean;
    isLoading: boolean;
    isLoadingDelete: boolean;
    projectsCount: number;
    handleNewProject: () => void;
    handleNext: () => void;
    handlePrev: () => void;
    handlePageClick: (page: number) => void;
    isDeleteDialogOpen: boolean;
    handleDelete: () => void;
    handleCancel: () => void;
    isRemoveDialogOpen: boolean;
    handleRemove: () => void;
    handleCancelRemove: () => void;
    removeMeFromProjectErrorMsg: string;
    isRemoveMeFromProjectLoading: boolean;
    isRemoveMeFromProjectErrorMessageOpen: boolean;
    closeRemoveMeFromProjectErrorMessage: () => void;
    ITEMS_PER_PAGE: number;
}

interface TableInfo {
    row: {
        original: FastProject;
    };
    getValue: () => any;
}

export const useSelectProject = (): SelectProjectHookReturn => {
    const user: User | null = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [url, setUrl] = useState<string>(`${baseUrl}/projects/?page=1&page_size=${ITEMS_PER_PAGE}`);
    const { fireEvent } = useFirebaseHook();
    useEffect(() => {
        dispatch(clearProject());
    }, [dispatch]);

    const {
        data: projectsRes,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useGetProjectsQuery({ url, fast: true }, {
        refetchOnMountOrArgChange: true,
    });

    const {
        results: projects = [],
        count: projectsCount = 0,
        next,
        previous,
    } = projectsRes || {};

    const { handleNext, handlePrev, handlePageClick } = usePaginate({
        next,
        previous,
        dynamicNext: (page: number) => `/projects/?page=${page}&page_size=${ITEMS_PER_PAGE}`,
        setUrl
    });

    const { showDeleteModal, isDeleteDialogOpen, handleDelete, handleCancel, isLoadingDelete } =
        useDeleteProject(refetch);

    const {
        showRemoveModal,
        isRemoveDialogOpen,
        handleRemove,
        handleCancel: handleCancelRemove,
        removeMeFromProjectErrorMsg,
        isRemoveMeFromProjectLoading,
        isRemoveMeFromProjectErrorMessageOpen,
        closeRemoveMeFromProjectErrorMessage,
    } = useRemoveMeFromProject(user?.id ?? 0, refetch);

    const [copyProject, { isLoading: isLoadingCopy }] = useCreateProjectCopyMutation();

    const tableData = useMemo(
        () => (isLoading ? Array(2).fill({}) : projects) as FastProject[],
        [isLoading, projects],
    );

    const handleNewProject = () => {
        dispatch(clearProject());
        navigate("/project/new/description");
    };

    const columnHelper = createColumnHelper<FastProject>();

    const duplicateSelectedProject = useCallback(
        async (info: TableInfo) => {
            try {
                const project = info?.row?.original;

                if (!project?.id || isLoadingCopy) {
                    return;
                }

                await copyProject(project.id).unwrap();
                fireEvent(EventTypes.duplicate_project);
                await refetch();
            } catch (error) {
                console.error('Failed to duplicate project:', error);
            }
        },
        [copyProject, refetch, isLoadingCopy],
    );

    const removeMeFromProject = useCallback(
        (info: TableInfo) => {
            const project = info?.row?.original;
            showRemoveModal(project);
        },
        [showRemoveModal],
    );

    const editProject = useCallback(
        async (info: TableInfo) => {
            try {
                const project = info?.row?.original;

                if (!project?.id) {
                    console.warn('No project ID found for editing');
                    return;
                }

                navigate(`/project/${project.id}/description`);
            } catch (error) {
                console.error('Failed to navigate to project edit:', error);
            }
        },
        [navigate],
    );

    const { getUserRole } = usePermissions();

    const columns = useMemo(() => {
        const handleSelectedProject = (info: TableInfo) => {
            const project = info?.row?.original;
            navigate(`/project/${project.id}/activities`);
        };

        return [
            columnHelper.accessor("name", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <div
                            className="fs-9 clickable span"
                            onClick={() => handleSelectedProject(info)}
                        >
                            {value}
                        </div>
                    );
                },
                header: () => <HeaderSpan name="project.project" />,
                enableSorting: true,
                sortingFn: "alphanumeric",
            }),
            columnHelper.accessor("country", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <div className="fs-9 span">
                            {value}
                        </div>
                    );
                },
                header: () => <HeaderSpan name="main.location" />,
                enableSorting: true,
                sortingFn: "alphanumeric",
            }),
            columnHelper.accessor("role", {
                cell: (info) => {
                    const roles = info.getValue();
                    if (Array.isArray(roles)) {
                        return (
                            <div className="fs-9 span">
                                {roles.join(", ")}
                            </div>
                        );
                    }
                    return roles;
                },
                header: () => <HeaderSpan name="main.role" />,
                enableSorting: true,
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.getValue(columnId) as string[];
                    const b = rowB.getValue(columnId) as string[];
                    return a[0].localeCompare(b[0]);
                }
            }),
            columnHelper.accessor("updated_at", {
                cell: (info) => {
                    const lastUpdated = info.getValue();
                    const date = new Date(lastUpdated);
                    const isValidDate = !isNaN(date.getTime());
                    return (
                        <div className="fs-9 span">
                            {isValidDate ? date.toLocaleString() : "-"}
                        </div>
                    );
                },
                header: () => <HeaderSpan name="main.last_updated" />,
                enableSorting: true,
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.getValue(columnId) as string;
                    const b = rowB.getValue(columnId) as string;
                    return a.localeCompare(b);
                }
            }),
            columnHelper.accessor("created_at", {
                cell: (info) => {
                    const createdAt = info.getValue();
                    const date = new Date(createdAt);
                    const isValidDate = !isNaN(date.getTime());
                    return (
                        <div className="fs-9 span">
                            {isValidDate ? format(date, 'dd/MM/yyyy') : "-"}
                        </div>
                    );
                },
                header: () => <HeaderSpan name="main.created_at" />,
                enableSorting: true,
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.getValue(columnId) as string;
                    const b = rowB.getValue(columnId) as string;
                    return a.localeCompare(b);
                }
            }),
            columnHelper.display({
                id: "edit",
                cell: (info) => {
                    const roles = info?.row?.original.role;
                    const isAdminOrAnalyst = roles?.includes(Role.ADMIN) || roles?.includes(Role.ANALYST);

                    const mutateLines: Line[] = [
                        {
                            id: 3,
                            label: "main.delete",
                            icon: BiTrash,
                            clickHandler: () => showDeleteModal(info?.row?.original),
                            activeColor: "var(--error)"
                        },
                        {
                            id: 2,
                            label: "main.edit",
                            icon: BiEditAlt,
                            clickHandler: () => editProject(info),
                        },
                    ];
                    const removeMeLine: Line = {
                        id: 4,
                        label: "main.remove_me_from_project",
                        icon: MdPersonRemove,
                        clickHandler: () => removeMeFromProject(info),
                    }
                    const duplicateLine: Line = {
                        id: 1,
                        label: "main.duplicate",
                        icon: MdContentCopy,
                        clickHandler: () => duplicateSelectedProject(info),
                    };

                    const actionLines = [
                        duplicateLine,
                        removeMeLine,
                        ...(isAdminOrAnalyst ? mutateLines : []),
                    ];

                    return <WrappedKebabMenu lines={actionLines} />;
                },
                header: () => (
                    <div className="w-100 text-center">
                        <HeaderSpan name="main.edit" />
                    </div>
                ),
            }),
        ];
    }, [navigate, duplicateSelectedProject, editProject, showDeleteModal, getUserRole, removeMeFromProject]);

    return {
        user,
        tableData,
        columns,
        isError,
        isLoading,
        isLoadingDelete,
        projectsCount,
        handleNewProject,
        handleNext,
        handlePrev,
        handlePageClick,
        isDeleteDialogOpen,
        handleDelete,
        handleCancel,
        isRemoveDialogOpen,
        handleRemove,
        handleCancelRemove,
        removeMeFromProjectErrorMsg,
        isRemoveMeFromProjectLoading,
        isRemoveMeFromProjectErrorMessageOpen,
        closeRemoveMeFromProjectErrorMessage,
        ITEMS_PER_PAGE,
    };
}; 
