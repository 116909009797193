import { getIn, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useGetFuelTypesQuery } from "../app/features/dropdownOptions/dropdownOptionsApiSlice"
import { isEqual } from 'lodash';

interface VCEnergyHandlerProps {
  listInputName: string,
  energyTypeInputName: string,
}

const ELECTRICITY_ID = 35

// this hook is for:
// - setting the values for fuel units if electricity is selected
// - setting the values for is_electricity_selected and is_solid_fuel_selected

export const useVCEnergyHandler = <T, L>({ listInputName, energyTypeInputName }: VCEnergyHandlerProps) => {
  const { values, setFieldValue } = useFormikContext<T>()
  const { data: fuelTypes } = useGetFuelTypesQuery({})
  const [list, setList] = useState<L[]>([])
  const [energyId, setEnergyId] = useState<number | null>(null)
  const [solidFuelIds, setSolidFuelIds] = useState<number[]>([])

  useEffect(() => {
    const valuesList: L[] = getIn(values, listInputName)
    const isListUpdated = Array.isArray(valuesList) ? !isEqual(valuesList, list) : false
    if (isListUpdated) { setList([...valuesList]); }
  }, [values, list])

  useEffect(() => {
    if (fuelTypes) {
      setSolidFuelIds(fuelTypes.filter(fuelType => fuelType?.macro_fuel_type?.name_en?.startsWith("Solid")).map(fuelType => fuelType.id))
      setEnergyId(fuelTypes.find(fuelType => fuelType?.macro_fuel_type?.name_en?.startsWith("Electricity"))?.id ?? ELECTRICITY_ID)
    }
  }, [fuelTypes])

  useEffect(() => {
    for (const [index, item] of list.entries()) {
      const energyTypeStart = getIn(item, `${energyTypeInputName}_start`)
      const energyTypeWith = getIn(item, `${energyTypeInputName}_w`)
      const energyTypeWithout = getIn(item, `${energyTypeInputName}_wo`)

      const isElectricitySelected = [energyTypeStart, energyTypeWith, energyTypeWithout].includes(ELECTRICITY_ID)
      const isSolidFuelSelected = [energyTypeStart, energyTypeWith, energyTypeWithout].some(id => solidFuelIds.includes(id))

      const startUnit = fuelTypes?.find(fuelType => fuelType.id === energyTypeStart)?.unit ?? ""
      const withUnit = fuelTypes?.find(fuelType => fuelType.id === energyTypeWith)?.unit ?? ""
      const withoutUnit = fuelTypes?.find(fuelType => fuelType.id === energyTypeWithout)?.unit ?? ""

      setFieldValue(`${listInputName}.${index}.${energyTypeInputName}_start_unit`, startUnit)
      setFieldValue(`${listInputName}.${index}.${energyTypeInputName}_w_unit`, withUnit)
      setFieldValue(`${listInputName}.${index}.${energyTypeInputName}_wo_unit`, withoutUnit)
      setFieldValue(`${listInputName}.${index}.is_electricity_selected`, isElectricitySelected)
      setFieldValue(`${listInputName}.${index}.is_solid_fuel_selected`, isSolidFuelSelected)
    }
  }, [list, energyId, solidFuelIds])
}
