import {
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi'
import { FastProject } from "../../types/interfaces";

const ProjectSelectTable = ({
	data,
	columns,
}: {
	data: FastProject[];
	columns: any[];
}) => {
	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<table className="select-project-table">
			<thead className="select-project-table-header">
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th
								key={header.id}
								onClick={header.column.getToggleSortingHandler()}
								style={{ cursor: 'pointer' }}
								colSpan={header.column.id === "name" || header.column.id === "country" ? 2 : 1}
							>
								{header.isPlaceholder ? null : (
									<div className="d-flex align-items-center pos-relative">
										{flexRender(
											header.column.columnDef.header,
											header.getContext()
										)}
										{{
											asc: <span className="d-flex align-items-center pos-absolute" style={{ top: 0, right: 0 }}><BiUpArrowAlt size={13} color="white" /></span>,
											desc: <span className="d-flex align-items-center pos-absolute" style={{ top: 0, right: 0 }}><BiDownArrowAlt size={13} color="white" /></span>,
										}[header.column.getIsSorted() as string] ?? null}
									</div>
								)}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id} className="pos-relative">
						{row.getVisibleCells().map((cell) => (
							<td
								key={cell.id}
								colSpan={cell.column.id === "name" || cell.column.id === "country" ? 2 : 1}
							>
								<div className="cell-content">
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</div>
							</td>
						))}
					</tr>
				))}
			</tbody>
			{/* <tfoot>
				{table.getFooterGroups().map((footerGroup) => (
					<tr key={footerGroup.id}>
						{footerGroup.headers.map((header) => (
							<th key={header.id} colSpan={header.colSpan}>
								{header.isPlaceholder
									? null
									: flexRender(
										header.column.columnDef.footer,
										header.getContext(),
									)}
							</th>
						))}
					</tr>
				))}
			</tfoot> */}
		</table>
	);
};

export default ProjectSelectTable;
