import { apiSlice } from "../api/apiSlice";
import type { Project, User } from "../../../types/interfaces";
import type {
	ProjectGasResults,
	ProjectTotalResults,
	ProjectPayload,
	ProjectsResponse,
	FastProjectsResponse,
} from "./projectTypes";
import type { Group } from "../permissions/permissionTypes";
import { convertEmptyStringsToNull } from "../../../pages/activityBuilder/modules/inputs/utils";

interface SOCRefProps {
	climate?: number | null;
	moisture?: number | null;
	soil_type?: number | null;
}

interface SOCRefResponse {
	id: number;
	value: number;
	climate: number;
	moisture: number;
	soil_type: number;
}

interface UpdateProjectPayload {
	id: number;
	body: ProjectPayload;
}

interface ProjectUser {
	id: number;
	user: User;
	group: Group;
	project: number;
}

interface GetProjectsPayload {
	url?: string,
	fast?: boolean
}

export interface ProjectAttachment {
	id: number;
	project: number;
	name: string;
	bucket_public_url: string;
	created_at?: string;
	size?: number;
}

interface UploadAttachmentPayload {
	project: number;
	file: File;
}

interface GetProjectResultsPayload {
	id: number;
	activities?: number[];
}

interface DownloadProjectReportPayload {
	id: number;
	activities?: number[];
	template?: string;
}

export const projectApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getProjectById: builder.query<Project, string>({
			query: (id) => `projects/${id}/`,
			providesTags: (results, _error, _data) => [results?.id ? { type: "Projects", id: results?.id } : "Projects"],
		}),
		getProjects: builder.query<FastProjectsResponse | ProjectsResponse, GetProjectsPayload>({
			query: ({ url, fast }) => `${url ? url : "projects/?page_size=30"}${fast ? "&summary=true" : ""}`,
			providesTags: (results, _error, _data) => results?.results.map((project) => ({ type: "Projects", id: project.id })) ?? [],
		}),
		createProject: builder.mutation<Project, ProjectPayload>({
			query: (data) => {
				return {
					url: "projects/",
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["Projects"],
		}),
		updateProject: builder.mutation<Project, UpdateProjectPayload>({
			query: ({ id, body }) => {
				const payload = { ...body, id };
				convertEmptyStringsToNull(payload);
				return {
					url: `projects/${id}/`,
					method: "PATCH",
					body: payload,
				};
			},
			invalidatesTags: (_results, _error, { id }) => [{ type: "Projects", id: id }],
		}),
		deleteProject: builder.mutation<Project, number>({
			query: (id) => ({
				url: `projects/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["Projects"],
		}),
		getProjectResults: builder.query<ProjectTotalResults, GetProjectResultsPayload>({
			query: ({ id, activities }) => activities ? `projects/${id}/results/?activities=${activities?.join(",")}` : `projects/${id}/results/`,
		}),
		getProjectResultsByGas: builder.query<ProjectGasResults, GetProjectResultsPayload>({
			query: ({ id, activities }) => activities ? `projects/${id}/results/?activities=${activities?.join(",")}&aggregate=gas` : `projects/${id}/results/?aggregate=gas`,
		}),
		getSOCref: builder.query<SOCRefResponse | null, void | SOCRefProps>({
			query: (props) => {
				if (!props) return "ipcc/soil-organic-carbons/";
				const { climate, moisture, soil_type } = props;
				const climateStr = climate ? `climate=${climate}&` : "";
				const moistureStr = moisture ? `moisture=${moisture}&` : "";
				const soil_typeStr = soil_type ? `soil_type=${soil_type}` : "";
				return `ipcc/soil-organic-carbons/?${climateStr}${moistureStr}${soil_typeStr}`;
			}, //TODO DA TESTARE
			transformResponse: (response: SOCRefResponse[]) =>
				response?.length ? response[0] : null,
		}),
		createProjectCopy: builder.mutation<Project, number>({
			query: (id) => ({
				url: `projects/${id}/copy/`,
				method: "POST",
			}),
			invalidatesTags: ["Projects"],
		}),
		getProjectMemberships: builder.query<ProjectUser[], number>({
			query: (id) => `projects/${id}/memberships/`,
			// transformResponse: (response: User[]) => response
			providesTags: ["ProjectUsers"],
		}),
		getMyMembership: builder.query<ProjectUser | null, { projectId: number, userId: number }>({
			query: ({ projectId, userId }) => `projects/${projectId}/memberships/?user=${userId}`,
			transformResponse: (response: ProjectUser[], _meta, { userId }) => {
				console.log("response", response, "userId", userId)
				return response.find((member) => member.user.id === userId) ?? null
			},
			providesTags: (result, _error, { projectId, userId }) => result ? [{ type: "ProjectUsers", id: `${projectId}-${userId}` }] : [],
		}),
		deleteMembership: builder.mutation<void, number>({
			query: (id) => ({
				url: `project-memberships/${id}/`,
				method: "DELETE"
			}),
			invalidatesTags: ["ProjectUsers"],
		}),

		getProjectAttachments: builder.query<ProjectAttachment[], number>({
			query: (projectId) => `project-attachments/?project_id=${projectId}`,
			providesTags: (result) =>
				result
					? [...result.map(({ id }) => ({ type: 'ProjectAttachments' as const, id })), { type: 'ProjectAttachments', id: 'LIST' }]
					: [{ type: 'ProjectAttachments', id: 'LIST' }],
		}),

		// Upload a new attachment
		uploadProjectAttachment: builder.mutation<ProjectAttachment, UploadAttachmentPayload>({
			query: ({ project, file }) => {
				const formData = new FormData();
				formData.append('project', project.toString());
				formData.append('file', file);

				return {
					url: 'project-attachments/',
					method: 'POST',
					body: formData,
					formData: true,
				};
			},
			invalidatesTags: [{ type: 'ProjectAttachments', id: 'LIST' }],
		}),
		// Delete an attachment
		deleteProjectAttachment: builder.mutation<void, number>({
			query: (id) => ({
				url: `project-attachments/${id}/`,
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, id) => [
				{ type: 'ProjectAttachments', id },
				{ type: 'ProjectAttachments', id: 'LIST' },
			],
		}),
		downloadProjectAttachment: builder.query<Blob, number>({
			query: (id) => ({
				url: `project-attachments/${id}/download/`,
				method: 'GET',
				responseHandler: response => response.blob(),
			}),
		}),
		downloadProjectReport: builder.query<Blob, DownloadProjectReportPayload>({
			query: ({ id, activities, template }) => {
				const params = new URLSearchParams();

				if (activities) params.append('activities', activities.join(","));
				if (template) params.append('template', template);

				return {
					url: `projects/${id}/report/?${params.toString()}`,
					method: 'GET',
					responseHandler: response => response.blob(),
				}
			},
		}),
	}),
});

export const {
	useGetProjectByIdQuery,
	useGetProjectResultsQuery,
	useGetProjectResultsByGasQuery,
	useGetSOCrefQuery,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useDeleteProjectMutation,
	useCreateProjectCopyMutation,
	useGetProjectMembershipsQuery,
	useDeleteMembershipMutation,
	useGetProjectsQuery,
	useUploadProjectAttachmentMutation,
	useDeleteProjectAttachmentMutation,
	useGetProjectAttachmentsQuery,
	useLazyDownloadProjectAttachmentQuery,
	useLazyGetMyMembershipQuery,
	useLazyDownloadProjectReportQuery,
	endpoints,
} = projectApiSlice;
