import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useState, useMemo } from "react";

import {
	validationSchema,
	getSchemas,
	initialValues,
} from "./energy";
import {
	useAppSelector,
	useThreadsProcessor,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import type { ModuleFull } from "./energyTypes";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetElectricityEntriesQuery,
	useGetEnergiesQuery,
	useGetFuelEntriesQuery,
	useUpdateElectricityEntryMutation,
	useUpdateEnergiesMutation,
	useUpdateFuelEntryMutation,
} from "../../../../app/features/api/modules/energyApiSlice";
import EnergyTierOne from "./EnergyTierOne";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import { BEModules } from "../../../../utils/beModules";
import GenericTierTwo from "../GenericTierTwo";

const Energy = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { data: energiesData, isLoading: isEnergiesLoading, isError: isEnergiesError, error: energiesError } = useGetEnergiesQuery({ activityId: activeActivityId ?? 0 });
	const [updateEnergies, { isError: isUpdateEnergiesError, error: updateEnergiesError }] = useUpdateEnergiesMutation();

	const { data: electricityData, isLoading: isElectricityLoading, isError: isElectricityError, error: electricityError } = useGetElectricityEntriesQuery({ activityId: activeActivityId ?? 0 });
	const { data: fuelData, isLoading: isFuelLoading, isError: isFuelLoadingError, error: fuelError } = useGetFuelEntriesQuery({ activityId: activeActivityId ?? 0 });
	const [updateElectricity, { isError: isElectricityUpdateError, error: updateElectricityError, isLoading: isUpdateElectricityEntryLoading }] = useUpdateElectricityEntryMutation();
	const [updateFuel, { isError: isFuelUpdateError, error: updateFuelError, isLoading: isUpdateFuelEntryLoading }] = useUpdateFuelEntryMutation();

	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>(initialValues);
	const { errorMsg: updateParentErrorMsg, tabsOpen } = useModuleHook({
		skeletonsLoadingDeps: [
			isEnergiesLoading,
			isElectricityLoading,
			isFuelLoading,
		],
		isMutateError: isUpdateEnergiesError,
		mutateError: updateEnergiesError,
	});
	const { errorMsg: fetchError } = useErrorMessage({
		isError: isElectricityError || isFuelLoadingError,
		error: electricityError ?? fuelError,
	});
	const { errorMsg: updateEntryErrorMsg } = useErrorMessage({
		isError: isElectricityUpdateError || isFuelUpdateError,
		error: updateElectricityError ?? updateFuelError,
	});
	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

	useEffect(() => {
		if (!energiesData) return;
		setInitValues((current) => ({ ...current, parent: energiesData }));
	}, [energiesData]);

	useEffect(() => {
		if (!electricityData || !fuelData) return;
		processModuleThreads({
			electricities: electricityData,
			fuels: fuelData,
		});
	}, [electricityData, fuelData, processModuleThreads]);

	const { notes } = useSyncNotesWithStore({
		notes: energiesData?.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		//update notes
		const { parent, electricities, fuels } = values;
		try {
			const promises: Promise<any>[] = [];
			const parentPayload = { body: { ...parent }, activityId: activeActivityId ?? 0 }
			// Update electricities
			electricities.forEach((entry) => {
				const payload = { body: entry, activityId: activeActivityId ?? 0 }
				if (entry.id) promises.push(updateElectricity(payload))
				return Promise.resolve()
			})

			// Update fuels
			fuels.forEach((entry) => {
				const payload = { body: entry.is_solid_fuel_selected ? entry : { ...entry, account_for_co2: false }, activityId: activeActivityId ?? 0 }
				if (entry.id) promises.push(updateFuel(payload))
				return Promise.resolve()
			})

			await Promise.all(promises);
			await updateEnergies(parentPayload)
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<EnergyTierOne
					title="project.energy"
					t2Schema={t2Schema}
					beModuleType={BEModules.Energy}
					moduleSchema={moduleSchema}
					isElectricityLoading={isElectricityLoading}
					isFuelLoading={isFuelLoading}
					footerError={updateParentErrorMsg ?? updateEntryErrorMsg}
					blockingError={fetchError}
					isError={isUpdateEnergiesError}
					setInitValues={setInitValues}
					note={notes?.content ?? null}
				/>
				<GenericTierTwo
					title="project.energy"
					t2Schema={t2Schema}
					tabsOpen={tabsOpen}
				/>
			</>
		</Formik>
	);
};

export default Energy;
