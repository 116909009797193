import { AnimatePresence } from "framer-motion";
import type { ButtonProps } from "../button/Button";
import type { ReactNode } from "react";
import Modal from "./Modal";

const ErrorModal = ({
	isOpen,
	errorMessage,
	handleClose,
	buttons,
}: {
	isOpen: boolean;
	errorMessage: string;
	handleClose?: () => void;
	buttons?: ButtonProps[];
}) => {
	return (
		<AnimatePresence mode="wait" onExitComplete={() => null}>
			{isOpen && (
				<Modal
					headerText="main.error"
					handleClose={handleClose}
					footerButtons={buttons}
					headerColor="var(--error)"
					isHeaderDark
					size="sm"
				>
					<div className="p-2">{errorMessage}</div>
				</Modal>
			)}
		</AnimatePresence>
	);
};

export default ErrorModal;
