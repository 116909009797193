import { Formik, Form } from 'formik';
import { useGetFastActivitiesByProjectQuery } from '../../../../app/features/activities/activityApiSlice';
import { CheckboxesInputGroup, FieldType, TranslationKey } from '../../../../types/modulesInterfaces';
import { useContext, useEffect, useState } from 'react';
import { BiRefresh } from 'react-icons/bi';
import Accordion, { AccordionContext } from '../../../../components/accordion/Accordion';
import GenericInputGroup from '../../../../components/input/inputGroup/GenericInputGroup';
import ModuleButton from '../../../../components/button/ModuleButton';
import TranslatableText from '../../../../components/translations/TranslatableText';
import Button from '../../../../components/button/Button';
import Spinner from '../../../../components/spinner/Spinner';

interface ActivityOption {
  name: TranslationKey;
  id: number;
}

interface FormValues {
  activities: number[];
}

interface ActivitiesCheckboxesProps {
  onRefresh: (selectedActivities: number[]) => void;
  projectId: number;
}

const ActivitiesCheckboxes = ({ onRefresh, projectId }: ActivitiesCheckboxesProps) => {
  const { data: activities, isLoading: activitiesLoading, isError, error } = useGetFastActivitiesByProjectQuery({ projectId, pageSize: 100, page: 1 }, { skip: projectId === 0 });
  // const { errorMsg } = useErrorMessage({ isError, error });
  const errorMsg = "Error loading activities";

  const [activityOptions, setActivityOptions] = useState<ActivityOption[]>([]);
  const [initialValues, setInitialValues] = useState<FormValues>({
    activities: []
  });

  useEffect(() => {
    if (!activities?.results) return;

    const options = activities.results.map((activity) => ({
      name: activity.name as TranslationKey,
      id: activity.id
    }));

    setActivityOptions(options);
    setInitialValues({
      activities: options.map(opt => opt.id)
    });
  }, [activities]);

  const activitiesCheckboxes: CheckboxesInputGroup = {
    type: FieldType.CHECKBOXES,
    inputName: 'activities',
    label: 'activity.activities',
    options: activityOptions
  };

  const handleSubmit = (values: FormValues) => {
    onRefresh(values.activities);
  };

  return (
    <Accordion header={<AccordionHeader />} initiallyOpen={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form className='w-100 pb-2 py-2 d-flex f-column gap-8'>
          {activitiesLoading ?
            <Spinner size={20} isBlack /> :
            isError ?
              <span className='fs-12 text-error mb-1'>{errorMsg}</span> :
              <GenericInputGroup inputGroup={activitiesCheckboxes} />}
          <ModuleButton
            styleType='secondary'
            labelKey='project.refresh_results'
            buttonType='submit'
            icon={BiRefresh}
          />
        </Form>
      </Formik>
    </Accordion>
  );
};

const AccordionHeader = () => {
  const { toggleOpen } = useContext(AccordionContext);

  return (
    <Button
      onClick={() => toggleOpen()}
      classes="filter-activities-header w-100 pos-relative w-fit"
      whileHoverProps={{ backgroundColor: "var(--gray)" }}
    >
      <div className="d-flex align-items-center">
        <h2 className="fs-13">
          <TranslatableText translationKey='project.filter_activities' />
        </h2>
      </div>
    </Button>
  )
}

export default ActivitiesCheckboxes;
