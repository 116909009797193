import * as Yup from "yup";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { BooleanInputGroup, DropdownInputGroupTierTwo, FieldType, InputType, ModuleSchema, StartWithWithoutTierTwo, T2Schema, type OptionalSubmodule, type StartWithWithoutGeneric } from "../../../../types/modulesInterfaces";
import { validatePositiveNumber } from "../moduleUtils";
import { ModuleFull, ProcessingEntryResponse } from "./processingTypes";

const countries = () => getOptions("country")
const efSources = () => getOptions("emissionFactorSources")
const fuelTypes = () => getOptions("fuelTypes", { search: ["stationary", "solid"] })

export const validationSchema = () => {
  return Yup.object({
    processings: Yup.array().of(Yup.object({
      fuel_type_start: validatePositiveNumber.required("validations.energy_type_required"),
      fuel_type_w: validatePositiveNumber.required("validations.energy_type_required"),
      fuel_type_wo: validatePositiveNumber.required("validations.energy_type_required"),
      quantity_consumed_per_year_start: validatePositiveNumber.required("validations.energy_consumption_required"),
      quantity_consumed_per_year_w: validatePositiveNumber.required("validations.energy_consumption_required"),
      quantity_consumed_per_year_wo: validatePositiveNumber.required("validations.energy_consumption_required"),

      is_water_used: Yup.boolean().required(),
      water_use_per_year_start: validatePositiveNumber.when("is_water_used", {
        is: true,
        then: validatePositiveNumber.required("validations.water_consumption_required"),
        otherwise: validatePositiveNumber.notRequired(),
      }),
      water_use_per_year_w: validatePositiveNumber.when("is_water_used", {
        is: true,
        then: validatePositiveNumber.required("validations.water_consumption_required"),
        otherwise: validatePositiveNumber.notRequired(),
      }),
      water_use_per_year_wo: validatePositiveNumber.when("is_water_used", {
        is: true,
        then: validatePositiveNumber.required("validations.water_consumption_required"),
        otherwise: validatePositiveNumber.notRequired(),
      }),
    })),
  })
}

const getOptionalSubmodules: () => OptionalSubmodule<ProcessingEntryResponse>[] = () => {
  const fuelType: StartWithWithoutGeneric = {
    type: FieldType.SWW_SELECT,
    label: "module.type_of_energy_required",
    inputName: (index: number) => `processings.${index}.fuel_type`,
    startProps: { dropdownOptions: fuelTypes },
    withProps: { dropdownOptions: fuelTypes },
    withoutProps: { dropdownOptions: fuelTypes },
  }
  const quantityConsumedPerYear: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: "module.energy_consumption_required",
    inputName: (index: number) => `processings.${index}.quantity_consumed_per_year`,
    startProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_start_unit` },
    withProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_w_unit` },
    withoutProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_wo_unit` },
  }

  const isWaterUsed: BooleanInputGroup = {
    type: FieldType.BOOLEAN,
    label: "module.is_water_used",
    inputName: (index: number) => `processings.${index}.is_water_used`,
  }
  const waterUsedPerYear: StartWithWithoutGeneric = {
    type: FieldType.SWW,
    label: "module.water_consumption",
    unit: "[m³/yr]",
    inputName: (index: number) => `processings.${index}.water_use_per_year`,
  }

  return [
    {
      name: "project.processing",
      inputName: "processings",
      inputGroups: [fuelType, quantityConsumedPerYear],
      repeatable: true,
      linkedTierTwoInputs: ["tiertwo.processings"],
      route: "processings",
      cacheTag: "ProcessingEntriesDefaults",
      conditionalSection: {
        inputGroups: [waterUsedPerYear],
        conditionName: "is_water_used",
        conditionInput: isWaterUsed,
      }
    }
  ]
}

const getProcessingEntryT2Submodule: () => OptionalSubmodule<ProcessingEntryResponse> = () => {
  const efRows: StartWithWithoutTierTwo[] = [
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `processings.${index}.energy_ef_co2_t2`,
      label: "module.ef_of_fuel_used_co2",
      unit: "[tCO2-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_start_unit` },
      withoutProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_wo_unit` },
      withProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_w_unit` },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `processings.${index}.energy_ef_ch4_t2`,
      label: "module.ef_of_fuel_used_ch4",
      unit: "[tCH4-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_start_unit` },
      withoutProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_wo_unit` },
      withProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_w_unit` },
    },
    {
      type: FieldType.SWW_T2,
      inputName: (index: number) => `processings.${index}.energy_ef_n2o_t2`,
      label: "module.ef_of_fuel_used_n2o",
      unit: "[tN2O-e/m3]",
      links: ["Fuel Type"],
      startProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_start_unit` },
      withoutProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_wo_unit` },
      withProps: { inputType: "number", labelSuffix: (index: number) => `processings.${index}.fuel_type_w_unit` },
    },
  ];

  const electricityEf: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    label: "module.ef_selected_country",
    unit: "[tCO2/MWh]",
    links: ["Electricity"],
    startProps: { inputType: "number" },
    withProps: { inputType: "number" },
    withoutProps: { inputType: "number" },
    inputName: (index: number) => `processings.${index}.electricity_ef_t2`,
  }
  const countryOfOrigin: DropdownInputGroupTierTwo = {
    type: FieldType.SELECT_T2,
    label: "module.power_country_of_origin",
    inputName: (index: number) => `processings.${index}.country_t2`,
    dropdownOptions: countries,
    unit: "",
    links: ["Electricity"],
    defaultValue: "",
  }
  const emissionFactorSources: DropdownInputGroupTierTwo = {
    type: FieldType.SELECT_T2,
    label: "module.ef_source_of_electricity",
    inputName: (index: number) => `processings.${index}.ef_source`,
    dropdownOptions: efSources,
    unit: "",
    links: ["Electricity"],
    defaultValue: "",
  }
  const transmissionLoss: StartWithWithoutTierTwo = {
    type: FieldType.SWW_T2,
    inputName: (index: number) => `processings.${index}.transmission_loss_t2`,
    label: "module.transmission_loss",
    unit: "[%]",
    links: ["Electricity"],
    startProps: { inputType: "number", defaultValue: 0 },
    withoutProps: { inputType: "number", defaultValue: 0 },
    withProps: { inputType: "number", defaultValue: 0 },
    inputType: InputType.PERCENTAGE
  }

  return {
    name: "project.processing",
    inputName: "processings",
    inputGroups: efRows,
    route: "processings",
    cacheTag: "ProcessingEntriesDefaults",
    conditionalSection: {
      inputGroups: [electricityEf, countryOfOrigin, emissionFactorSources, transmissionLoss],
      conditionName: "is_electricity_selected"
    }
  }
}

export const getSchemas = () => {
  const moduleSchema: ModuleSchema = {
    initInputGroups: [],
    mandatorySubmodules: [],
    optionalSubmodules: getOptionalSubmodules(),
  };

  const t2EntrySchema = getProcessingEntryT2Submodule();

  const t2Schema: T2Schema = {
    submodules: [],
    optionalSubmodules: [t2EntrySchema],
  };

  return { moduleSchema, t2Schema, t2EntrySchema };
}

export const initialValues: ModuleFull = {
  processings: [],
  parent: {
    id: null,
    note: null,
    activity: null,
    start_year: null,
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    status: null,
  }
}

export const waterUseNullData = {
  water_use_per_year_start: null,
  water_use_per_year_w: null,
  water_use_per_year_wo: null,
}