import {
	InputType,
	type StartWithWithout,
	type StartWithWithoutTierTwo,
} from "../../../types/modulesInterfaces";
import { Field, useFormikContext, getIn } from "formik";
import { useContext, useEffect, useState } from "react";
import { TierTwoIcon } from "../../../utils/customIcons";

import FormRow from "../formRow/FormRow";
import SubModuleInputGroup from "../SubModuleInputGroup";
import TierTwoRowTooltip from "../TierTwoRowTooltip";
import CustomInput from "../CustomInput";
import { useAppSelector, useCustomTranslation, useInputNameHandler } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import ModuleContext from '../../../pages/activityBuilder/ModuleContext';
import TranslatableText from "../../translations/TranslatableText";
import { formatDefaultT2 } from "../../../pages/activityBuilder/modules/moduleUtils";

const TierTwoSWWGroup = ({
	inputName,
	label,
	links,
	startProps,
	withProps,
	withoutProps,
	unit,
	index,
	autosubmit,
	inputType,
}: StartWithWithoutTierTwo) => {
	const { values, errors, touched } = useFormikContext();
	const { belongsToLuc } = useContext(ModuleContext)
	const [rowValues, setRowValues] = useState<StartWithWithout | undefined>(undefined);
	const [defaultValues, setDefaultValues] = useState<StartWithWithout | undefined>(undefined);

	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const [name, setName] = useState<string>("");
	const [error, setError] = useState<string | undefined>(undefined);
	const rowChanged = rowValues?.start || rowValues?.with || rowValues?.without;
	const translatedUnit = useCustomTranslation(unit);

	const { name: labelSuffixInputStartName } = useInputNameHandler({ inputName: startProps?.labelSuffix, index })
	const { name: labelSuffixInputWithName } = useInputNameHandler({ inputName: withProps?.labelSuffix, index })
	const { name: labelSuffixInputWithoutName } = useInputNameHandler({ inputName: withoutProps?.labelSuffix, index })
	const labelSuffixValueStart = getIn(values, labelSuffixInputStartName);
	const labelSuffixValueWith = getIn(values, labelSuffixInputWithName);
	const labelSuffixValueWithout = getIn(values, labelSuffixInputWithoutName);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);
	useEffect(() => {
		const errorPath = [`${name}_start`, `${name}_w`, `${name}_wo`].find(
			(inputName) => {
				const errorMessage = getIn(errors, inputName);
				const wasTouched = getIn(touched, inputName);
				return errorMessage?.length && wasTouched;
			},
		);
		if (errorPath) setError(getIn(errors, errorPath));

		return () => setError(undefined);
	}, [name, errors, touched]);

	useEffect(() => {
		setRowValues({
			start: getIn(values, `${name}_start`),
			with: getIn(values, `${name}_w`),
			without: getIn(values, `${name}_wo`),
		});
	}, [values, name]);

	useEffect(() => {
		const start = getIn(values, `${name}_start_default`);
		const withField = getIn(values, `${name}_w_default`);
		const without = getIn(values, `${name}_wo_default`);
		setDefaultValues({
			start: start?.toFixed(2),
			with: withField?.toFixed(2),
			without: without?.toFixed(2),
		});
	}, [values, name]);

	const validateDefaultValue = (value: any) => {
		if (inputType === InputType.PERCENTAGE && (value > 100 || value < 0)) {
			setError("Percentage must be between 0 and 100")
			return
		}
		return undefined
	}
	return (
		<TierTwoRowTooltip links={links} label={label}>
			<div
				className={`tiertwo-row ${links?.length ? "tiertwo-row-hover" : ""
					}`}
			>
				<TierTwoIcon fill={links?.length ? "active" : undefined} />
				<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
					{rowChanged ? <div className="has-changed-dot" /> : null}
					<TranslatableText className="fs-12" translationKey={label} />
					<span className="fs-10 op-5">
						{translatedUnit}
					</span>
				</div>
				<SubModuleInputGroup
					error={error}
					name=""
					gridTemplateColumns="1fr 1fr 1fr"
					gap="8px"
				>
					<FormRow
						style={{ gridColumn: "1" }}
						topLabel={`Default = ${formatDefaultT2(defaultValues?.start, inputType)}`}
						isFetching={isFetchingData}
					>
						<Field
							name={`${name}_start`}
							id={`${name}_start`}
							component={CustomInput}
							label="module.start"
							disabled={startProps.disabled || belongsToLuc?.is_start === false}
							type="number"
							textend
							classes={rowValues?.start ? "tiertwo-input-changed" : ""}
							autosubmit={autosubmit}
							validate={validateDefaultValue}
							labelSuffixValue={labelSuffixValueStart ?? null}
						/>
					</FormRow>
					<FormRow
						style={{ gridColumn: "2" }}
						topLabel={`Default = ${formatDefaultT2(defaultValues?.with, inputType)}`}
						isFetching={isFetchingData}
					>
						<Field
							name={`${name}_w`}
							id={`${name}_w`}
							component={CustomInput}
							label="module.with"
							disabled={withProps.disabled || belongsToLuc?.is_w === false}
							type="number"
							textend
							classes={rowValues?.with ? "tiertwo-input-changed" : ""}
							autosubmit={autosubmit}
							validate={validateDefaultValue}
							labelSuffixValue={labelSuffixValueWith ?? null}
						/>
					</FormRow>
					<FormRow
						style={{ gridColumn: "3" }}
						topLabel={`Default = ${formatDefaultT2(defaultValues?.without, inputType)}`}
						isFetching={isFetchingData}
					>
						<Field
							name={`${name}_wo`}
							id={`${name}_wo`}
							component={CustomInput}
							label="module.without"
							disabled={withoutProps.disabled || belongsToLuc?.is_wo === false}
							type="number"
							textend
							classes={rowValues?.without ? "tiertwo-input-changed" : ""}
							autosubmit={autosubmit}
							validate={validateDefaultValue}
							labelSuffixValue={labelSuffixValueWithout ?? null}
						/>
					</FormRow>
				</SubModuleInputGroup>
			</div>
		</TierTwoRowTooltip>
	);
};

export default TierTwoSWWGroup;
