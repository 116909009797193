import { AnimatePresence, motion } from "framer-motion";
import { BiTrash } from "react-icons/bi";
import Button from "../../../button/Button";
import { readableFileSize } from "../../../../utils/helperFunctions";

interface FileListProps {
  files: File[];
  onDelete: (file: File) => void;
}

const FileList = ({ files, onDelete }: FileListProps) => {
  return (
    <ul className="file-list">
      <AnimatePresence>
        {files.map((file, i) => {
          const fileSize = file.size ? readableFileSize(file.size) : null;
          return (
            <motion.li
              key={`tobeuploaded-${file.name}-${i}`}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
              className="d-flex justify-content-between align-items-center"
            >
              <span>{file.name} - {fileSize}</span>
              <Button classes="text-error" onClick={() => onDelete(file)}>
                <BiTrash size={18} />
              </Button>
            </motion.li>
          );
        })}
      </AnimatePresence>
    </ul>
  );
};

export default FileList; 