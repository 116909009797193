import React, { useCallback } from 'react'
import { useLazyDownloadProjectReportQuery } from '../../../../app/features/project/projectApiSlice';
import { extractErrorMessage } from '../../../../utils/useErrorMessage';
import { isFetchBaseQueryError } from '../../../../utils/helperFunctions';

const useDownloadExcel = ({ projectId, activities }: { projectId: number, activities?: number[] }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [triggerDownload] = useLazyDownloadProjectReportQuery();

  const handleCancel = () => {
    setIsOpen(false)
  }

  // Common download handler function
  const handleDownload = useCallback(async (options: {
    template?: string;
    fileName: string;
  }) => {
    const { template, fileName } = options;
    
    if (!projectId) return;
    setIsLoading(true);
    
    try {
      const response = await triggerDownload({ 
        id: projectId, 
        activities, 
        ...(template && { template })
      });

      if ('error' in response) {
        if (isFetchBaseQueryError(response.error) && response.error.data instanceof Blob) {
          const errorText = await response.error.data.text();
          const errorJson = JSON.parse(errorText);
          setErrorMsg(errorJson.details || "An error occurred while downloading the report");
        } else {
          const errMsg = extractErrorMessage(response.error);
          setErrorMsg(errMsg || "An error occurred while downloading the report");
        }
        setIsOpen(true);
        return;
      }

      const { data: blob } = response;
      if (!blob) {
        setErrorMsg("An error occurred while downloading the report");
        setIsOpen(true);
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the report:", error);
      setErrorMsg("An unexpected error occurred while downloading the report");
      setIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  }, [projectId, activities, triggerDownload]);

  const downloadExcel = useCallback(() => {
    return handleDownload({
      fileName: `${projectId}-results-report.xlsx`
    });
  }, [handleDownload, projectId]);

  const downloadNarrativeReport = useCallback(() => {
    return handleDownload({
      template: "fao",
      fileName: `${projectId}-narrative-report.pdf`
    });
  }, [handleDownload, projectId]);

  return {
    isLoading,
    downloadExcel,
    downloadNarrativeReport,
    handleCancel,
    isOpen,
    errorMsg
  }
}

export default useDownloadExcel
