import { useEffect, useState } from 'react';
import { useDeleteMembershipMutation, useLazyGetMyMembershipQuery } from '../../../app/features/project/projectApiSlice';
import type { FastProject } from '../../../types/interfaces';
import useErrorMessage from '../../../utils/useErrorMessage';

const useRemoveMeFromProject = (userId: number, refetch?: () => void) => {
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [projectToRemoveFrom, setProjectToRemoveFrom] = useState<FastProject | null>(null);

  const [getMyMembership] = useLazyGetMyMembershipQuery();
  const [deleteMembership, { isLoading: isLoadingRemove, isError: isErrorRemove, error: removeError }] = useDeleteMembershipMutation();

  const { errorMsg } = useErrorMessage({ isError: isErrorRemove, error: removeError });

  useEffect(() => {
    if (errorMsg)
      setIsErrorMessageOpen(true);
  }, [errorMsg]);

  console.log("isErrorRemove", isErrorRemove, "errorMsg", errorMsg)

  const handleRemove = async () => {
    if (isLoadingRemove || !projectToRemoveFrom?.id) return;

    try {
      const membership = await getMyMembership({
        projectId: projectToRemoveFrom.id,
        userId
      }).unwrap();

      if (!membership?.id) {
        console.warn('No membership found for current user');
        return;
      }

      await deleteMembership(membership.id).unwrap();
      if (refetch) refetch();
    } catch (error) {
      console.error('Failed to remove user from project:', error);
    } finally {
      setRemoveDialogOpen(false);
    }
  };

  const showRemoveModal = (project: FastProject) => {
    setRemoveDialogOpen(true);
    setProjectToRemoveFrom(project);
  };

  const handleCancel = () => {
    setRemoveDialogOpen(false);
  };

  return {
    isRemoveDialogOpen,
    handleCancel,
    handleRemove,
    showRemoveModal,
    isLoadingRemove,
    removeMeFromProjectErrorMsg: errorMsg,
    isRemoveMeFromProjectLoading: isLoadingRemove,
    isRemoveMeFromProjectErrorMessageOpen: isErrorMessageOpen,
    closeRemoveMeFromProjectErrorMessage: () => setIsErrorMessageOpen(false)
  };
};

export default useRemoveMeFromProject; 