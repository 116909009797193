import { useMemo } from "react";
import { useGetProjectResultsQuery } from "../../../../app/features/project/projectApiSlice";
import { calculateProjectCO2Balance } from "../hooks/resultsUtils";
import Skeleton from "react-loading-skeleton";
import ResultSlider from "../../../../components/resultSlider/ResultSlider";
import TranslatableText from "../../../../components/translations/TranslatableText";

interface TotalCO2GeneratedProps {
    projectId: number;
    selectedActivities?: number[];
}

const TotalCO2Generated = ({ projectId, selectedActivities }: TotalCO2GeneratedProps) => {
    const { data, isFetching } = useGetProjectResultsQuery(
        { id: projectId, activities: selectedActivities },
        {
            refetchOnMountOrArgChange: true,
            selectFromResult: (result) => ({
                ...result,
                data: calculateProjectCO2Balance(result.data),
            }),
        }
    );

    const sliders = useMemo(() => {
        return [
            {
                name: "Balance",
                value: data?.balance ?? 0,
            },
            {
                name: "With",
                value: data?.total_w ?? 0,
            },
            {
                name: "Without",
                value: data?.total_wo ?? 0,
            },
        ];
    }, [data]);

    return (
        <>
            <div
                className="mb-2 w-fit"
                style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
            >
                <h2 className="ff-bold fs-13 text-black">
                    <TranslatableText translationKey="main.total_co2_eq_generated" />
                </h2>
            </div>
            <div className="co2-blocks-grid">
                {isFetching
                    ? sliders.map((slider) => (
                        <Skeleton
                            height={80}
                            borderRadius={8}
                            key={slider.name}
                            style={{
                                gridColumn: slider.name === "Balance" ? "span 2" : "span 1",
                            }}
                        />
                    ))
                    : sliders.map((slider) => (
                        <div
                            className="d-flex f-column"
                            key={slider.name}
                            style={{
                                gridColumn: slider.name === "Balance" ? "span 2" : "span 1",
                            }}
                        >
                            <span className="text-black fs-13">{slider.name}</span>
                            <ResultSlider value={slider.value} />
                        </div>
                    ))}
            </div>
        </>
    );
};

export default TotalCO2Generated; 