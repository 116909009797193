import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { useEffect, useState } from "react";
import { DropdownInputGroup, FieldType, Option } from "../../../../types/modulesInterfaces";
import { Form, Formik } from "formik";
import { useGetProjectsQuery } from "../../../../app/features/project/projectApiSlice";
import { FastProject } from "../../../../types/interfaces";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import ProjectResults from "./ProjectResults";
import Button from "../../../../components/button/Button";
import TranslatableText from "../../../../components/translations/TranslatableText";

const initValues = {
  projectId: "",
}

const ProjectCompare = () => {
  const { tabsOpen } = useAppSelector(selectCurrentBuilder);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [projectOptions, setProjectOptions] = useState<Option[]>([]);
  const { data: projectsResponse } = useGetProjectsQuery({ fast: true });

  useEffect(() => {
    const fastProjects = projectsResponse?.results as FastProject[];
    if (fastProjects)
      setProjectOptions(fastProjects.map((project) => ({ name: project.name, id: project.id } as Option)));
  }, [projectsResponse]);

  const projectSelectDropdownInput: DropdownInputGroup = {
    type: FieldType.SELECT,
    inputName: "projectId",
    label: "project.select_project_to_compare",
    dropdownOptions: projectOptions,
  };

  const handleSubmit = (values: typeof initValues) => {
    console.log(values);
    setSelectedProjectId(values.projectId);
  };

  return !tabsOpen ? (
    <section className="d-flex f-column gap-8">
      <h2 className="fs-14 ff-medium-ext w-fit pt-2">
        <TranslatableText translationKey="project.project_comparison" />
      </h2>

      <SelectProjectToCompareForm projectSelectDropdownInput={projectSelectDropdownInput} handleSubmit={handleSubmit} />
      {selectedProjectId && <ProjectResults projectId={selectedProjectId} containerStyle={{ marginLeft: "60px" }} />}
    </section>
  ) : null;
};

const SelectProjectToCompareForm = ({ projectSelectDropdownInput, handleSubmit }: { projectSelectDropdownInput: DropdownInputGroup, handleSubmit: (values: typeof initValues) => void }) => {
  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
    >
      <Form className="w-50">
        <GenericInputGroup inputGroup={projectSelectDropdownInput} />
        <Button
          type="submit"
          classes="mt-2 compare-button"
          whileHoverProps={{ backgroundColor: "var(--primary-deep)" }}
        >
          Compare
        </Button>
      </Form>
    </Formik>
  );
};


export default ProjectCompare;