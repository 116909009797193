import { useGetFastActivitiesByProjectQuery } from "../../../app/features/activities/activityApiSlice";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDeleteActivity } from "./useDeleteActivity";
import { usePaginate } from "./usePaginate";
import { getActivitiesPageUrl } from "./utils";
import { usePermissions } from "../../../app/hooks";
import ActivityCard, { CreateNewActivityCard } from "./ActivityCard";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import Pagination from "../../../components/pagination/Pagination";
import TranslatableText from "../../../components/translations/TranslatableText";
import Skeleton from "react-loading-skeleton";
import Masonry from "react-responsive-masonry";
import useErrorMessage from "../../../utils/useErrorMessage";
import FadeAnimation from "../../../components/animations/FadeAnimation";

const ITEMS_PER_PAGE = 10;

const ProjectActivitiesContainer = () => {
	const { projectId } = useParams<string>();
	const location = useLocation();
	const isProjecIdValid = useMemo<boolean>(
		() => projectId !== undefined && projectId !== "new",
		[projectId],
	);
	const { isReadOnly } = usePermissions();
	const [searchParams, setSearchParams] = useState<any>({url: null, projectId: null});

	useEffect(() => {
		setSearchParams({
			url: `/activities/?page=1&project_id=${projectId}&page_size=${ITEMS_PER_PAGE}`,
			projectId
		});
	}, [projectId])

	const {
		data: activitiesRes,
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = useGetFastActivitiesByProjectQuery(searchParams, {
		skip: !isProjecIdValid || !searchParams.url,
		refetchOnMountOrArgChange: true
	});
	const {
		results: activities,
		count: activitiesCount,
		next,
		previous,
	} = activitiesRes || {};

	const nextUrl = useMemo(() => getActivitiesPageUrl(next, projectId, ITEMS_PER_PAGE), [next, projectId])
	const previousUrl = useMemo(() => getActivitiesPageUrl(previous, projectId, ITEMS_PER_PAGE), [previous, projectId])

	const { handleNext, handlePrev, handlePageClick } = usePaginate({
		next: nextUrl,
		previous: previousUrl,
		dynamicNext: (page: number) => `/activities/?page=${page}&page_size=${ITEMS_PER_PAGE}&project_id=${projectId}`,
		setUrl: (newUrl) => {
			if (newUrl) {
			setSearchParams({
			url: newUrl,
			projectId
			});
		}
		}
	})
	const {
		isDeleteDialogOpen,
		handleCancel,
		handleDelete,
		showDeleteModal,
		isDeleteLoading,
		deleteErrorMsg,
		isDeleteError,
	} = useDeleteActivity(refetch);

	const { errorMsg } = useErrorMessage({ error, isError });

	return isProjecIdValid ? (
		<section style={{ paddingBottom: 20 }}>
			<ConfirmationModal
				open={isDeleteDialogOpen}
				onCancel={handleCancel}
				onConfirm={handleDelete}
				message={<TranslatableText translationKey="activity.delete_confirmation" />}
			/>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey="project.project_activities" />
					</h2>
					<div className="module-number header-number ff-light-ext">
						{projectId}
					</div>
				</div>
			</div>

			<div className="activity-cards-container">
				{isError ? (
					<>
						<span className="error fs-13 pb-2">
							{errorMsg
								? errorMsg
								: `An error occurred while fetching activities for project ${projectId}`}
						</span>
					</>
				) : isDeleteError ? (
					<>
						<span className="error fs-13 pb-2">
							{deleteErrorMsg
								? deleteErrorMsg
								: "An error occurred while deleting an activity"}
						</span>
					</>
				) : (
					<Masonry columnsCount={2} gutter="1rem">
						<FadeAnimation isShown>
							<CreateNewActivityCard />
						</FadeAnimation>
						{isLoading || isFetching
							? Array(activities?.length ?? 3)
								.fill(0)
								.map((_, i) => (
									<FadeAnimation key={`cart-skeleton-${i}`} delay={i * 0.1} isShown>
										<Skeleton height={108} borderRadius={8} />
									</FadeAnimation>
								))
							: isDeleteLoading
								? activities?.map((act, i) => (
									<FadeAnimation key={act.id} delay={i * 0.1} isShown>
										<Skeleton height={108} borderRadius={8} />
									</FadeAnimation>
								))
								: activities?.length
									? activities.map((act, i) => (
										<FadeAnimation
											className="card-container"
											key={act.id}
											delay={i * 0.1} // 0.1s delay between each card
											isShown
										>
											<ActivityCard
												activity={act}
												onDelete={() => showDeleteModal(act.id)}
												canEdit={!isReadOnly}
											/>
										</FadeAnimation>
									))
									: null}
					</Masonry>
				)}
			</div>

			{activities?.length === 0 ? (
				<footer className="module-group-footer">
					<div className="module-group">
						<span className="ff-light fs-12 pb-2">
							* No activities have been added yet. Please, add activities to the
							project to start the assessment.
						</span>
					</div>
				</footer>
			) : null}
			{
				activities && activitiesCount && activitiesCount > ITEMS_PER_PAGE ? (
					<Pagination itemsPerPage={ITEMS_PER_PAGE} totalItems={activitiesCount ?? 0} onNext={handleNext} onPrevious={handlePrev} onPageClick={handlePageClick} size="md" />
				) : null
			}
		</section>
	) : (
		<Navigate
			to={"/project/new/description"}
			state={{ from: location }}
			replace
		/>
	);
};

export default ProjectActivitiesContainer;
