import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import Button from '../button/Button';
import usePages from './usePages';

const baseButtonStyle: React.CSSProperties = {
  cursor: 'pointer',
  color: 'white',
  alignContent: 'center',
  alignSelf: 'center',
  borderRadius: '50%'
}

const MdPageButtonStyle: React.CSSProperties = {
  fontSize: '15px',
  margin: '0 5px',
  padding: '11px 15px',
  ...baseButtonStyle
}

const SmPageButtonStyle: React.CSSProperties = {
  fontSize: '12px',
  margin: '0 5px',
  padding: '7px 10px',
  ...baseButtonStyle
}
interface PaginationProps {
  totalItems: number;
  itemsPerPage?: number;
  onPageChange?: (page: number) => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onPageClick?: (page: number) => void;
  size: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}
const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage = 10,
  onPageChange,
  onNext,
  onPrevious,
  onPageClick,
  size,
  style,
}) => {

  const { handlePrevious, handleNext, handlePageClick, currentPage, totalPages } = usePages({
    totalItems,
    itemsPerPage,
    onPageChange,
    onNext,
    onPrevious,
    onPageClick
  })

  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= 3) {
      // If 3 or fewer pages, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always show first page
      pages.push(1);

      if (currentPage <= 2) {
        // Near the start
        pages.push(2, '...', totalPages);
      } else if (currentPage >= totalPages - 1) {
        // Near the end
        pages.push('...', totalPages - 1, totalPages);
      } else {
        // Middle - show current page and neighbors
        pages.push('...', currentPage, '...', totalPages);
      }
    }

    return pages;
  };

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.25rem',
      }}
    >
      <Button onClick={handlePrevious} disabled={currentPage === 1}>
        <GrPrevious size={20} />
      </Button>

      {getPageNumbers().map((page, index) => (
        page === '...' ? (
          <span
            key={`ellipsis-${index}`}
            style={{
              color: 'var(--darker-gray)',
              // margin: '0 5px',
            }}
          >
            ...
          </span>
        ) : (
          <Button
            key={page}
            onClick={() => handlePageClick(page as number)}
            testId={`pagination-${page}`}
            style={{
              ...(size === 'sm' ? SmPageButtonStyle : MdPageButtonStyle),
              backgroundColor: currentPage === page ? 'var(--primary-dark)' : 'var(--gray)',
              height: '40px',
              width: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {page}
          </Button>
        )
      ))}

      <Button onClick={handleNext} disabled={currentPage === totalPages} testId="next-button-pagination">
        <GrNext size={20} />
      </Button>
    </div>
  )
};

export default Pagination;
