import { useMemo, useEffect, useState } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, useSyncNotesWithStore, useThreadsProcessor } from "../../../../app/hooks";
import { Formik, FormikHelpers } from "formik";
import { BEModules } from "../../../../utils/beModules";
import { ModuleFull } from "./packagingTypes";
import { useGetPackagingsQuery, useUpdatePackagingsMutation, useUpdatePackagingEntryMutation, useGetPackagingEntriesQuery } from "../../../../app/features/api/modules/packagingApiSlice";
import { getSchemas, validationSchema, initialValues, energyNullData } from "./packaging";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import GenericOptionalTierTwo from "../GenericOptionalTierTwo";
import PackagingTierOne from "./PackagingTierOne";
import PackagingEntryT2 from "./PackagingEntryT2";

const PackagingModule = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);

  const { data: packagingsData, isLoading, isError, error } = useGetPackagingsQuery({ activityId: activeActivityId ?? 0 });
  const { data: entriesData, isLoading: isEntriesLoading, isError: isEntriesError, error: entriesError } = useGetPackagingEntriesQuery({ activityId: activeActivityId ?? 0 });
  const [updatePackagings, { isError: isUpdateError, error: updateError }] = useUpdatePackagingsMutation();
  const [updatePackagingEntry, { isError: isUpdatePackagingEntryError, error: updatePackagingEntryError, isLoading: isUpdatePackagingEntryLoading }] = useUpdatePackagingEntryMutation();


  const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
  const [initValues, setInitValues] = useState<ModuleFull>(initialValues);
  const { errorMsg: updateParentErrorMsg } = useModuleHook({
    skeletonsLoadingDeps: [isLoading, isEntriesLoading],
    isMutateError: isUpdateError,
    mutateError: updateError,
  });

  const { errorMsg: fetchError } = useErrorMessage({ isError: isEntriesError ?? isError, error: entriesError ?? error });
  const { errorMsg: updateEntryErrorMsg } = useErrorMessage({ isError: isUpdatePackagingEntryError, error: updatePackagingEntryError });
  const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

  useEffect(() => {
    if (!packagingsData) return
    setInitValues((cur) => ({ ...cur, parent: packagingsData }))
  }, [packagingsData])

  useEffect(() => {
    if (entriesData) processModuleThreads({ packagings: entriesData })
  }, [processModuleThreads, entriesData])


  const { notes } = useSyncNotesWithStore({
    notes: packagingsData?.note ?? null,
  })

  const handleSubmit = async (
    values: ModuleFull,
    { resetForm }: FormikHelpers<ModuleFull>
  ) => {
    const { packagings, parent } = values
    try {
      const promises = []
      const parentPayload = { body: { ...parent }, activityId: activeActivityId ?? 0 }
      if (parent.id) promises.push(updatePackagings(parentPayload))

      packagings.map((entry) => {
        const payload = { body: entry.is_electric ? { ...entry } : { ...entry, ...energyNullData }, activityId: activeActivityId ?? 0 }
        if (entry.id) promises.push(updatePackagingEntry(payload))
        return Promise.resolve()
      })

      await Promise.all(promises);
      resetForm({ values })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <>
        <PackagingTierOne
          title="project.packaging"
          initValues={initValues}
          t2Schema={t2Schema}
          beModuleType={BEModules.Packaging}
          moduleSchema={moduleSchema}
          isLoading={isLoading || isEntriesLoading || isUpdatePackagingEntryLoading}
          isEntriesLoading={isEntriesLoading}
          footerError={updateParentErrorMsg ?? updateEntryErrorMsg}
          blockingError={fetchError}
          note={notes?.content ?? null}
          setInitValues={setInitValues}
        />
        <GenericOptionalTierTwo
          titleKey="project.packaging"
          listInputName="packagings"
          EntryComponent={PackagingEntryT2}
        />
      </>
    </Formik>
  );
}

export default PackagingModule;