import { useMemo, useEffect, useState } from "react";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useAppSelector, useSyncNotesWithStore, useThreadsProcessor } from "../../../../app/hooks";
import { Formik, FormikHelpers } from "formik";
import { BEModules } from "../../../../utils/beModules";
import { ModuleFull } from "./inputsTypes";
import { useGetInputsQuery, useUpdateInputsMutation, useGetInputEntriesQuery, useUpdateInputEntryMutation } from "../../../../app/features/api/modules/inputsApiSlice";
import { getSchemas, validationSchema, initialValues } from "./inputs";
import { useGetInputTypesQuery } from "../../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import GenericOptionalTierTwo from "../GenericOptionalTierTwo";
import InputsTierOne from "./InputsTierOne";
import InputEntryT2 from "./InputEntryT2";


const Inputs = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);

	const { data: inputsData, isLoading, isError, error } = useGetInputsQuery({ activityId: activeActivityId ?? 0 });
	const { data: entriesData, isLoading: isEntriesLoading, isError: isEntriesError, error: entriesError } = useGetInputEntriesQuery({ activityId: activeActivityId ?? 0 });
	const { data: inputTypes } = useGetInputTypesQuery();
	const [updateInputs, { isError: isUpdateError, error: updateError, }] = useUpdateInputsMutation();
	const [updateInputEntry, { isError: isUpdateInputEntryError, error: updateInputEntryError, isLoading: isUpdateInputEntryLoading }] = useUpdateInputEntryMutation();

	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>(initialValues);

	const { errorMsg: updateParentErrorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isEntriesLoading, isUpdateInputEntryLoading],
		isMutateError: isUpdateError,
		mutateError: updateError,
	})

	const { errorMsg: fetchError } = useErrorMessage({ isError: isEntriesError ?? isError, error: entriesError ?? error });
	const { errorMsg: updateEntryErrorMsg } = useErrorMessage({ isError: isUpdateInputEntryError, error: updateInputEntryError });
	const { processModuleThreads } = useThreadsProcessor<ModuleFull>();

	useEffect(() => {
		if (!inputsData) return
		setInitValues((cur) => ({ ...cur, parent: inputsData }))
	}, [inputsData])

	useEffect(() => {
		if (entriesData)
			processModuleThreads({ entries: entriesData })
	}, [processModuleThreads, entriesData])

	const { notes } = useSyncNotesWithStore({
		notes: inputsData?.note ?? null,
	})

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>
	) => {
		const { entries, parent } = values
		try {
			const promises = []
			const parentPayload = { body: { ...parent }, activityId: activeActivityId ?? 0 }
			if (parent.id) promises.push(updateInputs(parentPayload))

			entries.forEach((entry) => {
				const entryType = inputTypes?.find((type) => type.id === entry.input_type)
				//so that if a user changes input type and the new input type doesn't have some emissions, the t2 value that was set is reset
				const curatedEntry = {
					...entry,
					co2_emissions_t2: entryType?.has_co2_emissions ? entry.co2_emissions_t2 : null,
					n2o_emissions_t2: entryType?.has_n2o_emissions ? entry.n2o_emissions_t2 : null,
					co2_e_emissions_t2: entryType?.has_co2_e_emissions ? entry.co2_e_emissions_t2 : null
				}
				const payload = { body: curatedEntry, activityId: activeActivityId ?? 0 }
				if (entry.id) promises.push(updateInputEntry(payload))
			})

			await Promise.all(promises)
			resetForm({ values })
		} catch (error) {
			console.error(error)
		}
	}


	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<InputsTierOne
					title="project.inputs"
					initValues={initValues}
					t2Schema={t2Schema}
					beModuleType={BEModules.Inputs}
					moduleSchema={moduleSchema}
					isLoading={isLoading || isEntriesLoading || isUpdateInputEntryLoading}
					isEntriesLoading={isEntriesLoading}
					footerError={updateParentErrorMsg ?? updateEntryErrorMsg}
					blockingError={fetchError}
					note={notes?.content ?? null}
					setInitValues={setInitValues}
				/>
				<GenericOptionalTierTwo
					titleKey="project.inputs"
					listInputName="entries"
					EntryComponent={InputEntryT2}
				/>
			</>
		</Formik>
	);
};

export default Inputs;
