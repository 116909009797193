import { Navigate, useLocation, useParams } from "react-router-dom";
import ProjectCompare from "./components/ProjectCompare";
import ProjectResults from "./components/ProjectResults";

const ProjectResultsContainer = () => {
	const { projectId } = useParams<string>();
	const location = useLocation();

	const isProjectIdValid = projectId !== undefined && projectId !== "new";
	if (!isProjectIdValid) {
		return <Navigate to="/project/new/description" state={{ from: location }} replace />;
	}

	return (
		<>
			<ProjectResults projectId={projectId} containerStyle={{ marginLeft: "90px" }} />
			<ProjectCompare />
		</>
	);
};

export default ProjectResultsContainer;
