import { useMemo } from "react";
import { useGetProjectResultsByGasQuery } from "../../../../app/features/project/projectApiSlice";
import { aggregateTotalProjectEmissions } from "../hooks/resultsUtils";
import { translate } from "../../../../utils/helperFunctions";
import { ResultSliderItem } from "../../../../types/modulesInterfaces";
import i18n from "../../../../i18n";
import Skeleton from "react-loading-skeleton";
import ResultSlider from "../../../../components/resultSlider/ResultSlider";
import TranslatableText from "../../../../components/translations/TranslatableText";

interface CarbonBalanceProps {
    projectId: number;
    selectedActivities?: number[];
}

const CarbonBalance = ({ projectId, selectedActivities }: CarbonBalanceProps) => {
    const { data: customRes, isFetching } = useGetProjectResultsByGasQuery(
        { id: projectId, activities: selectedActivities },
        {
            refetchOnMountOrArgChange: true,
            selectFromResult: (result) => ({
                ...result,
                data: aggregateTotalProjectEmissions(result.data),
            }),
        }
    );

    const { language } = i18n;
    const sliders = useMemo((): ResultSliderItem[] => {
        return [
            { name: `CO<sub>2</sub> ${translate("module.fluxes")}`, value: customRes?.CO2 ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
            { name: `CH<sub>4</sub> ${translate("module.fluxes")}`, value: customRes?.CH4 ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
            { name: `N<sub>2</sub>O ${translate("module.fluxes")}`, value: customRes?.N2O ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
            { name: `${translate("module.OTHER_GHG_fluxes")}`, value: customRes?.OTHER ?? 0, unit: "module.unit_tonnes_of_CO2_equivalent" },
        ];
    }, [customRes, language]);

    return (
        <>
            <div
                className="mt-2 mb-3 w-fit"
                style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
            >
                <h2 className="ff-bold fs-13 text-black">
                    <TranslatableText translationKey="main.carbon_balance" />
                </h2>
            </div>
            <div className="co2-blocks-grid pb-4">
                {isFetching
                    ? sliders.map((slider) => (
                        <Skeleton height={80} borderRadius={8} key={slider.name} />
                    ))
                    : sliders.map((slider) => (
                        <div
                            className="d-flex f-column text-black mb-2"
                            key={slider.name}
                        >
                            <span className="text-black fs-13 ff-bold lh-90" dangerouslySetInnerHTML={{ __html: slider.name }} />
                            <TranslatableText className="text-black fs-12 ff-light" translationKey={slider.unit} />
                            <ResultSlider value={slider.value} />
                        </div>
                    ))}
            </div>
        </>
    );
};

export default CarbonBalance; 