import type { BelongsToLuc, FieldType, OptionalSubmodule, TranslationKey } from "../../../types/modulesInterfaces";
import { TranslatedAddAccordionButton } from "../../../components/input/AddAccordionButton";
import { TranslatedFormSubmodule } from "../../../components/formSubmodule/TranslatedFormSubmodule";
import { handleDisableSww } from "./moduleUtils";
import { FieldArray, getIn } from "formik";
import GenericInputGroup from "../../../components/input/inputGroup/GenericInputGroup";
import Spinner from "../../../components/spinner/Spinner";
import TranslatableText from "../../../components/translations/TranslatableText";
import FadeAnimation from "../../../components/animations/FadeAnimation";

interface OptionalProps<T extends { id: number }> {
  isOptionalLoading?: boolean;
  optionalSchema: OptionalSubmodule<T>;
  isCreateLoading?: boolean;
  isDeleteLoading?: boolean;
  createHandler: () => void;
  removeHandler: (remove: <T>(index: number) => T | undefined, index: number, id?: number | null) => void
  values: T[] | null;
  addLabel: TranslationKey;
  belongsToLuc?: BelongsToLuc
  isSWW?: (inputType: FieldType) => boolean
  fieldArrayName: string
  isReadOnly: boolean
}

const GenericOptionalSubModule = <T extends { id: number },>({ isOptionalLoading, optionalSchema, isDeleteLoading: isLoading, values, removeHandler, createHandler, addLabel, belongsToLuc, isSWW, fieldArrayName, isReadOnly, isCreateLoading }: OptionalProps<T>) => {
  const addDisabled = isReadOnly || !!(optionalSchema.max && values?.length && values?.length >= optionalSchema.max);
  return (
    <div>
      {
        isOptionalLoading ?
          <div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
            <TranslatableText className="text-white fs-12" translationKey="main.loading" />
            <Spinner size={36} padding={8} />
          </div>
          : <FieldArray name={fieldArrayName}>
            {({ remove }) => (
              <>
                {values && values.length > 0
                  ? values.map((value, index) => {
                    const showConditionalSection = optionalSchema.conditionalSection && optionalSchema.conditionalSection.conditionName ? getIn(value, optionalSchema.conditionalSection.conditionName) : null
                    return (
                      <FadeAnimation isShown delay={index * 0.1} key={`${value.id}-${index}`}>
                        <TranslatedFormSubmodule
                          submoduleName={optionalSchema.name}
                          removeHandler={() => removeHandler(remove, index, value.id)}
                          removeLoading={isLoading}
                          itemId={value.id}
                          itemIndex={index + 1}
                        >
                          {
                            optionalSchema?.inputGroups.map((input, i) => {
                              const curatedInput = handleDisableSww({ input, belongsToLuc, index, isReadOnly });
                              return (
                                <div className="py-1" key={`${input.label}-${i}`}>
                                  <GenericInputGroup inputGroup={{ ...curatedInput }} />
                                </div>
                              )
                            })
                          }

                          {optionalSchema.conditionalSection ?
                            <>
                              {optionalSchema.conditionalSection.conditionInput &&
                                <div className="py-2" key={`${optionalSchema.conditionalSection.conditionInput.label}-${index}`}>
                                  <GenericInputGroup inputGroup={{ ...optionalSchema.conditionalSection.conditionInput, disabled: isReadOnly, index }} />
                                </div>
                              }

                              <FadeAnimation isShown={showConditionalSection}>
                                <>
                                  {optionalSchema.conditionalSection.inputGroups.map((input, i) => {
                                    return (
                                      <div className="py-2" key={`${input.label}-${index}-${i}`}>
                                        <GenericInputGroup inputGroup={{ ...input, disabled: isReadOnly, index: index ?? input.index }} />
                                      </div>)
                                  })}
                                </>
                              </FadeAnimation>
                            </>
                            : null}
                        </TranslatedFormSubmodule>
                      </FadeAnimation>
                    )
                  })
                  : null}
              </>
            )}
          </FieldArray>
      }

      <TranslatedAddAccordionButton
        label={addLabel}
        clickHandler={createHandler}
        disabled={addDisabled}
        loading={isCreateLoading}
      />
    </div>
  )
}

export default GenericOptionalSubModule