import type {
	ModuleFull,
	IrrigationSystemCreatePayload,
	IrrigationPhaseCreatePayload,
	IrrigationPhaseResponse,
} from "./irrigationTypes";
import {
	FieldType,
	type HectaresInputGroup,
	type ModuleProps,
	type Submodule,
} from "../../../../types/modulesInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { FieldArray, Form, useFormikContext } from "formik";
import {
	BEModulesObj,
	FEModules,
	moduleDescriptions,
} from "../../../../utils/moduleList";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { NoteButton } from "../../../../components/tabsButtons/TabsButtons";
import Spinner from "../../../../components/spinner/Spinner";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import { TranslatedAddAccordionButton } from "../../../../components/input/AddAccordionButton";
import {
	useCreateIrrigationSystemMutation,
	useDeleteIrrigationSystemMutation,
	useDeleteIrrigationPhaseMutation,
	useCreateIrrigationPhaseMutation,
	useGetIrrigationSystemQuery,
	useGetIrrigationPhaseQuery,
} from "../../../../app/features/api/modules/irrigationApiSlice";
import { mergeLocalAndServerChanges, preventFormSubmit } from "../inputs/utils";
import { useIrrigationsContext } from "../../../../contexts/IrrigationsProvider";
import { addModuleTypeToOptionsKit, handleDisableSww, isLucDropdownInput } from "../moduleUtils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import TranslatableText from "../../../../components/translations/TranslatableText";
import ModuleFooter from "../ModuleFooter";
import { isEqual } from 'lodash';
import Hectares from "../../../../components/input/inputGroup/Hectares";
import { useVCEnergyHandler } from "../../../../utils/useVCEnergyHandler";


interface IrrigationProps {
	irrigationSystemSchema: Submodule;
	operationPhaseSchema: Submodule;
	isSystemLoading: boolean;
	isOperationLoading: boolean;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}

const MAX_ENTRIES = 10;

const IrrigationTierOne = (
	props: Omit<ModuleProps, "moduleSchema" | "t2Schema"> & IrrigationProps,
) => {
	const moduleContext = useIrrigationsContext();
	const {
		title,
		irrigationSystemSchema,
		operationPhaseSchema,
		isSystemLoading,
		isOperationLoading,
		footerError,
		blockingError,
		note,
		beModuleType,
		isError,
		setInitValues,
	} = props;
	const {
		values,
		touched,
		dirty,
		isValid,
		handleSubmit,
		validateForm,
		isSubmitting,
		errors,
	} = useFormikContext<ModuleFull>();
	const { moduleList, } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const [nextState, setNextState] = useState<boolean>(false);
	const feModuleType = BEModulesObj[beModuleType];
	const description = moduleDescriptions[feModuleType];
	const number = useMemo(() => {
		const currentModule = moduleList.find((step) => step.id === feModuleType);
		return currentModule?.uniqueId ?? "0.0";
	}, [moduleList, feModuleType]);

	const parentId = useMemo(
		() => moduleList.find((m) => m.id === FEModules.Irrigation)?.uniqueId,
		[moduleList],
	);
	const { isReadOnly } = usePermissions();
	//delete functions
	const [deleteIrrigationSystem, { isLoading: isSystemDelLoading }] =
		useDeleteIrrigationSystemMutation();
	const [deleteOperationPhase, { isLoading: isOperationDelLoading }] =
		useDeleteIrrigationPhaseMutation();
	useVCEnergyHandler<ModuleFull, IrrigationPhaseResponse>({ listInputName: "irrigationPhases", energyTypeInputName: "fuel_type" })


	const handleRemoveSystem = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		if (id) {
			remove(index);
			deleteIrrigationSystem({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
		}
	};
	const handleRemovePhase = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		if (id) {
			remove(index);
			deleteOperationPhase({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
		}
	};

	//create functions
	const [addSystem] = useCreateIrrigationSystemMutation();
	const [addPhase] = useCreateIrrigationPhaseMutation();
	const handleAddSystem = async () => {
		const payload: IrrigationSystemCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			if (values.irrigations.length < MAX_ENTRIES)
				await addSystem(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};
	const handleAddPhase = async () => {
		const payload: IrrigationPhaseCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			if (values.irrigationPhases.length < MAX_ENTRIES)
				await addPhase(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	const navigate = useNavigate();
	const location = useLocation();
	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);

	const handleSaveAndProceed = async () => {
		await validateForm();
		if (isValid) {
			await handleSubmit();
			setNextState(true);
		} else {
			console.log("not valid");
		}
	};

	/* resetting the form is essetial to reset the "dirty" state that's used to control navigation */
	const handleNext = useCallback(async () => {
		// if (!isValid) return;
		const currentModulePath = location.pathname.split("/").pop();
		const currentIndex = moduleList.findIndex(
			(step) => step.path === currentModulePath,
		);
		const nextModulePath =
			currentIndex === moduleList.length - 1
				? "complete"
				: `${moduleList[currentIndex + 1].path}`;
		navigate(`/builder/${activeActivityId}/${nextModulePath}`);
	}, [location.pathname, moduleList, navigate, activeActivityId]);

	useEffect(() => {
		if (!nextState) return;
		if (
			Object.keys(touched).length === 0 &&
			!dirty &&
			!isError &&
			!isSubmitting
		) {
			setNextState(false);
			handleNext();
		}
	}, [nextState, touched, dirty, handleNext, isSubmitting, isError]);

	/* ADD SUBMODULES LOGIC  */
	const { data: irrigationSysData } = useGetIrrigationSystemQuery(
		activeActivityId ?? 0,
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { data: operationPhasesData } = useGetIrrigationPhaseQuery(
		activeActivityId ?? 0,
		{
			refetchOnMountOrArgChange: true,
		},
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const lists = useMemo(
		() => {
			const isIrrigationsUpdated = !isEqual(values.irrigations, irrigationSysData);
			const isPhasesUpdated = !isEqual(values.irrigationPhases, operationPhasesData);

			return {
				irrigations: isIrrigationsUpdated
					? mergeLocalAndServerChanges({
						currentChangesList: values.irrigations,
						incomingList: irrigationSysData ?? [],
					})
					: values.irrigations,
				phases: isPhasesUpdated
					? mergeLocalAndServerChanges({
						currentChangesList: values.irrigationPhases,
						incomingList: operationPhasesData ?? [],
					})
					: values.irrigationPhases,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[irrigationSysData, operationPhasesData],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (lists.irrigations || lists.phases)
			setInitValues(() => ({
				...values,
				irrigationPhases: lists.phases,
				irrigations: lists.irrigations,
			}));
		if (moduleContext) {
			if (lists.irrigations) moduleContext.setIrrigationsSystemIds(lists.irrigations.map((i) => i.id) as number[]);
			if (lists.phases) moduleContext.setOperationPhaseIds(lists.phases.map((i) => i.id) as number[]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lists, setInitValues]);


	return blockingError ? (
		<section>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey={title} />
					</h2>
					<div className="module-number header-number ff-light-ext">
						{number}
					</div>
				</div>

				<span className="error fs-13 pb-2">{blockingError}</span>
			</div>
		</section>
	) : (
		<section>
			<Form onKeyDown={preventFormSubmit}>
				<div className="module-group">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-14 ff-medium-ext module-header w-fit">
							<TranslatableText translationKey={title} />
						</h2>
						<div className="module-number header-number ff-light-ext">
							{number}
						</div>
					</div>

					<div className="pos-relative mb-3">
						<p className="fs-14">
							<TranslatableText translationKey={description} />
						</p>
						<div
							className="pos-absolute d-flex align-items-center h-100"
							style={{ top: 0, left: "100%" }}
						>
							<NoteButton active={note !== null} />
						</div>
					</div>
					{
						values.parent.area && (
							<Hectares
								type={FieldType.HECTARES}
								label="module.hectares"
								unit="[ha]"
								inputName="parent.area"
								disabled
							/>
						)
					}
					{/* Irrigation Systems */}
					{isSystemLoading ? (
						<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
							<span className="text-white fs-12">
								Irrigation Systems loading...
							</span>
							<Spinner size={36} padding={8} />
						</div>
					) : (
						<FieldArray name="irrigations">
							{({ remove }) => (
								<>
									{values.irrigations.map((irrigationSystem, index) => (
										<TranslatedFormSubmodule
											key={`submodule-${irrigationSystem.id}`}
											submoduleName={irrigationSystemSchema.name}
											removeHandler={() =>
												handleRemoveSystem(remove, index, irrigationSystem.id)
											}
											removeLoading={isSystemLoading}
											itemId={irrigationSystem.id ?? 0}
											itemIndex={index + 1}
										>
											{irrigationSystemSchema.inputGroups.map((input, i) => {
												const curatedInput = handleDisableSww({ input, isReadOnly });
												return (
													<div
														className="py-1"
														key={`input-${irrigationSystem.id}-${i}`}
													>
														<GenericInputGroup inputGroup={{ ...addModuleTypeToOptionsKit(curatedInput, irrigationSystem.module_type?.id), index, disabled: isReadOnly }} />
													</div>
												)
											})}
										</TranslatedFormSubmodule>
									))}
								</>
							)}
						</FieldArray>
					)}
					<TranslatedAddAccordionButton
						label="module.add_new_irrigation_system"
						clickHandler={handleAddSystem}
						disabled={!(values.irrigations.length < MAX_ENTRIES) || isReadOnly}
					/>

					{/* Irrigation Phases */}
					{isOperationLoading ? (
						<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
							<span className="text-white fs-12">
								Operation Phases loading...
							</span>
							<Spinner size={36} padding={8} />
						</div>
					) : (
						<FieldArray name="irrigationPhases">
							{({ remove }) => (
								<>
									{values.irrigationPhases.map((irrigationPhase, index) => (
										<TranslatedFormSubmodule
											key={`submodule-${irrigationPhase.id}`}
											submoduleName={operationPhaseSchema.name}
											removeHandler={() =>
												handleRemovePhase(remove, index, irrigationPhase.id)
											}
											removeLoading={isOperationLoading}
											itemId={irrigationPhase.id ?? 0}
											itemIndex={index + 1}
										>
											{operationPhaseSchema.inputGroups.map((input, i) => {
												const curatedInput = handleDisableSww({ input, isReadOnly });
												return (
													<div
														className="py-1"
														key={`input-${irrigationPhase.id}-${i}`}
													>
														<GenericInputGroup inputGroup={{ ...addModuleTypeToOptionsKit(curatedInput, irrigationPhase.module_type?.id), index, disabled: isReadOnly }} />
													</div>
												)
											})}
										</TranslatedFormSubmodule>
									))}
								</>
							)}
						</FieldArray>
					)}

					<TranslatedAddAccordionButton
						label="module.add_new_operation_phase"
						clickHandler={handleAddPhase}
						disabled={!(values.irrigationPhases.length < MAX_ENTRIES) || isReadOnly}
					/>
				</div>

				<ModuleFooter
					footerError={footerError}
					errors={errors}
					touched={touched}
					isLoading={isSystemLoading || isOperationLoading || isSubmitting || isSystemDelLoading || isOperationDelLoading}
					isDisabled={isSystemLoading || isOperationLoading || isSubmitting || isReadOnly || isSystemDelLoading || isOperationDelLoading}
					handleSaveAndProceed={handleSaveAndProceed}
					handleNext={handleNext}
					shouldBlock={shouldBlock}
				/>
			</Form>
		</section>
	);
};

export default IrrigationTierOne;
