import { EntryShared, ModuleShared, ParentPayload, ParentResponse } from "../../../../types/modulesInterfaces";

export interface ElectricityResponse extends EntryShared {
	country_t2: number | null;
	quantity_consumed_per_year_start: number | null;
	quantity_consumed_per_year_w: number | null;
	quantity_consumed_per_year_wo: number | null;
	mwh_renewables_start: number | null;
	mwh_renewables_w: number | null;
	mwh_renewables_wo: number | null;

	ef_source: number | null;
	energy_ef_t2_start: number | null;
	energy_ef_t2_w: number | null;
	energy_ef_t2_wo: number | null;
	transmission_loss_t2_start: number | null;
	transmission_loss_t2_w: number | null;
	transmission_loss_t2_wo: number | null;
}

export interface ElectricityDefaults {
	ef_source_default: number | null;
	energy_ef_t2_start_default: number | null;
	energy_ef_t2_w_default: number | null;
	energy_ef_t2_wo_default: number | null;
	country_t2_default: number | null;
	transmission_loss_t2_start_default: number | null;
	transmission_loss_t2_w_default: number | null;
	transmission_loss_t2_wo_default: number | null;
}

export interface ElectricityData extends ElectricityResponse, Partial<ElectricityDefaults> { }

export interface FuelResponse extends EntryShared {
	fuel_type_start: number | null,
	fuel_type_w: number | null,
	fuel_type_wo: number | null,
	fuel_type_thread: number | null,
	quantity_consumed_per_year_start: number | null;
	quantity_consumed_per_year_w: number | null;
	quantity_consumed_per_year_wo: number | null;

	account_for_co2: boolean | null;
	energy_ef_co2_t2_start: number | null,
	energy_ef_co2_t2_w: number | null,
	energy_ef_co2_t2_wo: number | null,
	energy_ef_ch4_t2_start: number | null,
	energy_ef_ch4_t2_w: number | null,
	energy_ef_ch4_t2_wo: number | null,
	energy_ef_n2o_t2_start: number | null,
	energy_ef_n2o_t2_w: number | null,
	energy_ef_n2o_t2_wo: number | null,

	is_solid_fuel_selected: boolean | null; //helper value, fe side only	
}
export interface FuelDefaults {
	energy_ef_co2_t2_start_default: number | null,
	energy_ef_co2_t2_w_default: number | null,
	energy_ef_co2_t2_wo_default: number | null,
	energy_ef_ch4_t2_start_default: number | null,
	energy_ef_ch4_t2_w_default: number | null,
	energy_ef_ch4_t2_wo_default: number | null,
	energy_ef_n2o_t2_start_default: number | null,
	energy_ef_n2o_t2_w_default: number | null,
	energy_ef_n2o_t2_wo_default: number | null,
}
export interface FuelData extends FuelResponse, Partial<FuelDefaults> { }

export interface ModuleFull {
	electricities: ElectricityData[];
	fuels: FuelData[];
	parent: ParentPayload;
}

export enum EnergyModules {
	Electricity = 'module.electricity_optional',
	Fuel = 'module.fuel_usage_optional',
}