import { SiMicrosoftexcel, SiFiles } from "react-icons/si";
import ModuleButton from '../../../../components/button/ModuleButton'
import useDownloadExcel from '../hooks/useDownloadExcel';
import AlertModal from '../../../../components/modal/AlertModal';
import TranslatableText from '../../../../components/translations/TranslatableText';

const DownloadReportButtons = ({ selectedActivities, projectId }: { selectedActivities?: number[], projectId: number }) => {
  const { downloadExcel, downloadNarrativeReport, isLoading, handleCancel, errorMsg, isOpen } = useDownloadExcel({ projectId, activities: selectedActivities })

  return (
    <div className='d-flex f-column gap-8 pb-2 me-1'>
      <ModuleButton
        icon={SiFiles}
        buttonType="button"
        handleClick={downloadNarrativeReport}
        labelKey="main.download_narrative_report"
        isLoading={isLoading}
        disabled={!projectId}
      />

      <ModuleButton
        icon={SiMicrosoftexcel}
        buttonType="button"
        handleClick={downloadExcel}
        labelKey="main.download_excel"
        isLoading={isLoading}
        disabled={!projectId}
      />

      <AlertModal
        modalOpen={isOpen}
        handleSubmit={handleCancel}
        handleCancel={handleCancel}
      >
        <div className='p-2'>
          {
            errorMsg ? errorMsg : <TranslatableText translationKey="project.report_error" />
          }
        </div>
      </AlertModal>
    </div>

  )
}

export default DownloadReportButtons