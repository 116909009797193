
export const usePaginate = ({ 
    next, 
    previous, 
    dynamicNext,
    setUrl 
}: { 
    next?: string; 
    previous?: string, 
    dynamicNext?: (number: number) => string,
    setUrl: (url: string) => void 
}) => {
    const handleNext = async() => {
        if (next) {
            setUrl(next);
        }
    };
    const handlePrev = async() => {

        if (previous) {
            setUrl(previous);
        }
    };

    const handlePageClick = async(page: number) => {
        if (dynamicNext) {
            setUrl(dynamicNext(page));
        }
    };
    return {
        handleNext,
        handlePrev,
        handlePageClick
    };
};
function extractPathFromURL(url: string): string | null {
    const match = url.match(/api\/(.*)/);
    return match ? match[1] : null;
  }