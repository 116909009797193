import { NoteButton } from "../../../components/tabsButtons/TabsButtons";
import { Form, FieldArray, useFormikContext, getIn } from "formik";
import type {
	BelongsToLuc,
	ModuleProps,
	OptionalSubmodule,
	StartWithWithoutGeneric,
	T2Schema,
} from "../../../types/modulesInterfaces";
import { FieldType } from "../../../types/modulesInterfaces";
import { useAppSelector, useCustomTranslationHandler, usePermissions } from "../../../app/hooks";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import FormSubmodule from "../../../components/formSubmodule/FormSubmodule";
import AddAccordionButton from "../../../components/input/AddAccordionButton";
import GenericInputGroup from "../../../components/input/inputGroup/GenericInputGroup";
import { useGetLandUseChangesQuery } from "../../../app/features/api/modules/landUseChangeApiSlice";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import {
	BEModulesObj,
	moduleDescriptions,
} from "../../../utils/moduleList";
import { getSWWInputGroup, handleDisableSww } from "./moduleUtils";
import ModuleContext from "../ModuleContext";
import { preventFormSubmit } from "./inputs/utils";
import { TranslatedFormSubmodule } from "../../../components/formSubmodule/TranslatedFormSubmodule";
import TranslatableText from "../../../components/translations/TranslatableText";
import ModuleFooter from "./ModuleFooter";

const isSWW = (inputType: FieldType) =>
	[FieldType.SWW, FieldType.SWW_BOOL, FieldType.SWW_SELECT].includes(inputType);

const GenericModule = ({
	title,
	moduleSchema,
	note,
	closingNotes,
	t2Schema,
	children,
	beModuleType,
	isLoading,
	footerError,
	blockingError,
	disableOptionals,
	isError,
}: ModuleProps) => {
	const { activeAction, moduleList } = useAppSelector(selectCurrentBuilder);
	const {
		dirty,
		values,
		setFieldValue,
		touched,
		handleSubmit,
		validateForm,
		isValid,
		isSubmitting,
		errors,
	} = useFormikContext<any>();
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { data: lucData } = useGetLandUseChangesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const navigate = useNavigate();
	const location = useLocation();
	const { isReadOnly } = usePermissions();

	const feModuleType = BEModulesObj[beModuleType];
	const description = moduleDescriptions[feModuleType];
	const number = useMemo(() => {
		const currentModule = moduleList.find((step) => step.id === feModuleType);
		return currentModule?.uniqueId ?? "0.0";
	}, [moduleList, feModuleType]);

	const { belongsToLuc } = useContext(ModuleContext)
	const [nextState, setNextState] = useState<boolean>(false);
	useEffect(() => {
		if (lucData && !values.module?.land_use_change)
			setFieldValue("module.land_use_change", lucData.module);
	}, [lucData, setFieldValue]);

	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);

	const handleSaveAndProceed = async () => {
		await validateForm();
		if (isValid) {
			await handleSubmit();
			setNextState(true);
		} else {
			console.log("not valid");
		}
	};

	/* resetting the form is essetial to reset the "dirty" state that's used to control navigation */
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleNext = useCallback(async () => {
		// if (!isValid) return;
		const currentModulePath = location.pathname.split("/").pop();
		const currentIndex = moduleList.findIndex(
			(step) => step.path === currentModulePath,
		);
		const nextModulePath =
			currentIndex === moduleList.length - 1
				? "complete"
				: `${moduleList[currentIndex + 1].path}`;
		navigate(`/builder/${activeActivityId}/${nextModulePath}`);
	}, [location.pathname, moduleList, navigate]);

	useEffect(() => {
		if (!nextState) return;
		if (
			Object.keys(touched).length === 0 &&
			!dirty &&
			!isError &&
			!isSubmitting
		) {
			setNextState(false);
			handleNext();
		}
	}, [nextState, touched, dirty, handleNext, isSubmitting, isError]);

	return blockingError ? (
		<section>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">{title}</h2>
					<div className="module-number header-number ff-light-ext">
						{number}
					</div>
				</div>

				<span className="error fs-13 pb-2">{blockingError}</span>
			</div>
		</section>
	) : (
		<section>
			<Form onKeyDown={preventFormSubmit}>
				<div className="module-group">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-14 ff-medium-ext module-header w-fit">
							<TranslatableText translationKey={title} />
						</h2>
						<div className="module-number header-number ff-light-ext">
							{number}
						</div>
					</div>

					<div className="pos-relative mb-3">
						<p className="fs-14">
							<TranslatableText translationKey={description} />
						</p>
						<div
							className="pos-absolute d-flex align-items-center h-100"
							style={{ top: 0, left: "100%" }}
						>
							<NoteButton active={note !== null} />
						</div>
					</div>

					{moduleSchema.initInputGroups.map((input, index) => {
						const curatedInput = handleDisableSww({ input, belongsToLuc, isReadOnly });
						return input.type === FieldType.HECTARES ? (
							/* if the module belongs to land use change we display the area from luc */
							<GenericInputGroup
								inputGroup={{
									...input,
									inputName: input.inputName,
								}}
								key={input.label}
							/>
						) : (
							<div className="py-2" key={input.label}>
								<GenericInputGroup
									inputGroup={{ ...curatedInput, disabled: isReadOnly }}
								/>
							</div>
						);
					})}

					{moduleSchema.mandatorySubmodules.map((subModule) => (
						<TranslatedFormSubmodule
							submoduleName={subModule.name}
							key={subModule.name}
							note={subModule.note}
						>
							{subModule.inputGroups.map((input, index) => {
								const curatedInput = handleDisableSww({ input, belongsToLuc, isReadOnly });
								/* if the module belongs to Land use change: we disabled the statuses that are not related */
								return (
									<div className="py-2" key={input.label}>
										<GenericInputGroup inputGroup={{ ...curatedInput, disabled: isReadOnly }} />
										{/* autosubmit: true */}
									</div>
								);
							})}
						</TranslatedFormSubmodule>
					))}

					{!disableOptionals &&
						moduleSchema.optionalSubmodules?.map((subModule, index) => (
							<AccordionList
								subModule={subModule}
								key={subModule.name}
								t2Schema={t2Schema}
								belongsToLuc={belongsToLuc}
								isReadOnly={isReadOnly}
							/>
						))}

					{children ? children({ belongsToLuc, isSWW }) : null}
				</div>

				<ModuleFooter
					footerError={footerError}
					errors={errors}
					touched={touched}
					isLoading={isLoading || isSubmitting}
					isDisabled={isLoading || isSubmitting || isReadOnly}
					handleSaveAndProceed={handleSaveAndProceed}
					handleNext={handleNext}
					shouldBlock={shouldBlock}
					closingNotes={closingNotes}
				/>
			</Form>
		</section>
	);
};

const AccordionList = ({
	subModule,
	t2Schema,
	isReadOnly,
	belongsToLuc,
}: {
	subModule: OptionalSubmodule<any>;
	t2Schema?: T2Schema;
	isReadOnly: boolean;
	belongsToLuc?: BelongsToLuc;
}) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const subValues = getIn(values, subModule.inputName);
	const translationHandler = useCustomTranslationHandler();
	//handle push is to add a new Accordion
	const handlePush = (push: (obj: any) => void) => {
		push({ ...subModule.initialValues });
		if (subModule.linkedTierTwoInputs?.length && t2Schema) {
			for (const linkedT2 of subModule.linkedTierTwoInputs) {
				const foundSubmodule = t2Schema.optionalSubmodules?.find(
					(optSub) => optSub.inputName === linkedT2,
				);
				if (foundSubmodule?.initialValues) {
					const t2SubmoduleValues = getIn(values, linkedT2);
					setFieldValue(linkedT2, [
						...t2SubmoduleValues,
						foundSubmodule?.initialValues,
					]);
				}
			}
		}
	};
	const handleRemove = (
		remove: <T>(index: number) => T | undefined,
		index: number,
	) => {
		remove(index);
		if (subModule.linkedTierTwoInputs?.length && t2Schema) {
			for (const linkedT2 of subModule.linkedTierTwoInputs) {
				const t2SubmoduleValues = getIn(values, linkedT2);
				t2SubmoduleValues.splice(index, 1);
				setFieldValue(linkedT2, [...t2SubmoduleValues]);
			}
		}
	};

	return Array.isArray(subValues) ? (
		<FieldArray name={subModule.inputName}>
			{({ remove, push }) => {
				const translatedSubModuleName = translationHandler(subModule.name);
				return (
					<>
						{subValues.length > 0 &&
							subValues.map((element, index) => (
								<FormSubmodule
									key={`${subModule.inputName}-${index}`}
									submoduleName={`${index + 1}. ${subModule.name}`}
									removeHandler={() => handleRemove(remove, index)}
									initiallyOpen={false}
								>
									{subModule.inputGroups.map((input, i) => {
										const curatedInput = handleDisableSww({ input, belongsToLuc, isReadOnly, index });
										return (
											<div className="py-2" key={`${input.label}-${index}-${i}`}>
												<GenericInputGroup inputGroup={{ ...curatedInput, disabled: isReadOnly }}
												/>
											</div>
										);
									})}


									{subModule.conditionalSection ?
										<>
											{subModule.conditionalSection.conditionInput &&
												<div className="py-2" key={`${subModule.conditionalSection.conditionInput.label}-${index}`}>
													<GenericInputGroup inputGroup={{ ...subModule.conditionalSection.conditionInput, disabled: isReadOnly }} />
												</div>
											}

											{subModule.conditionalSection.inputGroups.map((input, i) => {
												return <div className="py-2" key={`${input.label}-${index}-${i}`}>
													<GenericInputGroup inputGroup={{ ...input, disabled: isReadOnly }} />
												</div>
											})}
										</>
										: null}
								</FormSubmodule>
							))}

						{subModule.repeatable ? (
							<AddAccordionButton
								label={`Add ${translatedSubModuleName}`}
								clickHandler={() => handlePush(push)}
								disabled={subModule?.max ? subValues.length >= subModule?.max : false}
							/>
						) : null}
					</>
				)
			}}
		</FieldArray>
	) : (
		<TranslatedFormSubmodule submoduleName={subModule.name} note={subModule.note} initiallyOpen={false}>
			{subModule.inputGroups.map((input, i) => {
				const swwInput = input as StartWithWithoutGeneric;
				return isSWW(input.type) ? (
					<div className="py-2" key={`${input.label}-${i}`}>
						<GenericInputGroup
							inputGroup={
								getSWWInputGroup({ swwInput, isReadOnly, belongsToLuc })
							}
						/>
					</div>
				) : (
					<div className="py-2" key={`${input.label}-${i}`}>
						<GenericInputGroup inputGroup={{ ...input, disabled: isReadOnly }} />{" "}
						{/* autosubmit: true */}
					</div>
				);
			})}
		</TranslatedFormSubmodule>
	);
};

export default GenericModule;
