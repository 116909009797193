import { Field, FieldArray, Form, getIn, useFormikContext } from "formik";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAppSelector, useGetAppLanguage, usePermissions } from "../../../../app/hooks";
import {
	BEModulesObj,
	FEModules,
	moduleDescriptions,
} from "../../../../utils/moduleList";
import { BEModules } from "../../../../utils/beModules";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useCreateForestDisturbanceMutation,
	useDeleteForestDisturbanceMutation,
	useGetForestDisturbancesQuery,
} from "../../../../app/features/api/modules/forestManagementApiSlice";
import type {
	ForestDisturbanceCreatePayload,
	ForestDisturbanceData,
	ModuleFull,
} from "./forestManagementTypes";

import type {
	BelongsToLuc,
	DropdownInputGroup,
	InputGroup,
	ModuleProps,
	StartWithWithoutGeneric,
	Submodule,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import {
	selectCurrentBuilder,
	selectIsFetchingData,
} from "../../../../app/features/builder/builderSlice";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import { NoteButton } from "../../../../components/tabsButtons/TabsButtons";
import { Activity } from "../../../../types/interfaces";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import FormRow from "../../../../components/input/formRow/FormRow";
import Skeleton from "react-loading-skeleton";
import {
	useGetForestConditionTypesQuery,
	useGetForestTypesQuery,
} from "../../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import Spinner from "../../../../components/spinner/Spinner";
import { TranslatedAddAccordionButton } from "../../../../components/input/AddAccordionButton";
import { useGetLandUseChangesQuery } from "../../../../app/features/api/modules/landUseChangeApiSlice";
import { mergeLocalAndServerChanges, preventFormSubmit } from "../inputs/utils";
import ForestContext from "../../../../contexts/ForestContext";
import { ForestManagementSections } from "./forestManagement";
import { fetchLandUseTypes, filterLucTypesByClimateMoisture, handleDisableSww } from "../moduleUtils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import TranslatableText from "../../../../components/translations/TranslatableText";
import ModuleFooter from "../ModuleFooter";

const ForestManagementTierOne = ({
	title,
	moduleSchema,
	disturbanceSchema,
	isLoading,
	footerError,
	isDisturbanceLoading,
	blockingError,
	beModuleType,
	note,
	isError,
	belongsToLuc,
	setInitValues,
}: ModuleProps & {
	isDisturbanceLoading?: boolean;
	disturbanceSchema: Submodule;
	belongsToLuc?: BelongsToLuc;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}) => {
	const {
		dirty,
		values,
		touched,
		isValid,
		handleSubmit,
		validateForm,
		isSubmitting,
		errors,
	} = useFormikContext<ModuleFull>();
	const forestContext = useContext(ForestContext)
	const { activeActivityId, activity, project } = useAppSelector(selectCurrentProject);
	const vegetationLevelsOptions = fetchLandUseTypes({beModuleId: BEModules.ForestManagement, activity, project})
	const { isReadOnly } = usePermissions();
	const vegetationLevels: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.forest_category",
		unit: "[unit]",
		inputName: "parent.land_use_type_start",
		dropdownOptions: async () => {
			let lucTypesKit = await vegetationLevelsOptions()
			if (forestContext) forestContext.setLucTypes(lucTypesKit.data)
			return lucTypesKit
		},
		disabled: isReadOnly,
	};

	const [nextState, setNextState] = useState<boolean>(false);

	const { moduleList } = useAppSelector(selectCurrentBuilder);

	const feModuleType = BEModulesObj[beModuleType];
	const description = moduleDescriptions[feModuleType];
	const number = useMemo(() => {
		const currentModule = moduleList.find((step) => step.id === feModuleType);
		return currentModule?.uniqueId ?? "0.0";
	}, [moduleList, feModuleType]);


	const navigate = useNavigate();
	const location = useLocation();
	const shouldBlock = useCallback(
		() => Object.keys(touched).length > 0 && dirty,
		[dirty, touched],
	);
	const handleSaveAndProceed = async () => {
		await validateForm();
		if (isValid) {
			await handleSubmit();
			setNextState(true);
		} else {
			console.log("not valid");
		}
	};

	/* resetting the form is essetial to reset the "dirty" state that's used to control navigation */
	const handleNext = useCallback(async () => {
		// if (!isValid) return;
		const currentModulePath = location.pathname.split("/").pop();
		const currentIndex = moduleList.findIndex(
			(step) => step.path === currentModulePath,
		);
		const nextModulePath =
			currentIndex === moduleList.length - 1
				? "complete"
				: `${moduleList[currentIndex + 1].path}`;
		navigate(`/builder/${activeActivityId}/${nextModulePath}`);
	}, [location.pathname, moduleList, navigate, activeActivityId]);

	useEffect(() => {
		if (!nextState) return;
		if (
			Object.keys(touched).length === 0 &&
			!dirty &&
			!isError &&
			!isSubmitting
		) {
			setNextState(false);
			handleNext();
		}
	}, [nextState, touched, dirty, handleNext, isSubmitting, isError]);

	/* ADD DISTURBANCES LOGIC */
	const { data: disturbancesData } = useGetForestDisturbancesQuery(
		activeActivityId ?? 0,
		{ refetchOnMountOrArgChange: true },
	);
	const { data: lucData } = useGetLandUseChangesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const disturbances = useMemo(() => {
		return mergeLocalAndServerChanges({
			currentChangesList: values.disturbances,
			incomingList:
				disturbancesData?.map((dist) => ({
					...dist,
					land_use_change: lucData?.module,
				})) ?? [] as ForestDisturbanceData[],
		});
	}, [disturbancesData, lucData]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setInitValues(() => ({
			...values,
			disturbances: disturbances,
		}));
		if (forestContext && forestContext.setDisturbancesIds) forestContext.setDisturbancesIds(disturbancesData?.map((d) => d.id))
		// eslint-disable-next-line
	}, [disturbances, setInitValues]); // we don't want values here

	const degradationPercentage: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "parent.average_yearly_degradation_percentage",
		label: "module.avg_yearly_degradation",
		unit: "[%]",
		disabled: isReadOnly
	};
	const curatedDegradationPercentage = handleDisableSww({ input: degradationPercentage, belongsToLuc, isReadOnly });
	return (
		<section>
			<Form onKeyDown={preventFormSubmit}>
				<div className="module-group">
					<div className="pos-relative mb-2 pb-1">
						<h2 className="fs-14 ff-medium-ext module-header w-fit">
							<TranslatableText translationKey={title} />
						</h2>
						<div className="module-number header-number ff-light-ext">
							{number}
						</div>
					</div>

					<div className="pos-relative mb-3">
						<p className="fs-14">
							<TranslatableText translationKey={description} />
						</p>
						<div
							className="pos-absolute d-flex align-items-center h-100"
							style={{ top: 0, left: "100%" }}
						>
							<NoteButton active={note !== null} />
						</div>
					</div>

					{moduleSchema.initInputGroups.map((input, index) => {
						const curatedInput = handleDisableSww({ input, belongsToLuc, isReadOnly });
						return input.type === FieldType.HECTARES ? (
							/* if the module belongs to land use change we display the area from luc */
							<GenericInputGroup
								inputGroup={input} //, autosubmit: true
								key={input.label}
							/>
						) : (
							<div className="py-2" key={`${input.label}-${index}`}>
								<GenericInputGroup
									inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
								/>
							</div>
						);
					})}

					<TranslatedFormSubmodule
						submoduleName={"module.type_of_forest"}
						key={"Type of Forest"}
					>
						<div className="py-2">
							<div className="pb-2">
								<TypeOfForestRadioGroup disabled={isReadOnly} />
							</div>
							<GenericInputGroup inputGroup={{ ...vegetationLevels }} />
						</div>
					</TranslatedFormSubmodule>

					{moduleSchema.mandatorySubmodules.map((subModule) => (
						<TranslatedFormSubmodule
							submoduleName={subModule.name}
							key={subModule.name}
							note={subModule.note}
							initiallyOpen={false}
						>
							{subModule.inputGroups.map((input, index) => {
								/* if the module belongs to Land use change: we disabled the statuses that are not related */
								const curatedInput = handleDisableSww({ input, belongsToLuc, isReadOnly });
								return (
									<div className="py-2" key={`${input.label}-${index}`}>
										<GenericInputGroup inputGroup={{ ...curatedInput, disabled: isReadOnly }} />
									</div>
								);
							})}
						</TranslatedFormSubmodule>
					))}

					<Disturbances
						disturbanceSchema={disturbanceSchema}
						isDisturbanceLoading={isDisturbanceLoading}
						belongsToLuc={belongsToLuc}
						isReadOnly={isReadOnly}
					/>

					{/* DEGRADATION */}
					<TranslatedFormSubmodule submoduleName={ForestManagementSections.DEGRADATION} key={"Degradation"}>
						<div className="py-2">
							<GenericInputGroup
								inputGroup={
									{
										...curatedDegradationPercentage,
									}
								}
							/>
						</div>
					</TranslatedFormSubmodule>
				</div>

				<ModuleFooter
					footerError={footerError}
					errors={errors}
					touched={touched}
					isLoading={isLoading || isDisturbanceLoading || isSubmitting}
					isDisabled={isLoading || isDisturbanceLoading || isSubmitting || isReadOnly}
					handleSaveAndProceed={handleSaveAndProceed}
					handleNext={handleNext}
					shouldBlock={shouldBlock}
				/>
			</Form>
		</section>
	);
};

export default ForestManagementTierOne;

const TypeOfForestRadioGroup = ({ disabled }: { disabled: boolean }) => {
	const [selectedRadio, setSelectedRadio] = useState<number | null>(null);

	const { data: forestTypes, isLoading: isForestTypeLoading } =
		useGetForestTypesQuery();
	const { data: forestCondTypes, isLoading: isForestCondTypeLoading } =
		useGetForestConditionTypesQuery();

	const { values, setFieldValue, errors, touched } = useFormikContext<ModuleFull>();
	const forestContext = useContext(ForestContext)
	const { apiLanguageField } = useGetAppLanguage()
	useEffect(() => {
		if (forestTypes) forestContext?.setForestTypes(forestTypes)
	}, [forestContext, forestTypes])

	const typeOfForestInputName = "parent.forest_type";
	const forestConditionTypeInputName = "parent.forest_condition_type";
	const typeOfForestValue: number = useMemo(() => getIn(values, typeOfForestInputName), [values, typeOfForestInputName]);
	const forestConditionTypeValue: number = useMemo(() => getIn(
		values,
		forestConditionTypeInputName,
	), [values, forestConditionTypeInputName]);

	useEffect(() => {
		const isTypeNatural = forestTypes
			?.find((type) => type.id === typeOfForestValue)
			?.name_en?.startsWith("Natural");
		const isTypePlantation = forestTypes
			?.find((type) => type.id === typeOfForestValue)
			?.name_en?.startsWith("Plantation");
		const isConditionSecondary = forestCondTypes
			?.find((type) => type.id === forestConditionTypeValue)
			?.name_en?.startsWith("Secondary");
		const isConditionPrimary = forestCondTypes
			?.find((type) => type.id === forestConditionTypeValue)
			?.name_en?.startsWith("Primary");


		if (isTypeNatural && isConditionPrimary) {
			setSelectedRadio(1);
		} else if (isTypeNatural && isConditionSecondary) {
			setSelectedRadio(2);
		} else if (isTypePlantation && isConditionSecondary) {
			setSelectedRadio(3);
		}
	}, [typeOfForestValue, forestConditionTypeValue, forestTypes, forestCondTypes]);

	const handleRadioChange = (clickedRadio: number) => {
		setSelectedRadio(clickedRadio);
		const typeValue =
			clickedRadio === 1 || clickedRadio === 2
				? forestTypes?.find((type) => type.name_en?.startsWith("Natural"))?.id
				: clickedRadio === 3
					? forestTypes?.find((type) => type.name_en?.startsWith("Plantation"))?.id
					: null;
		const conditionValue =
			clickedRadio === 1
				? forestCondTypes?.find((type) => type.name_en?.startsWith("Primary"))?.id
				: clickedRadio === 2 || clickedRadio === 3
					? forestCondTypes?.find((type) => type.name_en?.startsWith("Secondary"))
						?.id
					: null;
		setFieldValue(typeOfForestInputName, typeValue);
		setFieldValue(forestConditionTypeInputName, conditionValue);
	};

	const validationError = useMemo(() => {
		return getIn(errors, typeOfForestInputName) || getIn(touched, typeOfForestInputName) ? getIn(errors, typeOfForestInputName) : undefined
	}, [getIn, errors, typeOfForestInputName, forestConditionTypeInputName])

	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const options = useMemo(() => {
		if (!forestTypes || !forestCondTypes) return [];
		return [
			{
				label: forestCondTypes?.find((type) => type.name_en?.startsWith("Primary"))?.[apiLanguageField],
				value: 1,
			},
			{
				label: forestCondTypes?.find((type) => type.name_en?.startsWith("Secondary"))?.[apiLanguageField],
				value: 2,
			},
			{
				label: "Forest plantation (high density)",
				value: 3,
			},
		]
	}, [forestTypes, forestCondTypes, apiLanguageField])

	return (
		<FormRow
			style={{ height: "fit-content" }}
			error={validationError}
		>
			<div
				role="group"
				aria-labelledby="Type of forest"
				className="d-flex f-column"
				style={{ width: "80%" }}
			>
				{isFetchingData || isForestTypeLoading || isForestCondTypeLoading || !options.length ? (
					<Skeleton height="100%" count={3} />
				) : (
					options.map((option, index) => (
						<label
							className="clickable d-flex align-items-center pb-1"
							key={index}
							onClick={() => {
								if (!disabled) handleRadioChange(option.value)
							}}
						>
							<Field
								type="radio"
								value={option.value}
								checked={selectedRadio === option.value}
								onClick={() => {
									if (!disabled) handleRadioChange(option.value)
								}}
								name="not_used"
								disabled={disabled}
							/>
							<span className="ps-1 fs-12 ff-medium">
								{
									option.value === 3
										? <TranslatableText translationKey="module.forest_plantation" />
										: option.label

								}
							</span>
						</label>
					))
				)}
			</div>
		</FormRow>
	);
};

const Disturbances = ({
	isDisturbanceLoading,
	disturbanceSchema,
	belongsToLuc,
	isReadOnly
}: {
	isDisturbanceLoading?: boolean;
	disturbanceSchema: Submodule;
	belongsToLuc?: BelongsToLuc;
	isReadOnly: boolean;
}) => {
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { values } = useFormikContext<ModuleFull>();

	const [deleteDisturbance, { isLoading }] =
		useDeleteForestDisturbanceMutation();
	const [addDisturbance, { isLoading: isAddingDisturbance }] = useCreateForestDisturbanceMutation();
	const parentId = moduleList.find(
		(m) => m.id === FEModules.ForestManagement,
	)?.uniqueId;

	const handleRemove = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		remove(index);
		if (id)
			deleteDisturbance({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
	};
	const handleAdd = async () => {
		const payload: ForestDisturbanceCreatePayload = {
			body: { parent: parentId ?? 0 },
			activityId: activeActivityId ?? 0,
		};
		try {
			await addDisturbance(payload).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	// const handleDisableSww = (input: InputGroup) => {
	// 	if (!belongsToLuc || !isSWW(input.type)) return input;
	// 	const swwInput = input as StartWithWithoutGeneric;
	// 	return {
	// 		...swwInput,
	// 		startProps: {
	// 			...swwInput.startProps,
	// 			disabled: !belongsToLuc.is_start || swwInput.startProps?.disabled,
	// 		},
	// 		withProps: {
	// 			...swwInput.withProps,
	// 			disabled: !belongsToLuc.is_w || swwInput.withProps?.disabled,
	// 		},
	// 		withoutProps: {
	// 			...swwInput.withoutProps,
	// 			disabled: !belongsToLuc.is_wo || swwInput.withoutProps?.disabled,
	// 		},
	// 	} as StartWithWithoutGeneric;
	// };

	return (
		// <GenericOptionalSubModule
		//   isOptionalLoading={isDisturbanceLoading}
		//   optionalSchema={disturbanceSchema}
		//   isLoading={isLoading}
		//   values={values.disturbances}
		//   removeHandler={handleRemove}
		//   createHandler={handleAdd}
		//   addLabel="Add Disturbance"
		// />
		<div>
			{isDisturbanceLoading ? (
				<div className="w-100 d-flex justify-content-center align-items-center bg-darker-gray br-6">
					<span className="text-white fs-12">Disturbance loading...</span>
					<Spinner size={36} padding={8} />
				</div>
			) : (
				<FieldArray name="disturbances">
					{({ remove }) => (
						<>
							{values?.disturbances?.length > 0
								? values.disturbances.map((disturbance, index) => (
									<TranslatedFormSubmodule
										key={disturbance.id}
										submoduleName={disturbanceSchema.name}
										removeHandler={() =>
											handleRemove(() => remove(index), index, disturbance.id)
										}
										removeLoading={isLoading}
										itemId={disturbance.id}
										itemIndex={index + 1}
									>
										{disturbanceSchema?.inputGroups.map((input, i) => {
											const curatedInput = handleDisableSww({ input, isReadOnly, belongsToLuc });
											return (
												<div className="py-1" key={`${input.label}-${i}`}>
													<GenericInputGroup
														inputGroup={
															{ ...curatedInput, index, disabled: isReadOnly } as InputGroup
														}
													/>
												</div>
											);
										})}
									</TranslatedFormSubmodule>
								))
								: null}
						</>
					)}
				</FieldArray>
			)}

			<TranslatedAddAccordionButton label="module.add_disturbance" clickHandler={handleAdd} disabled={isReadOnly} loading={isAddingDisturbance} />
		</div>
	);
};
