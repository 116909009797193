import { useCallback, useEffect, useMemo } from "react";
import type {
	ModuleProps,
	TranslationKey,
} from "../../../../types/modulesInterfaces";
import { useAppSelector, usePermissions } from "../../../../app/hooks";
import { selectCurrentBuilder } from "../../../../app/features/builder/builderSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useFormikContext } from "formik";
import {
	EnergyModules,
	type ElectricityResponse,
	type FuelResponse,
	type ModuleFull,
} from "./energyTypes";
import {
	useCreateElectricityEntryMutation,
	useCreateFuelEntryMutation,
	useDeleteElectricityEntryMutation,
	useDeleteFuelEntryMutation,
	useGetElectricityEntriesQuery,
	useGetFuelEntriesQuery,
} from "../../../../app/features/api/modules/energyApiSlice";
import { FEModules } from "../../../../utils/moduleList";
import { isEqual } from 'lodash';
import { mergeLocalAndServerChanges } from "../inputs/utils";
import { MAXELECTRICITIES, MAXFUELS } from "./energy";
import GenericModule from "../GenericModule";
import GenericOptionalSubModule from "../GenericOptionalSubmodule";
import { useVCEnergyHandler } from "../../../../utils/useVCEnergyHandler";

interface EnergyProps {
	isElectricityLoading: boolean;
	isFuelLoading: boolean;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}

const EnergyTierOne = ({
	isElectricityLoading,
	isFuelLoading,
	setInitValues,
	...moduleProps
}: ModuleProps & EnergyProps,
) => {
	const { values } = useFormikContext<ModuleFull>()
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const { isReadOnly } = usePermissions();
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	useVCEnergyHandler({ listInputName: "fuels", energyTypeInputName: "fuel_type" })

	const parentId = useMemo(() => moduleList.find((m) => m.id === FEModules.Energy)?.uniqueId, [moduleList]);
	const [deleteElectricity, { isLoading: isElectricityDelLoading }] = useDeleteElectricityEntryMutation();
	const [deleteFuel, { isLoading: isFuelDelLoading }] = useDeleteFuelEntryMutation();
	const [addElectricity, { isLoading: isAddElectricityLoading }] = useCreateElectricityEntryMutation();
	const [addFuel, { isLoading: isAddFuelLoading }] = useCreateFuelEntryMutation();

	const { data: electricityData } = useGetElectricityEntriesQuery({ activityId: activeActivityId ?? 0 });
	const { data: fuelData } = useGetFuelEntriesQuery({ activityId: activeActivityId ?? 0 });

	const lists = useMemo(() => {
		const isElectricityUpdated = !isEqual(values.electricities, electricityData);
		const isFuelUpdated = !isEqual(values.fuels, fuelData);

		return {
			electricities: isElectricityUpdated
				? mergeLocalAndServerChanges({
					currentChangesList: values.electricities,
					incomingList: electricityData ?? [],
				})
				: values.electricities,
			fuels: isFuelUpdated
				? mergeLocalAndServerChanges({
					currentChangesList: values.fuels,
					incomingList: fuelData ?? [],
				})
				: values.fuels,
		};
	}, [electricityData, fuelData]);

	useEffect(() => {
		if (lists.electricities || lists.fuels) {
			setInitValues((cur) => ({
				...cur,
				electricities: lists.electricities,
				fuels: lists.fuels,
			}));
		}
	}, [lists, setInitValues]);

	//delete functions
	const handleRemoveElectricity = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		if (id) {
			remove(index);
			deleteElectricity({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
		}
	};

	const handleRemoveFuel = (
		remove: <T>(index: number) => T | undefined,
		index: number,
		id?: number | null,
	) => {
		if (id) {
			remove(index);
			deleteFuel({
				id,
				activityId: activeActivityId ?? 0,
				parent: parentId ?? 0,
			});
		}
	};

	//create functions
	const handleAddElectricity = async () => {
		try {
			if (values.electricities.length < MAXELECTRICITIES)
				await addElectricity({
					parent: parentId,
					activityId: activeActivityId ?? 0,
				}).unwrap();
		} catch (err) {
			console.error(err);
		}
	};
	const handleAddFuel = async () => {
		try {
			if (values.fuels.length < MAXFUELS) await addFuel({
				parent: parentId,
				activityId: activeActivityId ?? 0,
			}).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	const genericDeleteHandler = useCallback((name: string) => {
		switch (name) {
			case EnergyModules.Electricity:
				return handleRemoveElectricity;
			case EnergyModules.Fuel:
				return handleRemoveFuel;
			default:
				return () => { };
		}
	}, [handleRemoveElectricity, handleRemoveFuel]);

	const genericAddHandler = useCallback((name: string) => {
		switch (name) {
			case EnergyModules.Electricity:
				return handleAddElectricity;
			case EnergyModules.Fuel:
				return handleAddFuel;
			default:
				return () => { };
		}
	}, [handleAddElectricity, handleAddFuel]);

	const genericValuesHandler = useCallback((name: string) => {
		switch (name) {
			case EnergyModules.Electricity:
				return values.electricities;
			case EnergyModules.Fuel:
				return values.fuels;
			default:
				return [];
		}
	}, [values.electricities, values.fuels]);


	const genericFieldArrayName = useCallback((name: string) => {
		switch (name) {
			case EnergyModules.Electricity:
				return "electricities";
			case EnergyModules.Fuel:
				return "fuels";
			default:
				return "";
		}
	}, []);


	const translationKeySubmodule = (name: EnergyModules): TranslationKey => {
		switch (name) {
			case EnergyModules.Electricity:
				return "module.add_new_electricity";
			case EnergyModules.Fuel:
				return "module.add_new_fuel";
		}
	}

	const isOptionalLoading = useCallback((name: EnergyModules) => name === EnergyModules.Fuel ? isFuelLoading : isElectricityLoading, [isFuelLoading, isElectricityLoading]);
	const isCreateLoading = useCallback((name: EnergyModules) => name === EnergyModules.Fuel ? isAddFuelLoading : isAddElectricityLoading, [isAddFuelLoading, isAddElectricityLoading]);
	const isDeleteLoading = useCallback((name: EnergyModules) => name === EnergyModules.Fuel ? isFuelDelLoading : isElectricityDelLoading, [isFuelDelLoading, isElectricityDelLoading]);


	return (
		<GenericModule {...moduleProps} disableOptionals>
			{
				() => (
					<>
						{moduleProps.moduleSchema.optionalSubmodules?.map((subModule, index) => {
							return (
								<GenericOptionalSubModule<FuelResponse | ElectricityResponse>
									key={`${subModule.name}-${index}`}
									optionalSchema={subModule}
									values={genericValuesHandler(subModule.name)}
									createHandler={genericAddHandler(subModule.name)}
									removeHandler={genericDeleteHandler(subModule.name)}
									fieldArrayName={genericFieldArrayName(subModule.name)}
									addLabel={translationKeySubmodule(subModule.name as EnergyModules)}
									isReadOnly={isReadOnly}
									isOptionalLoading={isOptionalLoading(subModule.name as EnergyModules)}
									isCreateLoading={isCreateLoading(subModule.name as EnergyModules)}
									isDeleteLoading={isDeleteLoading(subModule.name as EnergyModules)}
								/>
							)
						})}
					</>
				)
			}
		</GenericModule>
	)
};

export default EnergyTierOne;