import Button from "./Button";
import Spinner from "../spinner/Spinner";
import { createElement, useMemo } from "react";
import { IconType } from "react-icons";
import { MdArrowForward } from "react-icons/md";
import { TranslationKey } from "../../types/modulesInterfaces";
import TranslatableText from "../translations/TranslatableText";

const ModuleButton = ({
	handleClick,
	labelKey,
	buttonType = "submit",
	icon,
	disabled = false,
	isLoading = false,
	outlined = false,
	styleType = "primary",
	translationParams,
}: {
	handleClick?: () => void;
	labelKey: TranslationKey;
	buttonType?: "reset" | "button" | "submit";
	icon?: IconType;
	disabled?: boolean;
	isLoading?: boolean;
	outlined?: boolean;
	styleType?: "primary" | "secondary";
	translationParams?: { [key: string]: string | number };
}) => {
	const styling = useMemo(() => {
		const isSecondary = styleType === "secondary";
		return {
			iconBgColor: isSecondary ? "bg-tert-de" : "bg-quat-br",
			iconColor: isSecondary ? "var(--dark)" : "var(--white)",
			labelBgColor: isSecondary ? "bg-tert-br" : outlined ? "bg-transp" : "bg-quat-de",
			labelColor: isSecondary ? "var(--dark)" : outlined ? "var(--quaternary-deep)" : "var(--white)",
		};
	}, [styleType, outlined]);

	const buttonClasses = `btn-save w-fit ${outlined ? "outlined-button" : ""} ${disabled ? "op-8" : ""}`;

	return (
		<Button
			type={buttonType}
			classes={buttonClasses}
			onClick={handleClick}
			disabled={disabled || isLoading}
		>
			<div className={`${styling.iconBgColor} save-icon`}>
				{isLoading ? (
					<Spinner size={36} padding="0.5rem" isBlack={styleType === "secondary"} />
				) : icon ? (
					createElement(icon, { size: 20, color: styling.iconColor })
				) : (
					<MdArrowForward size={20} color={styling.iconColor} />
				)}
			</div>
			<span className={`label ff-medium ${styling.labelBgColor}`} style={{ color: styling.labelColor }}>
				<TranslatableText translationKey={labelKey} translationParams={translationParams} />
			</span>
		</Button>
	);
};

export default ModuleButton;
