import { motion } from "framer-motion";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import Dropzone from "./components/Dropzone";
import ProjectAttachments from "./components/ProjectAttachments";

const AttachmentsAction = () => {
  const { tabsOpen } = useAppSelector(selectCurrentBuilder);

  return (
    <motion.section
      className="bg-gray w-100 h-100 d-flex f-column p-2"
      initial={{ width: 0 }}
      animate={{ width: tabsOpen ? "inherit" : 0 }}
      exit={{ width: 0 }}
    >
      <Dropzone />
      <ProjectAttachments />
    </motion.section>
  );
};

export default AttachmentsAction;

