import type { ElectricityResponse, FuelResponse, ElectricityDefaults, FuelDefaults } from "../../../../pages/activityBuilder/modules/energy/energyTypes";
import { convertEmptyStringsToNull, convertOutgoingPercentage, convertIncomingPercentage } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { EntryCreatePayload, EntryDeletePayload, EntryUpdatePayload, ParentUpdatePayload, ParentResponse, ParentPayload } from "../../../../types/modulesInterfaces";
import { apiSlice } from "../apiSlice";

const parentEndpoint = "energies/"
const electricityEntryEndpoint = "electricities/"
const fuelEntryEndpoint = "fuels/"

export const moduleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEnergies: builder.query<ParentResponse, { activityId: number }>({
      query: ({ activityId }) => `${parentEndpoint}?activity=${activityId}`,
      transformResponse: (response: ParentResponse[]) => {
        return response[0]
      },
      providesTags: (_results, _error, { activityId }) => [{ type: 'Energies', id: activityId }]
    }),
    updateEnergies: builder.mutation<ParentResponse, ParentUpdatePayload<ParentPayload>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `${parentEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'Energies', id: activityId },
        { type: "ModulesStatuses", id: activityId },
        { type: 'ModuleResults', id: body?.id ? `energies-${body.id}` : undefined },
        { type: "ModuleHistory", id: body?.id ? `energies-${body.id}` : undefined },
      ]
    }),
    getElectricityEntries: builder.query<ElectricityResponse[], { activityId: number }>({
      query: ({ activityId }) => `${electricityEntryEndpoint}?activity=${activityId}`,
      transformResponse: (response: ElectricityResponse[]) => {
        response.forEach(entry => convertIncomingPercentage(entry, ["transmission_loss_t2"]));
        return response.sort((a, b) => a.id - b.id)
      },
      providesTags: (_results, _error, { activityId }) => [{ type: 'Electricities', id: activityId }]
    }),
    updateElectricityEntry: builder.mutation<ElectricityResponse, EntryUpdatePayload<ElectricityResponse>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        convertOutgoingPercentage(rest, ["transmission_loss_t2"]);
        return { url: `${electricityEntryEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'Electricities', id: activityId },
        { type: "ElectricitiesDefaults", id: body.id ? body.id : undefined },
        { type: "ModulesStatuses", id: activityId },
      ]
    }),
    createElectricityEntry: builder.mutation<ElectricityResponse, EntryCreatePayload>({
      query: ({ activityId, parent }) => ({ url: `${electricityEntryEndpoint}`, method: 'POST', body: { activity: activityId, parent } }),
      invalidatesTags: (_results, _error, { activityId, parent }) => [
        { type: 'Electricities', id: activityId },
        { type: 'ModuleResults', id: parent ? `energies-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `energies-${parent}` : undefined },
        { type: "ModulesStatuses", id: activityId },
      ]
    }),
    deleteElectricityEntry: builder.mutation<void, EntryDeletePayload>({
      query: ({ id }) => ({ url: `${electricityEntryEndpoint}${id}/`, method: 'DELETE' }),
      invalidatesTags: (_results, _error, { activityId, parent, id }) => [
        { type: 'Electricities', id: activityId },
        { type: 'ModuleResults', id: parent ? `energies-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `energies-${parent}` : undefined },
        { type: "ElectricitiesDefaults", id: id },
        { type: "ModulesStatuses", id: activityId },
      ]
    }),
    getElectricityEntryDefaults: builder.query<ElectricityDefaults, number>({
      query: (id) => `${electricityEntryEndpoint}${id}/defaults/`,
      providesTags: (_results, _error, id) => [
        { type: "ElectricitiesDefaults", id: id },
      ],
    }),

    getFuelEntries: builder.query<FuelResponse[], { activityId: number }>({
      query: ({ activityId }) => `${fuelEntryEndpoint}?activity=${activityId}`,
      transformResponse: (response: FuelResponse[]) => { return response.sort((a, b) => a.id - b.id) },
      providesTags: (_results, _error, { activityId }) => [{ type: 'Fuels', id: activityId }]
    }),
    updateFuelEntry: builder.mutation<FuelResponse, EntryUpdatePayload<FuelResponse>>({
      query: ({ body }) => {
        const { id, ...rest } = body
        convertEmptyStringsToNull(rest)
        return { url: `${fuelEntryEndpoint}${id}/`, method: 'PUT', body: rest }
      },
      invalidatesTags: (_results, _error, { activityId, body }) => [
        { type: 'Fuels', id: activityId },
        { type: "FuelsDefaults", id: body.id },
        { type: "ModulesStatuses", id: activityId },
      ]
    }),
    createFuelEntry: builder.mutation<FuelResponse, EntryCreatePayload>({
      query: ({ activityId, parent }) => ({ url: `${fuelEntryEndpoint}`, method: 'POST', body: { activity: activityId, parent } }),
      invalidatesTags: (_results, _error, { activityId, parent }) => [
        { type: 'Fuels', id: activityId },
        { type: 'ModuleResults', id: parent ? `energies-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `energies-${parent}` : undefined },
        { type: "ModulesStatuses", id: activityId },
      ]
    }),
    deleteFuelEntry: builder.mutation<void, EntryDeletePayload>({
      query: ({ id }) => ({ url: `${fuelEntryEndpoint}${id}/`, method: 'DELETE' }),
      invalidatesTags: (_results, _error, { activityId, parent, id }) => [
        { type: 'Fuels', id: activityId },
        { type: 'ModuleResults', id: parent ? `energies-${parent}` : undefined },
        { type: "ModuleHistory", id: parent ? `energies-${parent}` : undefined },
        { type: "FuelsDefaults", id: id },
        { type: "ModulesStatuses", id: activityId },
      ]
    }),
    getFuelEntryDefaults: builder.query<FuelDefaults, number>({
      query: (id) => `${fuelEntryEndpoint}${id}/defaults/`,
      providesTags: (_results, _error, id) => [
        { type: "FuelsDefaults", id: id },
      ],
    }),
  })
})

export const {
  useGetEnergiesQuery,
  useUpdateEnergiesMutation,
  useGetElectricityEntriesQuery,
  useUpdateElectricityEntryMutation,
  useCreateElectricityEntryMutation,
  useDeleteElectricityEntryMutation,
  useGetElectricityEntryDefaultsQuery,
  useGetFuelEntriesQuery,
  useUpdateFuelEntryMutation,
  useCreateFuelEntryMutation,
  useDeleteFuelEntryMutation,
  useGetFuelEntryDefaultsQuery
} = moduleApiSlice;