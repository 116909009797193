import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import { BiCloudUpload, BiMinus, BiPlus } from "react-icons/bi";
import { useDropzone } from "react-dropzone";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useFileUpload } from "../hooks/useFileUpload";
import { useGetAppParametersQuery } from "../../../../app/features/other/genericApiSlice";
import ModuleButton from "../../../button/ModuleButton";
import TranslatableText from "../../../translations/TranslatableText";
import FileList from "./FileList";

const Dropzone = () => {
  const { project } = useAppSelector(selectCurrentProject);
  const {
    files,
    uploadErrorMsg,
    isLoading,
    errorMsg,
    handleAddFiles,
    handleDelete,
    handleClear,
    handleUpload
  } = useFileUpload({ projectId: project?.id ?? 0 });
  const { data: maxAttachmentsSizeParam } = useGetAppParametersQuery(undefined, {
    selectFromResult: (res) => ({ data: res?.data?.find(param => param.name === "project_uploads_max_file_size_mb") ?? null })
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleAddFiles
  });

  const dropzoneContent = useMemo(() => (
    <>
      <BiCloudUpload size={36} color="#F9BD24" />
      <TranslatableText translationKey="main.upload_files" />
      <div className="text-gray fs-12">
        {isDragActive
          ? <TranslatableText translationKey="main.drop_files" />
          : <TranslatableText translationKey="main.drag_and_drop" />
        }
      </div>
    </>
  ), [isDragActive]);

  return (
    <div style={{ maxWidth: "600px" }}>
      <div className="dropzone-container clickable" {...getRootProps()}>
        <input {...getInputProps()} />
        <svg className={`dropzone-border ${isDragActive ? 'animated' : ''}`}>
          <rect
            x="1"
            y="1"
            width="98%"
            height="98%"
            rx="8"
          />
        </svg>
        {dropzoneContent}
      </div>

      <span>
        <TranslatableText className="text-gray fs-12" translationKey="main.max_project_size" />
        <span className="fs-12">{maxAttachmentsSizeParam?.value} {maxAttachmentsSizeParam?.unit}</span>
      </span>

      {(uploadErrorMsg || errorMsg) && (
        <div className="pt-1">
          <span className="text-error">{uploadErrorMsg ?? errorMsg}</span>
        </div>
      )}

      <AnimatePresence>
        <FileList files={files} onDelete={handleDelete} />
        <motion.div
          initial={{ opacity: 0, height: 0, paddingTop: 0 }}
          animate={{
            opacity: files.length > 0 ? 1 : 0,
            height: files.length > 0 ? "auto" : 0,
            paddingTop: files.length > 0 ? "0.5rem" : 0
          }}
          transition={{ duration: 0.5 }}
          className="w-100 d-flex justify-content-end gap-8"
        >
          <ModuleButton
            outlined
            labelKey="main.clear"
            icon={BiMinus}
            disabled={files.length === 0}
            handleClick={handleClear}
          />
          <ModuleButton
            labelKey="main.upload"
            isLoading={isLoading}
            icon={BiPlus}
            disabled={files.length === 0}
            handleClick={handleUpload}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Dropzone; 