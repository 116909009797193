import { useState, useCallback } from 'react';
import { useUploadProjectAttachmentMutation } from "../../../../app/features/project/projectApiSlice";
import useErrorMessage from "../../../../utils/useErrorMessage";

interface UseFileUploadProps {
  projectId: number;
}

interface UseFileUploadReturn {
  files: File[];
  uploadErrorMsg: string | null;
  isLoading: boolean;
  errorMsg: string | null;
  handleAddFiles: (acceptedFiles: File[]) => void;
  handleDelete: (file: File) => void;
  handleClear: () => void;
  handleUpload: () => Promise<void>;
}

export const useFileUpload = ({ projectId }: UseFileUploadProps): UseFileUploadReturn => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadErrorMsg, setUploadErrorMsg] = useState<string | null>(null);
  const [uploadProjectAttachment, { isLoading, isError, error }] = useUploadProjectAttachmentMutation();
  const { errorMsg } = useErrorMessage({ isError, error });

  const handleAddFiles = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => setUploadErrorMsg('file reading was aborted');
      reader.onerror = () => setUploadErrorMsg('file reading has failed');
      reader.onload = () => {
        setFiles(prevFiles => [...prevFiles, file]);
        setUploadErrorMsg(null);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const handleDelete = useCallback((file: File) => {
    setFiles(prevFiles => prevFiles.filter(f => f !== file));
  }, []);

  const handleClear = useCallback(() => {
    setFiles([]);
  }, []);

  const handleUpload = async () => {
    try {
      await Promise.all(
        files.map(file =>
          uploadProjectAttachment({
            project: projectId,
            file
          }).unwrap()
        )
      );
      setFiles([]);
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadErrorMsg('Failed to upload files');
    }
  };

  return {
    files,
    uploadErrorMsg,
    isLoading,
    errorMsg,
    handleAddFiles,
    handleDelete,
    handleClear,
    handleUpload
  };
}; 