import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { BiSelectMultiple, BiDownload, BiTrash } from "react-icons/bi";
import { useAttachmentManager } from "../hooks/useAttachmentManager";
import { useMemo } from "react";
import ModuleButton from "../../../button/ModuleButton";
import TranslatableText from "../../../translations/TranslatableText";
import FadeAnimation from "../../../animations/FadeAnimation";
import Skeleton from "react-loading-skeleton";
import AttachmentCard from "./AttachmentCard";
import ConfirmationModal from "../../../modal/ConfirmationModal";

const ProjectAttachments = () => {
  const { project } = useAppSelector(selectCurrentProject);
  const {
    attachments,
    isLoading,
    isFetching,
    errorMsg,
    selectedAttachments,
    isDeleteDialogOpen,
    isDeleteMultipleDialogOpen,
    isMultipleDownloadLoading,
    isMultipleDeleteLoading,
    isDeleteLoading,
    handleDeleteConfirm,
    handleDeleteCancel,
    handleDelete,
    handleDownload,
    handleSelect,
    handleUnselect,
    handleSelectAll,
    handleMultipleDownloadClick,
    handleDeleteMultipleConfirm,
    handleDeleteMultipleCancel,
    handleDeleteMultipleClick
  } = useAttachmentManager({
    projectId: project?.id ?? 0,
    projectName: project?.name
  });

  const actionButtons = useMemo(() => (
    <div className="d-flex gap-8 mb-2">
      <ModuleButton
        handleClick={handleSelectAll}
        labelKey={
          attachments && selectedAttachments.length === attachments.length
            ? "main.unselect_all"
            : "main.select_all"
        }
        icon={BiSelectMultiple}
        styleType="secondary"
        buttonType="button"
      />
      <FadeAnimation isShown={selectedAttachments.length > 0} duration={0.2}>
        <div className="d-flex gap-8">
          <ModuleButton
            handleClick={handleMultipleDownloadClick}
            labelKey="main.download_selected"
            translationParams={{ count: selectedAttachments.length }}
            icon={BiDownload}
            styleType="secondary"
            buttonType="button"
            isLoading={isMultipleDownloadLoading}
          />
          <ModuleButton
            handleClick={handleDeleteMultipleClick}
            labelKey="main.delete_selected"
            translationParams={{ count: selectedAttachments.length }}
            icon={BiTrash}
            styleType="secondary"
            buttonType="button"
            isLoading={isMultipleDeleteLoading}
          />
        </div>
      </FadeAnimation>
    </div>
  ), [
    attachments,
    selectedAttachments.length,
    isMultipleDownloadLoading,
    isMultipleDeleteLoading,
    handleSelectAll,
    handleMultipleDownloadClick,
    handleDeleteMultipleClick
  ]);

  const renderContent = () => {
    if (isLoading || isFetching) {
      return Array(3)
        .fill(0)
        .map((_, i) => (
          <FadeAnimation key={`attachment-skeleton-${i}`} delay={i * 0.1} isShown>
            <Skeleton height={45} borderRadius={8} className="mt-1" baseColor="var(--light-gray)" highlightColor="var(--gray)" />
          </FadeAnimation>
        ));
    }

    if (!attachments?.length) {
      return (
        <TranslatableText
          className="text-darker-gray fs-14"
          translationKey="main.no_attachments"
        />
      );
    }

    if (errorMsg.length > 0) {
      return <span className="text-error">{errorMsg}</span>;
    }

    return attachments.map((attachment, i) => (
      <FadeAnimation
        key={`${attachment.id}-${i}`}
        delay={i * 0.1}
        className="pt-1 card-container"
        isShown
      >
        {isDeleteLoading ? (
          <Skeleton
            height={20}
            borderRadius={8}
            key={`delete-skeleton-${attachment.id}`}
          />
        ) : (
          <AttachmentCard
            key={attachment.id}
            attachment={attachment}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            setSelected={handleSelect}
            setUnselected={handleUnselect}
            isSelected={selectedAttachments.includes(attachment.id)}
          />
        )}
      </FadeAnimation>
    ));
  };

  return (
    <div className="pt-2">
      <div className="pos-relative mb-2">
        <h2 className="fs-14 ff-medium-ext module-header w-fit">
          <TranslatableText translationKey="main.project_attachments" />
        </h2>
      </div>

      <ConfirmationModal
        open={isDeleteDialogOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        message={<TranslatableText translationKey="main.delete_attachment_confirmation" />}
        headerText="main.delete_attachment"
        isLoading={isDeleteLoading}
      />

      <ConfirmationModal
        open={isDeleteMultipleDialogOpen}
        onCancel={handleDeleteMultipleCancel}
        onConfirm={handleDeleteMultipleConfirm}
        message={
          <TranslatableText
            translationKey="main.delete_multiple_attachments_confirmation"
            translationParams={{ count: selectedAttachments.length }}
          />
        }
        headerText="main.delete_attachments"
        isLoading={isDeleteLoading}
      />

      {actionButtons}
      {project && renderContent()}
    </div>
  );
};

export default ProjectAttachments; 