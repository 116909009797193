import { apiSlice } from "./apiSlice";

export interface HealthCheckResponse {
  is_under_maintenance: boolean;
  maintenance_end_time: string;
  maintenance_message: string;
}

export const healthApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getHealthCheck: builder.query<HealthCheckResponse, void>({
			query: () => "/health/",
		}),
	}),
});

export const { useGetHealthCheckQuery } = healthApiSlice;