import { EventObject, StateValue } from "xstate";
import {
	Help,
	InputGroup,
	Note,
	Option,
	TranslationKey,
} from "../../../../types/modulesInterfaces";
import { FEModules } from "../../../../utils/moduleList";

export enum FormStates {
	initInfo = "initInfo",
	isOrganicSoil = "isOrganicSoil",
	mainActivityFocus = "mainActivityFocus",
	hectares = "hectares",
	previousLandUse = "previousLandUse",
	areaWithoutProject = "areaWithoutProject",
	livestockFeedOrigin = "livestockFeedOrigin",
	fishFeedOrigin = "fishFeedOrigin",
	fisheriesSize = "fisheriesSize",
	includesInputs = "includesInputs",
	usesEnergy = "usesEnergy",
	usesValueChain = "usesValueChain",
	finish = "finish",
}

export interface SubmodulePlus {
	name: TranslationKey;
	inputGroups: InputGroup[];
	initialValues: any | (() => any);
	step: FormStates;
	validationSchema?: any;
	help?: Help;
	comment?: Comment;
	note?: Note;
}

export interface AllPossibleSetupAssistantForm {
	activityTitle: string /* __ */;
	activityCost: number;
	duration: number /* __ */;
	changeRate: number;
	climate: number /* __ */;
	moisture: number;
	typeOfSoil: number /* __ */;
	startYear: number /* __ */;
	last_year_of_accounting: number; 
	isOrganicSoil: boolean;
	mainActivityFocus: number;
	hectares: number /* __ */;
	previousLandUse: number;
	areaWithoutProject: number;
	withoutProjectLandUse: number;
	livestockFeedOrigin: number;
	fishFeedOrigin: number;
	fertilizers: boolean;
	energy: boolean;
	irrigation: boolean;
	usesEnergy: boolean;
	fisheriesSize: number;
	packaging: boolean;
	transport: boolean;
	storage: boolean;
	processing: boolean;
}

export type SetupAssistantForm = Partial<AllPossibleSetupAssistantForm>;

export interface ContextModules {
	module: FEModules;
	generatedBy: FormStates;
}

export interface Context {
	schema: SubmodulePlus[];
	values: SetupAssistantForm;
	modules: ContextModules[]; //TODO change this to ids
}

export interface SpecialOptions extends Option {
	name: TranslationKey;
	derivedModules?: FEModules[]; //modules that are added to the context when this option is selected
	derivedStates?: FormStates; //helps the state machine chose the next state when there's more than one branch
}

export enum FormEvents {
	NEXT = "NEXT",
	SKIP = "SKIP",
	BACK = "BACK",
	PUSH_MODULES = "PUSH_MODULES",
	SET_INIT_VALUES = "SET_INIT_VALUES",
}

type EventTypeSchema =
	| FormEvents.NEXT
	| FormEvents.BACK
	| FormEvents.SKIP
	| FormEvents.PUSH_MODULES
	| FormEvents.SET_INIT_VALUES;

export interface FormMachineEvents extends EventObject {
	type: EventTypeSchema;
	next?: FormStates;
	prev?: StateValue;
	values?: SetupAssistantForm;
	modules?: FEModules[];
	generatedBy?: FormStates;
	schemaStep?: SubmodulePlus;
}
