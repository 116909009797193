import { useNavigate } from "react-router-dom";
import { SetupAssistantForm } from "../project/activities/setupAssistant/setupAssistant.types";
import { useEffect, useState } from "react";
import { useHandleActivityState } from "../activityBuilder/hooks/useHandleActivityState";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import { useAppSelector } from "../../app/hooks";
import SetupAssistantFinish from "../project/activities/setupAssistant/SetupAssistantFinish";

const EditModules = () => {
  const navigate = useNavigate()
  const handleReturn = () => navigate(-1)
  const [values, setValues] = useState<SetupAssistantForm>({})
  const { activity } = useHandleActivityState();
  const { project } = useAppSelector(selectCurrentProject)

  useEffect(() => {
    if (activity)
      setValues({
        activityTitle: activity.name,
        changeRate: activity.change_rate?.id,
        climate: activity.climate_t2?.id ?? project?.climate?.id,
        moisture: activity.moisture_t2?.id ?? project?.moisture?.id,
        typeOfSoil: activity.soil_type_t2?.id ?? project?.soil_type?.id,
        startYear: activity.start_year_t2 ?? project?.start_year_of_activities,
        duration: activity.duration_t2 ?? project?.implementation_years,
        activityCost: activity.cost ?? undefined,
      })
  }, [activity])

  return (
    <section>
      <div className="module-group">
        <div className="pos-relative mb-2 pb-1">
          <h2 className="fs-14 ff-medium-ext module-header w-fit">
            Edit Activity Modules
          </h2>
          <div className="module-number header-number ff-light-ext">{activity?.id}</div>
        </div>

        <div className="d-flex f-column" style={{ maxWidth: "450px" }}>
          <SetupAssistantFinish modules={[]} onReturn={handleReturn} values={values} />
        </div>
      </div>
    </section>
  );
}

export default EditModules;
