import { apiSlice } from "../api/apiSlice";

interface AppParameter {
  id: number;
  name: string;
  value: number;
  unit: string;
}

export const genericApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAppParameters: builder.query<AppParameter[], void>({
			query: () => "parameters/",
		}),
	}),
});

export const { useGetAppParametersQuery } = genericApiSlice