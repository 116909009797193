import {
	clearProgressiveModules,
	selectCurrentModules,
} from "../../../app/features/setupAssistant/setupAssistantSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MapIframe from "../../../pages/project/map/MapIframe";
import ClimateIdentifier from "./ClimateIdentifier";
import TranslatableText from "../../translations/TranslatableText";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../tooltip/Tooltip"; // Assuming this is the Tooltip component
import { BiQuestionMark } from "react-icons/bi";
import { motion } from "framer-motion";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";

const MapAction = ({ style }: { style?: React.CSSProperties }) => {
	const dispatch = useAppDispatch();
	const modules = useAppSelector(selectCurrentModules); //these are the progressive modules
	const location = useLocation();
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);

	useEffect(() => {
		if (!location.pathname.endsWith("new-activity") && modules.length)
			dispatch(clearProgressiveModules());
	}, [location.pathname]);

	return (
		<motion.section
			className="w-100 h-100 py-2 px-3 bg-gray"
			initial={{ width: 0 }}
			animate={{ width: tabsOpen ? "inherit" : 0 }}
			exit={{ width: 0 }}
			style={style}
		>
			<div className="pos-relative mb-2 pb-1">
				<h2 className="fs-14 ff-medium-ext module-header w-fit">
					<TranslatableText translationKey="main.project_map" />
				</h2>
			</div>

			<MapGuide />

			<div style={{ position: "relative", zIndex: 1 }}>
				<MapIframe />
				<div
					style={{
						backgroundColor: "#e8e8e8",
						color: "#e8e8e8",
						width: "100%",
						paddingTop: 10,
						border: "none",
						position: "absolute",
						bottom: -9,
						zIndex: 10000,
					}}
				>
					Bye leaflet
				</div>
			</div>

			<div className="w-50">
				<ClimateIdentifier />
			</div>
		</motion.section>
	);
};

const MapGuide = () => {
	const paramsComponents = [
		<span style={{ fontWeight: 'bold' }} />
	]

	return (
		<Tooltip placement="bottom-start">
			<TooltipTrigger className="d-flex mb-2">
				<motion.div className="d-flex align-items-end pb-1" style={{ borderBottom: "1px solid var(--gray)" }} whileHover={{ backgroundColor: "var(--gray)" }}>
					<TranslatableText translationKey="project.map_guide" className="text-gray ff-medium-cond" style={{ color: "var(--dark)", fontSize: 16 }} />
					<BiQuestionMark
						size={20}
						color="#50A2A7" />
				</motion.div>
			</TooltipTrigger>
			<TooltipContent>
				<div className="map-guide-tooltip-content" style={{ maxWidth: 700 }}>
					<h3 className="fs-18 ff-medium-cond"><TranslatableText translationKey="map_guide.step_title" /></h3>
					<h4 className="ff-medium-cond pt-2 fs-18"><TranslatableText translationKey="map_guide.access_the_analysis_tool" /></h4>
					<ul className="fs-14 ps-2">
						<li><TranslatableText translationKey="map_guide.step_instruction_1" paramsComponents={paramsComponents} /></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_2" paramsComponents={paramsComponents} 	/></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_3" paramsComponents={paramsComponents} /></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_4" paramsComponents={paramsComponents} /></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_5" paramsComponents={paramsComponents} /></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_6" paramsComponents={paramsComponents} /></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_7" paramsComponents={paramsComponents} /></li>
						<li><TranslatableText translationKey="map_guide.step_instruction_8" paramsComponents={paramsComponents} /></li>
					</ul>

					<p className="ff-medium-cond pt-2 fs-18"><TranslatableText translationKey="map_guide.download_instruction" /></p>
				</div>
			</TooltipContent>
		</Tooltip>
	)
}

export default MapAction;
