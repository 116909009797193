import { createElement, useMemo } from "react";
import { motion } from "framer-motion";
import { capitalizeEach } from "../../utils/helperFunctions";
import { useAppDispatch, useAppSelector, useCustomTranslation } from "../../app/hooks";
import {
	selectCurrentBuilder,
	toggleTabsOpen,
	setActiveAction,
} from "../../app/features/builder/builderSlice";

import Button from "../../components/button/Button";
import { RiArrowRightFill } from "react-icons/ri";
import { ActionsInfo } from "../../utils/useTabsHook";
import type { IconType } from "react-icons";
import { Actions } from "../../types/interfaces";
import { TranslationKey } from "../../types/modulesInterfaces";
import TranslatableText from "../translations/TranslatableText";

const BuilderActionsTabs = () => {
	const { actionsList, activeAction } = useAppSelector(selectCurrentBuilder);

	const activeColor = useMemo(() => {
		return ActionsInfo[activeAction]?.color ?? "#fff";
	}, [activeAction]);

	return (
		<motion.div
			className="builder-actions-tabs"
			animate={activeColor ? { borderBottom: `4px solid ${activeColor}` } : {}}
		>
			<HideAndShowButton />
			{actionsList?.map((tab) => (
				<Tab
					{...ActionsInfo[tab]}
					id={tab}
					isActive={tab === activeAction}
					key={tab}
				/>
			))}
		</motion.div>
	);
};

const HideAndShowButton = () => {
	const { tabsOpen } = useAppSelector(selectCurrentBuilder);
	const dispatch = useAppDispatch();

	return (
		<Button
			classes="bat-btn-hide bg-transparent"
			onClick={() => dispatch(toggleTabsOpen())}
		>
			<TranslatableText style={{ marginRight: "2px" }} translationKey={tabsOpen ? "module.hide" : "module.show_menu"} />
			<motion.div
				animate={{
					rotate: tabsOpen ? 0 : -540,
				}}
				transition={{ ease: "easeOut", duration: 0.5 }}
			>
				<RiArrowRightFill size={20} />
			</motion.div>
		</Button>
	);
};

const Tab = ({
	id,
	label,
	icon,
	color,
	isActive,
}: {
	id: Actions;
	label: TranslationKey;
	icon: IconType;
	color: string;
	isActive: boolean;
}) => {
	const { tabsOpen, activeAction } = useAppSelector(selectCurrentBuilder);
	const dispatch = useAppDispatch();
	const translatedLabel = useCustomTranslation(label);

	const bgColor = isActive ? color : "var(--white)";
	const contentColor = isActive && activeAction !== Actions.Comments ? "var(--white)" : "var(--icon)";

	const tabClick = () => {
		dispatch(setActiveAction(id));
		if (!tabsOpen) dispatch(toggleTabsOpen());
	};

	const iconElement = useMemo(() => createElement(icon, {
		width: 20,
		color: contentColor,
		fill: isActive && activeAction !== Actions.Comments ? "white" : "",
	}),
		[isActive, contentColor, icon],
	);

	return (
		<motion.div
			className={`action-tab clickable ${!tabsOpen && "action-tab-closed"}`}
			onClick={tabClick}
			animate={{ backgroundColor: bgColor }}
			whileHover={!isActive ? { backgroundColor: "var(--light-gray)" } : {}}
		>
			<div>
				{iconElement}
			</div>

			{tabsOpen ? (
				<motion.span
					className="ps-1 lh-1 fs-13 no-wrap"
					animate={{ color: contentColor }}
				>
					{translatedLabel ? capitalizeEach(translatedLabel) : capitalizeEach(label)}
				</motion.span>
			) : null}
		</motion.div>
	);
};

export default BuilderActionsTabs;
