import React from "react";
import AlertModal from "./AlertModal";
import Spinner from "../spinner/Spinner";
import TranslatableText from "../translations/TranslatableText";
import { ButtonProps } from "../button/Button";
import { TranslationKey } from "../../types/modulesInterfaces";

interface ConfirmationModalProps {
	open: boolean;
	onCancel?: () => void;
	onConfirm: () => void;
	message: string | React.ReactNode;
	size?: "sm" | "md" | "lg" | "auto" | "xlg";
	headerText?: TranslationKey;
	isLoading?: boolean
	showCancel?: boolean,
	confirmationMessage?: TranslationKey,
	cancelButtonMessage?: TranslationKey
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	open,
	onCancel,
	onConfirm,
	message,
	size = "sm",
	headerText,
	isLoading,
	showCancel = true,
	confirmationMessage,
	cancelButtonMessage,
}) => {

	const cancelButton:ButtonProps = {
		onClick: onCancel,
		children: <TranslatableText translationKey={cancelButtonMessage ?? "main.cancel"} />,
		type: "button",
	}
	const confirmButton:ButtonProps = {
		onClick: onConfirm,
		children: isLoading ? <Spinner size={16} isBlack /> : <TranslatableText translationKey={confirmationMessage ?? "main.proceed"} />,
		type: "button",
		disabled: isLoading
	}
	return (
		<AlertModal
			modalOpen={open}
			handleCancel={onCancel ? onCancel : () => null}
			headerText={headerText}
			buttons={
				showCancel ? [cancelButton, confirmButton] : [confirmButton]
			}
			size={size}
		>
			<p className="w-100 h-100 p-2 d-flex align-items-center justify-content-center">
				{message}
			</p>
		</AlertModal>
	);
};

export default ConfirmationModal;
